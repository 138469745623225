import { AnyAction, Dispatch } from 'redux';
import { AuthConstants } from './_constants';
import { AuthService, AuthProvider } from '../services';
import { AuthUser } from '../interfaces/models/auth-user';
import { SignupRequest } from '../interfaces/requests/auth/signup-request';
import { LoginRequest } from '../interfaces/requests/auth/login-request';

export interface AuthAction {
  type: AuthConstants;
  errorMessage?: string;
  provider?: AuthProvider;
  user?: AuthUser;
  next?: AnyAction;
}

function login(data: LoginRequest, next?: AnyAction) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(authenticating());
      const user = await AuthService.login(data);
      if (user) {
        dispatch(success(user));
        next && dispatch(next);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(failure(error?.message));
    }
  };
}

function signup(data: SignupRequest, next?: AnyAction) {
  return async (dispatch: Dispatch) => {
    const user = await AuthService.signup(data);
    try {
      dispatch(authenticating());
      if (user) {
        dispatch(success(user));
        next && dispatch(next);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(failure(error?.message));
    }
  };
}

function success(user: AuthUser): AuthAction {
  return { type: AuthConstants.AUTH_SUCCESS, user };
}
function failure(errorMessage: string): AuthAction {
  return { type: AuthConstants.AUTH_FAILURE, errorMessage };
}

function authenticating(
  provider: AuthProvider = AuthProvider.EMAIL,
): AuthAction {
  return { type: AuthConstants.AUTH_AUTHENTICATING, provider };
}

function requestAuth(next?: AnyAction): AuthAction {
  return { type: AuthConstants.AUTH_REQUEST, next };
}

function cancelAuth(): AuthAction {
  return { type: AuthConstants.AUTH_CANCEL };
}

function logout(): AuthAction {
  AuthService.logout();
  return { type: AuthConstants.LOGOUT };
}

export const authActions = {
  authenticating,
  failure,
  signup,
  login,
  logout,
  requestAuth,
  cancelAuth,
};
