import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { AlertMessage } from '../../../components/alert-message';
import { RoundButton } from '../../../components/round-button';
import { BorderedList } from '../../../components/bordered-list';
import { UpdateMeRequest } from '../../../interfaces/requests/me/update-profile';
import { Messages } from '../../../interfaces/responses/response';
import { AuthService } from '../../../services/auth-service';
import { countries } from '../../../utils/data';
import { MeApi } from '../../../api/me-api';
import { ImagePicker } from '../../../components/image-picker';

interface ProfileEditProps {
  onPasswordView: () => void;
}
export const ProfileEdit: React.FC<ProfileEditProps> = ({ onPasswordView }) => {
  const [user] = useState(AuthService.getUser());
  const [pictureUrl, setPictureUrl] = useState(user?.pictureUrl || '');
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState<Messages>();
  const [success, setSuccess] = useState(false);

  const formik = useFormik<UpdateMeRequest>({
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      phone: user?.phone || '',
      phoneCountry: user?.phoneCountry || 'MX',
    },
    validationSchema: yup.object({
      firstName: yup.string().required('Nombre es requerido.'),
      lastName: yup.string().required('Apellido es requerido.'),
      email: yup
        .string()
        .email('Correo electrónico debe ser un correo válido.')
        .required('Correo electrónico es requerido.'),
      phone: yup.string().required('Teléfono es requerido.'),
      phoneCountry: yup.string().required('País de teléfono es requerido.'),
    }),
    onSubmit: async (values) => {
      if (!user) {
        return;
      }
      setSaving(true);
      setSuccess(false);
      setErrors(undefined);
      const { errors } = await MeApi.editProfile(values);
      setErrors(errors);
      if (!errors) {
        Object.assign(user, values);
        AuthService.setUser(user);
        setSuccess(true);
      }
      setSaving(false);
    },
  });

  useEffect(() => {
    if (user) {
      Object.assign(user, { pictureUrl });
      AuthService.setUser(user);
    }
  }, [pictureUrl, user]);

  const getErrorProps = (field: string) => {
    const meta = formik.getFieldMeta(field);
    return {
      error: meta.touched && !!meta.error,
      helperText: meta.touched && meta.error,
    };
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Box width={0.33} m="auto">
        <ImagePicker
          label="Foto de perfil"
          name="pictureUrl"
          value={pictureUrl}
          onChange={(e) => setPictureUrl(e.target.value)}
          uploadHandler={MeApi.updatePicture}
          circle
        />
      </Box>
      <TextField
        variant="outlined"
        type="text"
        label="Nombre(s)"
        name="firstName"
        value={formik.values.firstName}
        onChange={formik.handleChange}
        {...getErrorProps('firstName')}
        required
      />
      <TextField
        variant="outlined"
        type="text"
        label="Apellido(s)"
        name="lastName"
        value={formik.values.lastName}
        onChange={formik.handleChange}
        {...getErrorProps('lastName')}
        required
      />
      <TextField
        variant="outlined"
        type="email"
        label="Correo electrónico"
        name="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        disabled={!!user?.email}
        {...getErrorProps('email')}
      />
      <Grid container spacing={2}>
        <Grid item xs={5} sm={4}>
          <TextField
            variant="outlined"
            select
            label="País"
            name="phoneCountry"
            value={formik.values.phoneCountry}
            onChange={formik.handleChange}
            {...getErrorProps('phoneCountry')}
          >
            {countries.map((option) => (
              <MenuItem key={option.code} value={option.code}>
                {option.flag} {option.dial_code}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={7} sm={8}>
          <TextField
            variant="outlined"
            type="tel"
            label="Teléfono celular"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            {...getErrorProps('phone')}
          />
        </Grid>
      </Grid>
      <List>
        <BorderedList button onClick={onPasswordView}>
          <ListItemText primary="Cambiar contraseña" />
          <ListItemSecondaryAction>
            <IconButton edge="end" color="secondary">
              <NavigateNext />
            </IconButton>
          </ListItemSecondaryAction>
        </BorderedList>
      </List>
      <Box mt={3} mb={1}>
        {errors && <AlertMessage messages={errors} />}
        {success && (
          <AlertMessage
            severity="success"
            messages={{ m: 'Perfil actualizado exitosanente.' }}
            onClose={() => setSuccess(false)}
          />
        )}
        <RoundButton type="submit" loading={saving} fullWidth>
          Guardar
        </RoundButton>
      </Box>
    </form>
  );
};
