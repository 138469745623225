import { CartAction, CartConstants } from '../actions';

const initialState: CartState = {};

export function cartReducer(
  state = initialState,
  action: CartAction,
): CartState {
  switch (action.type) {
    case CartConstants.CART_VISIBILITY_TOGGLE:
      return {
        showCart:
          action.visible === undefined ? !state.showCart : action.visible,
      };
    case CartConstants.CART_ADD_ITEM:
      return {
        itemId: action.itemId,
      };
    default:
      return state;
  }
}

export interface CartState {
  showCart?: boolean;
  itemId?: number;
}
