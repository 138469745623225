import Box from '@material-ui/core/Box';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { AppToolbar } from '../../../components/app-toolbar';
import { AddressesEdit } from './addresses-edit';

interface AddressEditPageProps {
  id?: number;
}
export const AddressEditPage: React.FC<AddressEditPageProps> = ({ id }) => {
  const title = useMemo(
    () => (id ? 'Editar dirección' : 'Agregar dirección'),
    [id],
  );
  const history = useHistory();

  const goList = () => {
    history.push('/me/addresses');
  };

  return (
    <React.Fragment>
      <AppToolbar title={title} />
      <Box maxWidth={480} py={4} mx="auto">
        <AddressesEdit onSuccess={goList} onCancel={goList} id={id} />
      </Box>
    </React.Fragment>
  );
};
