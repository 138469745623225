import React, { useState } from 'react';
import { LoadingWrapper } from '../../components/loading-wrapper';
import { AppToolbar } from '../../components/app-toolbar';
import { Box, Paper, Typography, TextField } from '@material-ui/core';
import { RoundButton } from '../../components/round-button';
import { SupportApi } from '../../api/support-api';
import { SnackAlert } from '../../components/snack-alert';

interface snackState {
  open: boolean;
  message: string;
  severity: any;
}

export const Support = () => {
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState<string>('');
  const [snack, setSnack] = useState<snackState>({
    open: false,
    message: '',
    severity: 'success',
  });

  // Snack
  const setOpenSnack = (value: boolean) => {
    setSnack({ ...snack, open: value });
  };

  // Comments
  const handleCommentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComments(event.target.value);
  };

  const onSubmit = async () => {
    setLoading(true);
    const { errors } = await SupportApi.postSupportReport(comments);
    if (errors) {
      await setSnack({
        open: true,
        message:
          'Surgió un problema, favor de intentar mandar este reporte más tarde',
        severity: 'error',
      });
      setLoading(false);
    } else {
      await setSnack({
        open: true,
        message: 'Hemos enviado tu reporte, muchas gracias',
        severity: 'success',
      });
      setTimeout(() => {
        setComments('');
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <React.Fragment>
      <AppToolbar title="Solicitar ayuda" />
      <LoadingWrapper loading={loading}>
        <Box maxWidth={480} py={{ sm: 4 }} mx="auto">
          <Paper>
            <Box px={{ xs: 2, sm: 4 }} py={4}>
              <Typography>
                {'Tu opinión es muy importante para nosotros.'}
              </Typography>
              <TextField
                autoFocus
                variant="outlined"
                type="text"
                label="¿En que te podemos ayudar?"
                name="request"
                value={comments}
                onChange={handleCommentsChange}
                required
              />
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
              >
                <RoundButton
                  size={'small'}
                  loading={loading}
                  disabled={comments === ''}
                  onClick={onSubmit}
                >
                  {'Enviar reporte'}
                </RoundButton>
              </Box>
            </Box>
          </Paper>
        </Box>
      </LoadingWrapper>
      <SnackAlert {...snack} setOpen={setOpenSnack} />
    </React.Fragment>
  );
};
