import React, { useState } from 'react';
import { LoadingWrapper } from '../../../../components/loading-wrapper';
import {
  ReportValue,
  OrderDetail,
} from '../../../../interfaces/models/oders/order-detail';
import {
  makeStyles,
  DialogContent,
  DialogActions,
  Typography,
  DialogContentText,
  Chip,
  Avatar,
  Grid,
} from '@material-ui/core';
import { RoundButton } from '../../../../components/round-button';
import { Info } from '@material-ui/icons';
import { OrdersApi } from '../../../../api/orders-api';

const useStyles = makeStyles(() => ({
  button: {
    color: 'white',
  },
}));

interface OrderDetailConfirmProps {
  orderId: number;
  order: OrderDetail;
  rejects: ReportValue[];
  getData(): void;
  onCloseModal(key?: string): void;
  setSnackState(state?: object): void;
}

export const OrderDetailConfirm: React.FC<OrderDetailConfirmProps> = ({
  order,
  orderId,
  rejects,
  getData,
  onCloseModal,
  setSnackState,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    const { errors } = await OrdersApi.setConfirm(orderId, rejects);
    if (errors) {
      await setSnackState({
        open: true,
        message:
          'Surgió un problema, favor de intentar confirmar su orden más tarde',
        severity: 'error',
      });
      setLoading(false);
    } else {
      await setSnackState({
        open: true,
        message:
          'Solicitud enviada, notificaremos al comprador la información de la venta',
        severity: 'success',
      });
      setTimeout(() => {
        onCloseModal('confirm');
        getData();
      }, 1000);
    }
  };

  return (
    <LoadingWrapper loading={loading}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {
            'Hemos de enviar esta información al comprador, favor de confirmar si la información es correcta'
          }
        </DialogContentText>
        <Grid container wrap={'wrap'} spacing={2}>
          {rejects.length < order.details.length ? (
            <Chip
              variant={'outlined'}
              avatar={<Avatar>{order.details.length}</Avatar>}
              label=" productos confirmados"
            />
          ) : (
            <Chip
              color={'secondary'}
              icon={<Info />}
              label="Atención esta orden se cancelará"
            />
          )}
          {rejects.length > 0 && (
            <Chip
              variant={'outlined'}
              color={'primary'}
              avatar={<Avatar>{rejects.length}</Avatar>}
              label=" productos reportados con problemas"
            />
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <RoundButton
          loading={loading}
          size={'small'}
          color={'primary'}
          onClick={() => onCloseModal('confirm')}
        >
          <Typography className={classes.button}>{'Cancelar'}</Typography>
        </RoundButton>
        <RoundButton loading={loading} size={'small'} onClick={onSubmit}>
          <Typography className={classes.button}>
            {'Confirmar orden'}
          </Typography>
        </RoundButton>
      </DialogActions>
    </LoadingWrapper>
  );
};
