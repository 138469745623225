import React from 'react';
import { Box, Typography, makeStyles, Theme, Grid } from '@material-ui/core';
import { OrderDetailDefaultProps } from '../../../interfaces/models/oders/order-detail';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    color: theme.palette.info.main,
  },
  body: {
    color: theme.palette.info.light,
    fontSize: 14,
    fontWeight: 500,
  },
}));

export const OrderDetailAddress: React.FC<OrderDetailDefaultProps> = ({
  order,
}) => {
  const classes = useStyles();
  if (order.shipment && order.shipment.destination) {
    return (
      <Grid item xs={12} sm={6}>
        <Box marginBottom={2} p={4} bgcolor="background.paper" height={'auto'}>
          <Typography variant="h5" className={classes.header}>
            {'Dirección'}
          </Typography>
          <Typography className={classes.body}>
            {order.shipment.destination}
          </Typography>
        </Box>
      </Grid>
    );
  }
  return null;
};
