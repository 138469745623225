import React, { useState, useEffect } from 'react';
import { GetIndex, OptionsApi } from '../../../../api/options-api';
import { LoadingWrapper } from '../../../../components/loading-wrapper';
import { Option, OptionType } from '../../../../interfaces/models/options';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField,
  Typography,
  makeStyles,
  Box,
  Grid,
  DialogActions,
} from '@material-ui/core';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import { RoundButton } from '../../../../components/round-button';
import { ImagePicker } from '../../../../components/image-picker';
import { MediaApi } from '../../../../api/media-api';

const useStyles = makeStyles(() => ({
  button: {
    color: 'white',
  },
}));

interface OrderDetailRefundProps {
  orderDetailId: number;
  onCloseModal(key?: string): void;
  setReturnElement(ReturnType?: object): void;
}

interface SelectState {
  orderDetailId: number;
  reason: string;
  comments: string;
  images: string;
}

export const OrderDetailRefund: React.FC<OrderDetailRefundProps> = ({
  orderDetailId,
  onCloseModal,
  setReturnElement,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<GetIndex>();
  const [select, setSelect] = React.useState<SelectState>({
    orderDetailId: orderDetailId || 0,
    reason: '',
    comments: '',
    images: '',
  });

  useEffect(() => {
    const initData = async () => {
      setResponse(await OptionsApi.index(OptionType.ORDER_RETURN));
      setLoading(false);
    };
    initData();
  }, []);

  const handleAltTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelect({ ...select, comments: event.target.value });
  };

  const handleSelection = (reason: string) => {
    setSelect({ ...select, reason });
  };

  const onChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelect({ ...select, images: event.target.value });
  };

  const onSubmit = async () => {
    await setReturnElement({
      ...select,
      images: [select.images],
    });
    setTimeout(() => {
      onCloseModal('refund');
    }, 250);
  };

  return (
    <LoadingWrapper loading={loading} errors={response?.errors}>
      {!loading && response && response.options && response.options.length > 0 && (
        <div>
          <List>
            {response.options.map((option: Option) => {
              const selected = Boolean(
                select.reason !== '' && select.reason === option.code,
              );
              return (
                <ListItem
                  button
                  selected={selected}
                  onClick={() => handleSelection(option.code)}
                  key={option.code}
                >
                  <ListItemIcon>
                    {selected ? (
                      <RadioButtonChecked />
                    ) : (
                      <RadioButtonUnchecked />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </ListItem>
              );
            })}
            <Box px={2}>
              <TextField
                autoFocus
                id="altCommnets-multiline-flexible"
                variant="outlined"
                label="Comentarios ..."
                multiline
                maxRows={4}
                value={select.comments}
                onChange={handleAltTopicChange}
              />
            </Box>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={4}>
                <ImagePicker
                  label="Vuelta *"
                  name="pictures.front"
                  value={select.images}
                  onChange={onChangeImage}
                  uploadHandler={MediaApi.uploadPicture}
                />
              </Grid>
            </Grid>
          </List>
        </div>
      )}
      <DialogActions>
        <RoundButton
          size={'small'}
          loading={loading}
          disabled={
            select.reason === '' ||
            select.comments === '' ||
            select.images === ''
          }
          onClick={onSubmit}
        >
          <Typography className={classes.button}>{'Guardar'}</Typography>
        </RoundButton>
      </DialogActions>
    </LoadingWrapper>
  );
};
