import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { OrdersIndex } from './orders-index';

export const OrdersRoutes = () => {
  const matchPath = useRouteMatch();
  return (
    <Switch>
      <Route path={`${matchPath.url}`}>
        <OrdersIndex />
      </Route>
    </Switch>
  );
};
