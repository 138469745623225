import moment from 'moment';
import 'moment/locale/es-mx';

import { Option } from '../interfaces/models/attribute';

export const getBackPath = (path?: string) => {
  const segments = (path || '/').split('/');
  segments.pop();
  return segments.join('/');
};

export const groupOptions = (options: Option[]) => {
  return Object.entries(
    options.reduce((carry: { [group: string]: Option[] }, option) => {
      if (!carry[option.group || '']) {
        carry[option.group || ''] = [];
      }
      carry[option.group || ''].push(option);
      return carry;
    }, {}),
  );
};

export const formatCurrency = (amount: number, decimals = true) => {
  return new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: decimals ? 2 : 0,
  }).format(amount);
};

// 0, 10, 20, ..., 90, 100, 200, ..., 900, 1000, 2000, ..., 9000, 10000, ...
export const rangePow10 = (x: number) => {
  return (
    ((x % 10) + Math.floor(x / 10)) * Math.pow(10, Math.ceil((x + 1) / 10))
  );
};

// 0, 1, 2, ..., 9, 10, 20, ..., 90, 100, 200, ..., 900, 1000, ...
export const rangePow1 = (x: number) => {
  return ((x % 10) + Math.floor(x / 10)) * Math.pow(10, Math.floor(x / 10));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseJson = (str: string): any => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return undefined;
  }
};

const defaultOptions: object = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const formatDate = (date: string, options: object = {}): string => {
  const current = new Date(date);
  return current.toLocaleDateString('es-MX', {
    ...defaultOptions,
    ...options,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const equalsObj = (a: any, b: any): boolean => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export const humanizeStrDate = (str: string, locale = 'es-mx'): string => {
  moment.locale(locale);
  const date = moment(str);
  if (!date.isValid()) {
    return '';
  }
  return moment.duration(date.diff(moment())).humanize(true);
};

export const refundStatusLabel = (returnStatus: string): string => {
  if (returnStatus !== null && returnStatus !== undefined) {
    switch (returnStatus) {
      case 'rejected':
        return 'Devolución rechazada';
      case 'partial':
        return 'Devolución parcialmente aceptada';
      case 'accepted':
        return 'Devuelto';
      default:
        return 'En proceso de devolución';
    }
  }
  return '';
};

export const shipmentStatusLabel = (shipment: { status: string }): string => {
  if (shipment !== null && shipment !== undefined) {
    switch (shipment.status) {
      case 'DE':
        return 'Excepción';
      case 'DY':
        return 'Retrasado';
      case 'IT':
        return 'Enviado';
      case 'OC':
        return 'Paquetería';
      case 'DL':
        return 'Entregado';
      default:
        return 'En preparación de envío';
    }
  }
  return '';
};

export const orderStatusLabel = (status: string): string => {
  if (status !== null && status !== undefined) {
    switch (status) {
      case 'accepted':
        return 'Aceptado';
      case 'rejected':
        return 'Cancelado';
      case 'partial':
        return 'Parcialmente aceptado';
      default:
        return 'En preparación';
    }
  }
  return '';
};

export const paymentStatusLabel = (status: string): string => {
  if (status !== null && status !== undefined) {
    switch (status) {
      case 'accepted':
        return 'Pago aceptado';
      case 'rejected':
        return 'Referencia de pago cencelada';
      default:
        return 'Pendiente de realizar pago';
    }
  }
  return '';
};

export interface OrderFormattedList<T> {
  date: string;
  items: Array<T>;
}
export const setFormattedOrderList = <T extends { createdAt: string }>(
  list: T[] = [],
): OrderFormattedList<T>[] => {
  const groups = list.reduce((ac: { [date: string]: T[] }, item: T) => {
    const date = new Date(item.createdAt).toLocaleDateString('es-MX', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    if (!ac[date]) {
      ac[date] = [];
    }
    ac[date].push(item);
    return ac;
  }, {});
  return Object.keys(groups).map((date: string) => ({
    date,
    items: groups[date],
  }));
};
