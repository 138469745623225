import React, { FunctionComponent, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { withFormik, FormikProps } from 'formik';
import * as yup from 'yup';

import { AddressesApi } from '../../../api/addresses-api';
import { StatesApi } from '../../../api/states-api';
import { RoundButton } from '../../../components/round-button';
import { LoadingWrapper } from '../../../components/loading-wrapper';
import { AlertMessage } from '../../../components/alert-message';
import { AddressRequest } from '../../../interfaces/requests/addresses/address-request';
import { Messages } from '../../../interfaces/responses/response';
import { Address } from '../../../interfaces/models/address';
import { FormControl } from '@material-ui/core';

interface AddressesEditProps {
  id?: number;
  onSuccess: (address: Address) => void;
  onCancel: () => void;
  inset?: boolean;
}

interface AddressesEditFormProps
  extends AddressesEditProps,
    FormikProps<AddressRequest> {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customselect: {
      margin: theme.spacing(1, 0),
      width: '100%',
    },
  }),
);

const AddressesEditForm: FunctionComponent<AddressesEditFormProps> = ({
  id,
  setValues,
  onCancel,
  isSubmitting,
  inset,
  ...formik
}) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Messages>();
  const [states, setStates] = useState<string[]>([]);

  const getErrorProps = (field: keyof AddressRequest) => ({
    error: formik.touched[field] && !!formik.errors[field],
    helperText: formik.touched[field] && formik.errors[field],
  });

  useEffect(() => {
    const fetchAddress = async () => {
      if (id) {
        const { errors, address } = await AddressesApi.show(id);
        setErrors(errors);
        setValues(getValues(address));
      } else {
        setValues(getValues());
      }
    };
    const fetchStates = async () => {
      const { errors, states } = await StatesApi.index();
      setErrors(errors);
      setStates(states);
    };
    setLoading(true);
    Promise.all([fetchStates(), fetchAddress()]).then(() => {
      setLoading(false);
    });
  }, [id, setValues]);

  const paddings = inset ? {} : { px: { xs: 2, sm: 4 }, py: 4 };
  const classes = useStyles();

  return (
    <LoadingWrapper loading={loading} errors={errors}>
      <Box maxWidth={480} mx="auto">
        <Paper elevation={inset ? 0 : undefined}>
          <Box {...paddings}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <TextField
                variant="outlined"
                type="text"
                label="Alias"
                placeholder="Casa, Trabajo, ..."
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                {...getErrorProps('name')}
                required
              />
              <TextField
                variant="outlined"
                type="text"
                autoComplete="shipping address-line1"
                label="Calle"
                name="street"
                value={formik.values.street}
                onChange={formik.handleChange}
                {...getErrorProps('street')}
                required
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Número ext."
                    name="extNumber"
                    value={formik.values.extNumber}
                    onChange={formik.handleChange}
                    {...getErrorProps('extNumber')}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Número int."
                    name="intNumber"
                    value={formik.values.intNumber}
                    onChange={formik.handleChange}
                    {...getErrorProps('intNumber')}
                  />
                </Grid>
              </Grid>
              <TextField
                variant="outlined"
                type="text"
                autoComplete="shipping postal-code"
                label="Código Postal"
                name="zip"
                value={formik.values.zip}
                onChange={formik.handleChange}
                {...getErrorProps('zip')}
                required
              />
              <TextField
                variant="outlined"
                type="text"
                autoComplete="shipping address-line2"
                label="Colonia"
                name="neighborhood"
                value={formik.values.neighborhood}
                onChange={formik.handleChange}
                {...getErrorProps('neighborhood')}
                required
              />
              <TextField
                variant="outlined"
                type="text"
                autoComplete="shipping address-level2"
                label="Municipio"
                name="municipality"
                value={formik.values.municipality}
                onChange={formik.handleChange}
                {...getErrorProps('municipality')}
                required
              />
              <FormControl variant="outlined" className={classes.customselect}>
                <InputLabel htmlFor="outlined-state">Estado</InputLabel>
                {states.length && (
                  <Select
                    native
                    value={formik.values.state}
                    name="state"
                    label="Estado"
                    onChange={formik.handleChange}
                    inputProps={{
                      name: 'state',
                      id: 'outlined-state',
                    }}
                  >
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </Select>
                )}
              </FormControl>
              <TextField
                variant="outlined"
                type="text"
                autoComplete="shipping country-name"
                label="País"
                name="country"
                defaultValue={formik.values.country}
                InputProps={{
                  readOnly: true,
                }}
              />
              {formik.status?.errors && (
                <AlertMessage messages={formik.status.errors} />
              )}
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <RoundButton
                      fullWidth
                      variant="outlined"
                      disabled={isSubmitting}
                      onClick={() => onCancel()}
                    >
                      Cancelar
                    </RoundButton>
                  </Grid>
                  <Grid item xs={6}>
                    <RoundButton fullWidth type="submit" loading={isSubmitting}>
                      Guardar
                    </RoundButton>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
        </Paper>
      </Box>
    </LoadingWrapper>
  );
};

const validationSchema = yup.object({
  name: yup.string().required('Nombre es requerido.'),
  street: yup.string().required('Calle es requerida.'),
  extNumber: yup.string().required('Número ext. es requerido.'),
  intNumber: yup.string().optional(),
  neighborhood: yup.string().required('Colonia es requerida.'),
  municipality: yup.string().required('Municipio es requerido.'),
  state: yup.string().required('Estado es requerido.'),
  country: yup.string().required('País es requerido.'),
  zip: yup
    .string()
    .required('Código postal es requerido.')
    .matches(/^\d{5}$/, 'Código postal debe ser de 5 digítos.'),
  lat: yup.number().min(-90).max(90).required(),
  lng: yup.number().min(-180).max(180).required(),
});

const getValues = (address?: Address): AddressRequest => ({
  name: address?.name || '',
  street: address?.street || '',
  extNumber: address?.extNumber || '',
  intNumber: address?.intNumber || '',
  neighborhood: address?.neighborhood || '',
  municipality: address?.municipality || '',
  state: address?.state || '',
  country: address?.country || 'México',
  zip: address?.zip || '',
  lat: address?.lat || 0,
  lng: address?.lng || 0,
});

export const AddressesEdit = withFormik<AddressesEditProps, AddressRequest>({
  displayName: 'AddressesEdit',
  validationSchema,
  handleSubmit: async (
    values,
    { setSubmitting, setStatus, setErrors, props },
  ) => {
    setSubmitting(true);
    setErrors({});
    setStatus({ errors: undefined });
    let errors, address;
    if (props.id) {
      ({ errors, address } = await AddressesApi.update(props.id, values));
    } else {
      ({ errors, address } = await AddressesApi.store(values));
    }
    setErrors(errors || {});
    setStatus({ errors });
    if (!errors && address) {
      props.onSuccess(address);
    }
  },
})(AddressesEditForm);
