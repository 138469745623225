import React, { useEffect, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import PhotoCameraOutlined from '@material-ui/icons/PhotoCameraOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { Messages } from '../interfaces/responses/response';
import clsx from 'clsx';

const useStyles = makeStyles({
  formControl: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  input: {
    position: 'absolute',
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
  },
  label: {
    position: 'absolute',
    top: 'calc(50% - 11px)',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  textHelper: {
    textAlign: 'center',
  },
  circle: {
    borderRadius: '9999px',
    overflow: 'hidden',
  },
});

declare type UploadHandler = (
  file: Blob,
) => Promise<{ errors?: Messages; url?: string }>;

declare type ErrorHandler = (m?: string) => void;

interface ImagePickerProps {
  label: React.ReactNode;
  name: string;
  value: string;
  error?: boolean;
  helperText?: React.ReactNode;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  uploadHandler?: UploadHandler;
  onError?: ErrorHandler;
  circle?: boolean;
}
export const ImagePicker: React.FC<ImagePickerProps> = ({
  label,
  name,
  value,
  error = false,
  helperText = '',
  onChange,
  uploadHandler,
  circle = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const [url, setUrl] = useState(value);
  const [errorMsg, setErrorMsg] = useState<string>();

  useEffect(() => {
    const cont = ref.current?.querySelector<HTMLElement>('.MuiInputBase-root');
    const onResize = () => {
      if (cont) {
        cont.style.height = ref.current?.clientWidth + 'px';
      }
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    const cont = ref.current?.querySelector<HTMLElement>('.MuiInputBase-root');
    if (cont) {
      cont.style.backgroundImage = `url(${url})`;
    }
  }, [url]);

  const previewImage = (file: Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const result = reader.result?.toString() || '';
      setUrl(result);
    };
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
    previewImage(file);
    if (uploadHandler) {
      setErrorMsg(undefined);
      const { errors = {}, url } = await uploadHandler(file);
      const event = {
        target: { name, value: url || '' },
      } as React.ChangeEvent<HTMLInputElement>;
      onChange(event);
      setErrorMsg(Object.values(errors).shift());
    }
  };

  return (
    <React.Fragment>
      <TextField
        ref={ref}
        variant="outlined"
        type="file"
        label={!url && <PhotoCameraOutlined fontSize="large" />}
        helperText={helperText || errorMsg || label}
        InputProps={{
          classes: {
            root: clsx(classes.formControl, circle ? classes.circle : ''),
            input: classes.input,
            notchedOutline: circle ? classes.circle : undefined,
          },
          inputProps: { accept: 'image/*' },
        }}
        InputLabelProps={{ classes: { root: classes.label } }}
        FormHelperTextProps={{ classes: { root: classes.textHelper } }}
        onChange={handleChange}
        error={error || !!errorMsg}
      />
    </React.Fragment>
  );
};
