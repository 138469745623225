import React from 'react';
import { Box, Grid } from '@material-ui/core';
import {
  OrderDetail,
  ReportValue,
  ReturnType,
} from '../../../interfaces/models/oders/order-detail';
import { OrderDetailItem } from './order-detail-item';
export interface OrderDetailListProps {
  isSale: Boolean;
  order: OrderDetail;
  rejects: ReportValue[];
  orderDetails: ReturnType[];
  openModalWithItem(key?: string, id?: number | any): void;
}

export const OrderDetailsList: React.FC<OrderDetailListProps> = ({
  order,
  isSale,
  rejects,
  orderDetails,
  openModalWithItem,
}) => {
  return (
    <Box marginBottom={2} p={4} bgcolor="background.paper" height={'auto'}>
      <Grid container direction="column" justify="flex-start">
        {order.details.length > 0 &&
          order.details.map((detail: any) => (
            <OrderDetailItem
              key={detail.id}
              isSale={isSale}
              rejects={rejects}
              {...detail}
              shipment={order.shipment}
              orderDetails={orderDetails}
              returnable={order.returnable}
              confirmable={order.confirmable}
              openModalWithItem={openModalWithItem}
            />
          ))}
      </Grid>
    </Box>
  );
};
