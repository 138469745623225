import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { OptionsApi } from '../../api/options-api';
import { AppToolbar } from '../../components/app-toolbar';
import { LoadingWrapper } from '../../components/loading-wrapper';
import { RoundButton } from '../../components/round-button';
import { Option, OptionType } from '../../interfaces/models/options';
import { PostsReportRequest } from '../../interfaces/requests/posts/posts-report-request';
import { Messages } from '../../interfaces/responses/response';
import FormHelperText from '@material-ui/core/FormHelperText';
import { AlertMessage } from '../../components/alert-message';
import { PostsApi } from '../../api/posts-api';

interface PostsReportProps {
  id: number;
}

export const PostsReport: React.FC<PostsReportProps> = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<Option[]>([]);
  const [errors, setErrors] = useState<Messages>();
  const [saving, setSaving] = useState(false);
  const [errorsSaving, setErrorsSaving] = useState<Messages>();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setErrors(undefined);
      setOptions([]);
      const { errors, options } = await OptionsApi.index(
        OptionType.POST_COMPLAIN,
      );
      if (errors) {
        setErrors(errors);
        return setLoading(false);
      }
      setOptions(options);
      setLoading(false);
    };
    loading && fetch();
  }, [loading]);

  const formik = useFormik<PostsReportRequest>({
    initialValues: {
      postId: id,
      reason: '',
      text: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSaving(true);
      setErrorsSaving(undefined);
      const { errors } = await PostsApi.report(values);
      if (errors) {
        setErrorsSaving(errors);
        return setSaving(false);
      }
      setSaving(false);
      setSuccess(true);
    },
  });

  return (
    <LoadingWrapper loading={loading} errors={errors}>
      <AppToolbar title="Reportar artículo" />
      <Container maxWidth="sm">
        <form onSubmit={formik.handleSubmit}>
          <Box py={4}>
            <Box
              p={2}
              border={1}
              mb={2}
              borderRadius="borderRadius"
              borderColor="#2BC2AC"
              bgcolor="#E7FCF9"
              color="#2BC2AC"
              fontWeight="bold"
            >
              Cuéntanos que sucede
            </Box>
            <FormControl
              style={{ width: '100%' }}
              error={formik.touched.reason && !!formik.errors.reason}
            >
              <RadioGroup
                name="reason"
                value={formik.values.reason}
                onChange={formik.handleChange}
              >
                {options.map((o, i) => (
                  <Box
                    key={o.code}
                    px={2}
                    py={0.5}
                    border={1}
                    mb={i < options.length - 1 ? 2 : 0}
                    borderRadius="borderRadius"
                    borderColor="divider"
                    bgcolor="background.paper"
                  >
                    <FormControlLabel
                      style={{ display: 'block' }}
                      value={o.code}
                      control={<Radio />}
                      label={o.name}
                    />
                  </Box>
                ))}
              </RadioGroup>
              {formik.touched.reason && !!formik.errors.reason && (
                <FormHelperText>{formik.errors.reason}</FormHelperText>
              )}
            </FormControl>
            <Box mt={2}>
              <TextField
                variant="outlined"
                label="Comentarios"
                multiline
                minRows={3}
                name="text"
                value={formik.values.text}
                onChange={formik.handleChange}
              />
            </Box>
            {errorsSaving && <AlertMessage messages={errorsSaving} />}
            {success && (
              <AlertMessage
                messages={{ m: 'Hemos enviado tu reporte. Gracias.' }}
                severity="success"
              />
            )}
            <Box mt={2}>
              <RoundButton
                fullWidth
                loading={saving}
                type="submit"
                disabled={success}
              >
                Enviar reporte
              </RoundButton>
            </Box>
          </Box>
        </form>
      </Container>
    </LoadingWrapper>
  );
};

const validationSchema = yup.object({
  reason: yup.string().required('Motivo es requerido.'),
  text: yup.string(),
});
