import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { EmailVerification } from './email-verification';

import { PasswordReset } from './password-reset';

export const AuthRoutes = () => {
  const matchPath = useRouteMatch();

  return (
    <Switch>
      <Route path={`${matchPath.url}/password-reset/:token`}>
        <PasswordReset />
      </Route>
      <Route path={`${matchPath.url}/email-verification/:token`}>
        <EmailVerification />
      </Route>
    </Switch>
  );
};
