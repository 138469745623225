import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import { Link, useRouteMatch } from 'react-router-dom';
import * as yup from 'yup';

import { AuthApi } from '../../api/auth-api';
import { AlertMessage } from '../../components/alert-message';
import { AppToolbar } from '../../components/app-toolbar';
import { RoundButton } from '../../components/round-button';
import { TogglePasswordInput } from '../../components/toggle-password-input';
import { Messages } from '../../interfaces/responses/response';

interface PasswordInput {
  token: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

export const PasswordReset = () => {
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState<Messages>();
  const [success, setSuccess] = useState(false);

  const match = useRouteMatch<{ token: string }>();

  const formik = useFormik<PasswordInput>({
    initialValues: {
      token: match.params.token || '',
      email: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Correo electrónico debe ser un correo válido.')
        .required('Correo electrónico es requerido.'),
      password: yup.string().required('Contraseña es requerida.'),
      passwordConfirmation: yup
        .string()
        .oneOf([yup.ref('password')], 'Contraseñas no coinciden.')
        .required('Confirmación de contraseña es requerida.'),
    }),
    onSubmit: async (values) => {
      setSaving(true);
      setErrors(undefined);
      setSuccess(false);
      const { errors } = await AuthApi.resetPassword(
        values.token,
        values.password,
      );
      setErrors(errors);
      if (!errors) {
        formik.resetForm();
        setSuccess(true);
      }
      setSaving(false);
    },
  });

  const getErrorProps = (field: string) => {
    const meta = formik.getFieldMeta(field);
    return {
      error: meta.touched && !!meta.error,
      helperText: meta.touched && meta.error,
    };
  };

  return (
    <React.Fragment>
      <AppToolbar title="Reestablecimiento de contraseña" disableBack />
      <Container maxWidth="sm">
        <Box py={4}>
          <Paper>
            <Box p={4}>
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  variant="outlined"
                  type="email"
                  label="Correo electrónico"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  {...getErrorProps('email')}
                />
                <TogglePasswordInput
                  label="Contraseña"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  {...getErrorProps('password')}
                />
                <TogglePasswordInput
                  label="Confirmar contraseña"
                  name="passwordConfirmation"
                  value={formik.values.passwordConfirmation}
                  onChange={formik.handleChange}
                  {...getErrorProps('passwordConfirmation')}
                />
                <Box mt={3} mb={1}>
                  {errors && <AlertMessage messages={errors} />}
                  {success && (
                    <AlertMessage
                      severity="success"
                      messages={{ m: 'Contraseña reestablecida exitosamente.' }}
                      action={
                        <Button color="inherit" component={Link} to="/">
                          Inicio
                        </Button>
                      }
                    />
                  )}
                  <RoundButton fullWidth type="submit" loading={saving}>
                    Reestablecer contraseña
                  </RoundButton>
                </Box>
              </form>
            </Box>
          </Paper>
        </Box>
      </Container>
    </React.Fragment>
  );
};
