import React, { useState, useEffect } from 'react';
import { LoadingWrapper } from '../../../../components/loading-wrapper';
import { GetIndex, OptionsApi } from '../../../../api/options-api';
import {
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Box,
  TextField,
  Typography,
  makeStyles,
  DialogActions,
} from '@material-ui/core';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import { OptionType } from '../../../../interfaces/models/options';
import { RoundButton } from '../../../../components/round-button';
import { ReportValue } from '../../../../interfaces/models/oders/order-detail';

const useStyles = makeStyles(() => ({
  button: {
    color: 'white',
  },
}));

interface OrderDetailProblemProps {
  id: number;
  onCloseModal(key?: string): void;
  setSnackState(state?: object): void;
  setRejectElements(report?: ReportValue): void;
}

export const OrderDetailProblem: React.FC<OrderDetailProblemProps> = ({
  id,
  onCloseModal,
  setRejectElements,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<GetIndex>();

  const [report, setReport] = useState<ReportValue>({
    id,
    reason: '',
    comments: '',
  });

  useEffect(() => {
    const initData = async () => {
      setResponse(await OptionsApi.index(OptionType.ORDER_REJECT));
      setLoading(false);
    };
    initData();
  }, []);

  const handleSelection = (reason: string) => {
    setReport({ ...report, reason });
  };

  const handleCommentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReport({ ...report, comments: event.target.value });
  };

  const onSubmit = () => {
    setRejectElements(report);
    setTimeout(() => {
      onCloseModal('problem');
    }, 250);
  };

  return (
    <LoadingWrapper loading={loading} errors={response?.errors}>
      {!loading && response && response.options && response.options.length > 0 && (
        <div>
          <List>
            {response.options.map((option: any) => {
              const selected = Boolean(
                report.reason !== '' && report.reason === option.code,
              );
              return (
                <ListItem
                  button
                  selected={selected}
                  onClick={() => handleSelection(option.code)}
                  key={option.code}
                >
                  <ListItemIcon>
                    {selected ? (
                      <RadioButtonChecked />
                    ) : (
                      <RadioButtonUnchecked />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </ListItem>
              );
            })}
            <Box px={2}>
              <TextField
                id="comments-multiline-flexible"
                variant="outlined"
                label="Añade comentarios acerca de este producto ..."
                multiline
                maxRows={4}
                value={report.comments}
                onChange={handleCommentsChange}
              />
            </Box>
          </List>
        </div>
      )}
      <DialogActions>
        <RoundButton
          size={'small'}
          loading={loading}
          disabled={report.reason === ''}
          onClick={onSubmit}
        >
          <Typography className={classes.button}>
            {'Guardar problema'}
          </Typography>
        </RoundButton>
      </DialogActions>
    </LoadingWrapper>
  );
};
