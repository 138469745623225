import { AnyAction } from 'redux';
import { AuthAction, AuthConstants } from '../actions';
import { AuthProvider, AuthService } from '../services';

const initialState: AuthState = { loggedIn: AuthService.isAuth() };

export function authReducer(
  state = initialState,
  action: AuthAction,
): AuthState {
  const next = action.next;
  switch (action.type) {
    case AuthConstants.AUTH_REQUEST:
      return {
        next,
        showAuth: true,
      };
    case AuthConstants.AUTH_CANCEL:
      return {
        showAuth: false,
      };
    case AuthConstants.AUTH_AUTHENTICATING:
      return {
        next,
        showAuth: true,
        loggingIn: true,
        provider: action.provider,
      };
    case AuthConstants.AUTH_SUCCESS:
      return {
        next,
        loggedIn: true,
      };
    case AuthConstants.AUTH_FAILURE:
      return {
        showAuth: true,
        errorMessage: action.errorMessage,
      };
    case AuthConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}

export interface AuthState {
  showAuth?: boolean;
  loggingIn?: boolean;
  loggedIn?: boolean;
  errorMessage?: string;
  next?: AnyAction;
  provider?: AuthProvider;
}
