import { Api } from './api-base';
import { Messages, Response } from '../interfaces/responses/response';
import { Address } from '../interfaces/models/address';
import { AddressRequest } from '../interfaces/requests/addresses/address-request';

export interface AddressesIndex {
  errors?: Messages;
  addresses: Address[];
}

export interface AddressesShow {
  errors?: Messages;
  address?: Address;
}

export const AddressesApi = {
  index: async (): Promise<AddressesIndex> => {
    const { errors, response } = await Api.get<Response<Address[]>>(
      '/addresses',
    );
    return { errors, addresses: response?.data || [] };
  },
  show: async (id: number): Promise<AddressesShow> => {
    const { errors, response } = await Api.get<Response<Address>>(
      `/addresses/${id}`,
    );
    return { errors, address: response?.data };
  },
  store: async (input: AddressRequest) => {
    const { errors, response } = await Api.post<Response<Address>>(
      '/addresses',
      input,
    );
    return { errors, address: response?.data };
  },
  update: async (id: number, input: AddressRequest) => {
    const { errors, response } = await Api.put<Response<Address>>(
      `/addresses/${id}`,
      input,
    );
    return { errors, address: response?.data };
  },
  delete: async (id: number) => {
    const { errors } = await Api.delete<Response<void>>(`/addresses/${id}`);
    return { errors };
  },
};
