import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tune from '@material-ui/icons/Tune';
import { useLocation } from 'react-router-dom';

import { PostsApi } from '../../api/posts-api';
import { HomeFilters } from './home-filters';
import { HomeCategories } from './home-categories';
import { PostListItem } from '../posts/partials/post-list-item';
import { LoadingWrapper } from '../../components/loading-wrapper';
import { Category } from '../../interfaces/models/category';
import { PostItem } from '../../interfaces/models/post-item';
import { Messages } from '../../interfaces/responses/response';
import { Brand } from '../../interfaces/models/brand';
import { Attribute } from '../../interfaces/models/attribute';
import { PostFilters } from '../../interfaces/requests/posts/posts-filters';
import { queryStrToObj } from '../../utils/query-params';
import { rangePow10 } from '../../utils/helpers';
import { Placeholder } from '../../components/placeholder';
import { makeStyles } from '@material-ui/core';
import { VerificationAlert } from '../auth/verification-alert';

export const Home: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const [filters, setFilters] = useState<PostFilters>({});
  const [loading, setLoading] = useState(false);
  const [loadingMeta, setLoadingMeta] = useState(true);
  const [categories, setCategories] = useState<Category[]>([]);
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [posts, setPosts] = useState<PostItem[]>([]);
  const [errors, setErrors] = useState<Messages>();

  const [openFilters, setOpenFilters] = React.useState(false);

  useEffect(() => {
    setFilters(queryStrToObj(location.search));
  }, [location.search]);

  useEffect(() => {
    setLoading(true);
  }, [filters]);

  useEffect(() => {
    const fetch = async () => {
      setCategories([]);
      setErrors(undefined);
      const [res1, res2, res3] = await Promise.all([
        PostsApi.categories(),
        PostsApi.attributes(),
        PostsApi.brands(),
      ]);
      const hasErrors = res1.errors || res2.errors || res3.errors;
      if (hasErrors) {
        setErrors(hasErrors);
        return setLoadingMeta(false);
      }
      setCategories(res1.categories);
      setAttributes(res2.attributes);
      setBrands(res3.brands);
      setLoadingMeta(false);
    };
    loadingMeta && fetch();
  }, [loadingMeta]);

  useEffect(() => {
    const fetch = async () => {
      setPosts([]);
      setErrors(undefined);
      const params: PostFilters = { ...filters };
      if (filters.minPrice) {
        params.minPrice = rangePow10(+filters.minPrice);
      }
      if (filters.maxPrice) {
        params.maxPrice = rangePow10(+filters.maxPrice);
      }
      params.salable = true;
      const res = await PostsApi.index(params);
      if (res.errors) {
        setErrors(res.errors);
        return setLoading(false);
      }
      setPosts(res.posts);
      setLoading(false);
    };
    loading && fetch();
  }, [loading, filters]);

  return (
    <LoadingWrapper loading={loadingMeta && loading} errors={errors}>
      <Container maxWidth="lg" className={classes.container}>
        <Box pt={1}>
          <VerificationAlert />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row-reverse"
        >
          <IconButton onClick={() => setOpenFilters(true)}>
            <Tune />
          </IconButton>
        </Box>
        <HomeFilters
          categories={categories}
          brands={brands}
          attributes={attributes}
          open={openFilters}
          onClose={() => setOpenFilters(false)}
          filters={filters}
          onChange={setFilters}
        />
        <HomeCategories
          categories={categories}
          selected={Number(filters.categoryId)}
        />
        <Box flexGrow={1} py={4}>
          <Grid container>
            {posts.length ? (
              posts.map((post) => (
                <Grid key={post.id} item xs={12} sm={6} md={4} lg={3}>
                  <PostListItem post={post} />
                </Grid>
              ))
            ) : (
              <Placeholder
                picture="posts"
                title="Sin productos"
                body="La busqueda no arrojo resultados"
              />
            )}
          </Grid>
        </Box>
      </Container>
    </LoadingWrapper>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));
