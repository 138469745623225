import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton/IconButton';
import ListSubheader from '@material-ui/core/ListSubheader/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider/Slider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Close from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import { useFormik } from 'formik';

import { RoundButton } from '../../components/round-button';
import { Attribute, Option } from '../../interfaces/models/attribute';
import { Brand } from '../../interfaces/models/brand';
import { Category } from '../../interfaces/models/category';
import { AttrFilter, PostFilters } from '../../interfaces/requests/posts';
import { formatCurrency, rangePow10 } from '../../utils/helpers';
import { MexicoStates } from '../../utils/data';

const MIN_PRICE = 0;
const MAX_PRICE = 28;

const useStyles = makeStyles(() => ({
  drawerPaper: {
    width: '100%',
    maxWidth: '360px',
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface FilterValues extends PostFilters {
  categoryId: number | string;
  brandIds: number[];
  minPrice: number;
  maxPrice: number;
  attributes: AttrFilter[];
  location: string;
}

interface HomeFilterProps {
  attributes: Attribute[];
  brands: Brand[];
  categories: Category[];
  open: boolean;
  onClose: () => void;
  filters: PostFilters;
  onChange: (filters: PostFilters) => void;
}
export const HomeFilters: React.FC<HomeFilterProps> = ({
  categories,
  attributes,
  brands,
  open,
  onClose,
  filters,
  onChange,
}) => {
  const classes = useStyles();
  const [categoryId, setCategoryId] = useState<number>();
  const [currentAttrs, setCurrentAttrs] = useState<Attribute[]>([]);

  const getInitial = () => {
    return {
      categoryId: filters.categoryId || '',
      brandIds: Object.values(filters.brandIds || []).map((i) => +i) || [],
      minPrice: filters.minPrice || MIN_PRICE,
      maxPrice: filters.maxPrice || MAX_PRICE,
      attributes: [],
      location: filters.location || '',
    };
  };

  const { setFieldValue, ...formik } = useFormik<FilterValues>({
    initialValues: getInitial(),
    enableReinitialize: true,
    onSubmit: async (values) => {
      const params: PostFilters = {};
      if (values.categoryId) {
        params.categoryId = values.categoryId;
      }
      if (values.brandIds?.length) {
        params.brandIds = values.brandIds;
      }
      if (values.minPrice > MIN_PRICE) {
        params.minPrice = values.minPrice;
      }
      if (values.maxPrice < MAX_PRICE) {
        params.maxPrice = values.maxPrice;
      }
      if (values.maxPrice < MAX_PRICE) {
        params.maxPrice = values.maxPrice;
      }
      if (values.attributes?.some((a) => a.options.length)) {
        params.attributes = values.attributes.filter((a) => a.options.length);
      }
      if (values.location) {
        params.location = values.location;
      }
      onChange(params);
      onClose();
    },
  });

  useEffect(() => {
    const category = categories.find((c) => c.id === categoryId);
    if (!category) {
      return setCurrentAttrs([]);
    }
    const items = category.attributes.reduce((carry: Attribute[], ca) => {
      const attr = attributes.find((a) => a.id === ca.id);
      if (!attr) {
        return carry;
      }
      const options = attr.options.filter((o) => ca.options.includes(o.value));
      return carry.concat({ ...attr, options });
    }, []);

    setFieldValue(
      'attributes',
      items.map((a) => {
        const qryAttr = filters.attributes?.find(
          (qa: AttrFilter) => a.id === +qa.id,
        );
        return {
          id: a.id.toString(),
          options: qryAttr?.options?.map((i) => +i) || [],
        };
      }),
    );
    setCurrentAttrs(items);
  }, [categoryId, categories, attributes, filters, setFieldValue]);

  const groupOptions = (options: Option[]) => {
    return Object.entries(
      options.reduce((carry: { [group: string]: Option[] }, option) => {
        if (!carry[option.group || '']) {
          carry[option.group || ''] = [];
        }
        carry[option.group || ''].push(option);
        return carry;
      }, {}),
    );
  };

  const getErrorProps = (field: string) => {
    const meta = formik.getFieldMeta(field);
    return {
      error: meta.touched && !!meta.error,
      helperText: meta.touched && meta.error,
    };
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
        onSubmit={formik.handleSubmit}
      >
        <Container>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height={{ xs: 56, md: 72 }}
          >
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
            <IconButton onClick={onClose}>
              <Delete />
            </IconButton>
          </Box>
        </Container>
        <Box flexGrow={1} flexShrink={1} overflow="auto">
          <Container>
            <TextField
              variant="outlined"
              select
              label="Categoría"
              name="categoryId"
              value={formik.values.categoryId}
              onChange={(e) => {
                setCategoryId(Number(e.target.value));
                formik.handleChange(e);
              }}
              {...getErrorProps('categoryId')}
            >
              <MenuItem value={0}>Todas</MenuItem>
              {categories.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </TextField>
            <Autocomplete
              multiple
              options={brands}
              getOptionLabel={(brand) => brand.name}
              value={brands.filter((brand) =>
                formik.values.brandIds?.includes(brand.id),
              )}
              onChange={(e, value) =>
                setFieldValue(
                  'brandIds',
                  value.map(({ id }) => id),
                )
              }
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Marcas" />
              )}
              popupIcon={<KeyboardArrowDown color="secondary" />}
            />
            <Slider
              min={0}
              max={28}
              step={1}
              scale={(x) =>
                ((x % 10) + Math.floor(x / 10)) *
                Math.pow(10, Math.ceil((x + 1) / 10))
              }
              valueLabelFormat={(x) => formatCurrency(x, false)}
              value={[formik.values.minPrice || 0, formik.values.maxPrice || 0]}
              onChange={(e, value) => {
                if (!Array.isArray(value)) {
                  return;
                }
                setFieldValue('minPrice', value[0]);
                setFieldValue('maxPrice', value[1]);
              }}
              valueLabelDisplay="off"
            />
            <Box display="flex" justifyContent="space-between">
              <Box>
                Desde{' '}
                {formatCurrency(rangePow10(formik.values.minPrice || 0), false)}
              </Box>
              <Box>
                Hasta{' '}
                {formatCurrency(rangePow10(formik.values.maxPrice || 0), false)}
              </Box>
            </Box>
            {currentAttrs.map((attr, i) => (
              <React.Fragment key={attr.id}>
                <TextField
                  variant="outlined"
                  select
                  SelectProps={{
                    multiple: true,
                    renderValue: (value) => {
                      if (!Array.isArray(value)) {
                        return '';
                      }
                      const names: string[] = [];
                      return attr.options
                        .reduce((carry, option) => {
                          if (value.includes(option.value)) {
                            carry.push(option.label);
                          }
                          return carry;
                        }, names)
                        .join(', ');
                    },
                  }}
                  label={attr.name}
                  name={`attributes.${i}.options`}
                  value={formik.values.attributes[i]?.options}
                  onChange={formik.handleChange}
                  {...getErrorProps(`attributes.${i}.options`)}
                >
                  {groupOptions(attr.options).map(([g, options]) => [
                    g && <ListSubheader>{g}</ListSubheader>,
                    options.map((o) => (
                      <MenuItem key={o.value} value={o.value}>
                        <Checkbox
                          checked={formik.values.attributes[
                            i
                          ]?.options.includes(o.value)}
                          readOnly
                        />
                        {o.label}
                      </MenuItem>
                    )),
                  ])}
                </TextField>
              </React.Fragment>
            ))}
            <TextField
              variant="outlined"
              select
              label="Ubicación"
              name="location"
              value={formik.values.location}
              onChange={formik.handleChange}
              {...getErrorProps('location')}
            >
              <MenuItem value="">Todos</MenuItem>
              {MexicoStates.map((state) => (
                <MenuItem key={state.clave} value={state.nombre}>
                  {state.nombre}
                </MenuItem>
              ))}
            </TextField>
          </Container>
        </Box>
        <Box flexShrink={0} py={2}>
          <Container>
            <RoundButton type="submit" loading={false} fullWidth>
              Aplicar
            </RoundButton>
          </Container>
        </Box>
      </form>
    </Drawer>
  );
};
