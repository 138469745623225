import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Close from '@material-ui/icons/Close';

import { Login } from './login';
import { Signup } from './signup';
import { TabPanel } from '../../components/tab-panel';
import { authActions } from '../../actions';
import { RootState } from '../../reducers';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    maxWidth: '640px',
    height: '100vh',
    outline: 0,
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      boxSizing: 'border-box',
      height: 'auto',
      maxHeight: 'calc(100vh - 48px)',
    },
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      minHeight: theme.spacing(8),
    },
    '& .MuiTab-root': {
      [theme.breakpoints.up('sm')]: {
        fontSize: '1rem',
      },
    },
    '& .Mui-selected': {
      fontWeight: '600',
    },
  },
}));

export const Auth: FunctionComponent = () => {
  const classes = useStyles();
  const [tab, setTab] = React.useState(0);
  const showAuth = useSelector<RootState, boolean>(
    (state) => state.authentication.showAuth || false,
  );
  const dispatch = useDispatch();

  const onTabChange = (_: React.ChangeEvent<{}>, tabIndex: number) => {
    setTab(tabIndex);
  };

  const handleClose = () => {
    dispatch(authActions.cancelAuth());
  };

  return (
    <Modal
      className={classes.modal}
      open={showAuth}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      disableEnforceFocus
    >
      <Fade in={showAuth}>
        <Paper className={classes.paper}>
          <AppBar position="relative" color="default">
            <Box display="flex" alignItems="center">
              <Box width={48}></Box>
              <Box flexGrow={1}>
                <Tabs
                  textColor="primary"
                  indicatorColor="primary"
                  variant="fullWidth"
                  centered={true}
                  value={tab}
                  onChange={onTabChange}
                  className={classes.tabs}
                >
                  <Tab wrapped label="Iniciar sesión" />
                  <Tab wrapped label="Regístrate" />
                </Tabs>
              </Box>
              <IconButton onClick={handleClose}>
                <Close fontSize="inherit" />
              </IconButton>
            </Box>
          </AppBar>
          <Box overflow="auto" py={4} px={{ xs: 2, sm: 16 }}>
            <TabPanel value={tab} index={0}>
              <Login />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Signup />
            </TabPanel>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
