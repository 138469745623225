export default {
  baseUrl: process.env.REACT_APP_API_URL,

  openPay: {
    merchantId: process.env.REACT_APP_OPENPAY_MERCHANT_ID,
    publicKey: process.env.REACT_APP_OPENPAY_PUBLIC_KEY,
    production: process.env.REACT_APP_OPENPAY_PRODUCTION === 'true',
  },

  paypal: {
    environment:
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? process.env.REACT_APP_ENVIRONMENT
        : 'sandbox',
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || '',
  },

  social: {
    facebookAppId: process.env.REACT_APP_FB_APP_ID || '',
    googleClientId: process.env.REACT_APP_GG_APP_ID || '',
    appleClientId: process.env.REACT_APP_AP_APP_ID || '',
    appleRedirectUri: process.env.REACT_APP_AP_REDIRECT || '',
  },
};
