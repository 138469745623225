import React from 'react';
import { makeStyles } from '@material-ui/core';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { PostPictures } from '../../../interfaces/models/post';

SwiperCore.use([Navigation, Pagination]);

const useStyles = makeStyles((theme) => ({
  slide: {
    padding: '50% 0',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: 'url(https://picsum.photos/id/237/600/600)',
  },
  swiper: {
    '&.swiper-container-horizontal > .swiper-pagination-bullets': {
      bottom: 0,
      padding: theme.spacing(4, 0, 2),
    },
    '& .swiper-pagination-bullet': {
      background: '#fff',
    },
    '& .swiper-button-prev, & .swiper-button-next': {
      color: '#9CA0AA',
      width: '24px',
      height: '24px',
      backgroundColor: '#fff',
      borderRadius: '12px',
      boxShadow: '0px 3px 6px #00000029',
      '&:after': {
        fontSize: '12px',
      },
    },
  },
  pagination: {
    background: 'linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)',
  },
}));

interface PostSwipperProps {
  pictures: PostPictures;
}

const keys: (keyof PostPictures)[] = [
  'front',
  'back',
  'tag',
  'extra1',
  'extra2',
  'extra3',
];

export const PostSwipper: React.FC<PostSwipperProps> = ({ pictures }) => {
  const classes = useStyles();
  const urls = keys.map((k) => pictures[k]).filter((i) => i);
  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true, clickableClass: classes.pagination }}
      className={classes.swiper}
    >
      {urls.map((url) => (
        <SwiperSlide
          key={url}
          className={classes.slide}
          style={{ backgroundImage: `url(${url})` }}
        />
      ))}
    </Swiper>
  );
};
