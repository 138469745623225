import React, { useState, useEffect } from 'react';
import { OrderDetail } from '../../../../interfaces/models/oders/order-detail';
import { GetIndex, OptionsApi } from '../../../../api/options-api';
import { LoadingWrapper } from '../../../../components/loading-wrapper';
import { Option, OptionType } from '../../../../interfaces/models/options';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField,
  Typography,
  makeStyles,
  Box,
  DialogActions,
} from '@material-ui/core';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import { RoundButton } from '../../../../components/round-button';
import { HelpApi } from '../../../../api/help-api';

const useStyles = makeStyles(() => ({
  button: {
    color: 'white',
  },
}));

interface OrderDetailHelpProps {
  order: OrderDetail;
  onCloseModal(key?: string): void;
  setSnackState(state?: object): void;
}

interface SelectState {
  orderId: number;
  topic: string;
  altTopic: string;
}

export const OrderDetailHelp: React.FC<OrderDetailHelpProps> = ({
  order,
  onCloseModal,
  setSnackState,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<GetIndex>();
  const [select, setSelect] = React.useState<SelectState>({
    orderId: order.id || 0,
    topic: '',
    altTopic: '',
  });

  useEffect(() => {
    const initData = async () => {
      setResponse(await OptionsApi.index(OptionType.ORDER_SUPPORT));
      setLoading(false);
    };
    initData();
  }, []);

  const handleAltTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelect({ ...select, altTopic: event.target.value });
  };

  const handleSelection = (topic: string) => {
    setSelect({ ...select, topic });
  };

  const onSubmit = async () => {
    setLoading(true);
    const { errors } = await HelpApi.postReport(select);
    if (errors) {
      await setSnackState({
        open: true,
        message:
          'Surgió un problema, favor de intentar mandar el reporte más tarde',
        severity: 'error',
      });
      setLoading(false);
    } else {
      await setSnackState({
        open: true,
        message: 'Hemos enviado tu reporte, muchas gracias',
        severity: 'success',
      });
      setTimeout(() => {
        onCloseModal('help');
      }, 1000);
    }
  };

  return (
    <LoadingWrapper loading={loading} errors={response?.errors}>
      {!loading && response && response.options && response.options.length > 0 && (
        <div>
          <List dense>
            {response.options.map((option: Option) => {
              const selected = Boolean(
                select.topic !== '' && select.topic === option.code,
              );
              return (
                <ListItem
                  button
                  selected={selected}
                  onClick={() => handleSelection(option.code)}
                  key={option.code}
                >
                  <ListItemIcon>
                    {selected ? (
                      <RadioButtonChecked />
                    ) : (
                      <RadioButtonUnchecked />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </ListItem>
              );
            })}
            {select.topic !== '' && select.topic === 'other' && (
              <Box px={2}>
                <TextField
                  autoFocus
                  id="altTopic-multiline-flexible"
                  variant="outlined"
                  label="Cuentanos ..."
                  multiline
                  maxRows={4}
                  value={select.altTopic}
                  onChange={handleAltTopicChange}
                />
              </Box>
            )}
          </List>
        </div>
      )}
      <DialogActions>
        <RoundButton
          size={'small'}
          loading={loading}
          disabled={select.topic === ''}
          onClick={onSubmit}
        >
          <Typography className={classes.button}>{'Enviar reporte'}</Typography>
        </RoundButton>
      </DialogActions>
    </LoadingWrapper>
  );
};
