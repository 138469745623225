import React, { useCallback } from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  IResolveParams,
  objectType,
} from 'reactjs-social-login';
import AppleSignin from 'react-apple-signin-auth';

import {
  AppleButton,
  FacebookButton,
  GoogleButton,
} from '../../components/social-buttons';
import config from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { AnyAction } from 'redux';
import { authActions } from '../../actions';
import { LoginRequest } from '../../interfaces/requests/auth/login-request';
import { AuthProvider } from '../../services';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1, 0),
  },
  bigSpacing: {
    margin: theme.spacing(2, 0),
  },
}));

export const SocialSignin = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loggingIn = useSelector<RootState, boolean>(
    (state) => state.authentication.loggingIn || false,
  );
  const provider = useSelector<RootState, AuthProvider | undefined>(
    (state) => state.authentication.provider,
  );
  const next = useSelector<RootState, AnyAction | undefined>(
    (state) => state.authentication.next,
  );

  const onLoginStart = useCallback((provider: AuthProvider) => {
    dispatch(authActions.authenticating(provider));
  }, []);

  const onLoginSuccess = useCallback(({ provider, data }: IResolveParams) => {
    console.log(provider, data);
    const input: LoginRequest = { provider, user: '', secret: '' };
    switch (provider) {
      case AuthProvider.GOOGLE:
        input.user = data?.id;
        input.secret = data?.access_token;
        break;
      case AuthProvider.FACEBOOK:
        input.user = data?.userID;
        input.secret = data?.accessToken;
        break;
      case AuthProvider.APPLE:
        input.user = JSON.stringify(data?.user ?? {});
        input.secret = data?.authorization?.id_token;
        break;
      default:
        dispatch(authActions.failure('Proveedor no soportado'));
        return;
    }

    dispatch(authActions.login(input, next));
  }, []);

  const onLoginFail = useCallback(() => {
    dispatch(authActions.failure('Error login social.'));
  }, []);

  return (
    <React.Fragment>
      <Divider className={classes.bigSpacing} />
      <div className={classes.margin}>
        <Typography variant="caption" color="textSecondary">
          Continuar con
        </Typography>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <AppleSignin
            /** Auth options passed to AppleID.auth.init() */
            authOptions={{
              /** Client ID - eg: 'com.example.com' */
              clientId: config.social.appleClientId,
              /** Requested scopes, seperated by spaces - eg: 'email name' */
              scope: 'email name',
              /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
              redirectURI: config.social.appleRedirectUri,
              /** Uses popup auth instead of redirection */
              usePopup: true,
              state: '',
              nonce: 'nonce',
            }} // REQUIRED
            /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
            onSuccess={(data: objectType) =>
              onLoginSuccess({ provider: AuthProvider.APPLE, data })
            } // default = undefined
            /** Called upon signin error */
            onError={onLoginFail} // default = undefined
            /** Skips loading the apple script if true */
            skipScript={false} // default = undefined
            /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            render={(props: any) => (
              <AppleButton {...props}>My Custom Button</AppleButton>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LoginSocialFacebook
            appId={config.social.facebookAppId}
            onLoginStart={() => onLoginStart(AuthProvider.FACEBOOK)}
            onResolve={onLoginSuccess}
            onReject={onLoginFail}
            cookie={false}
            state={false}
            xfbml={false}
          >
            <FacebookButton
              disabled={loggingIn}
              loading={provider === AuthProvider.FACEBOOK && loggingIn}
            />
          </LoginSocialFacebook>
        </Grid>
        <Grid item xs={12} md={6}>
          <LoginSocialGoogle
            client_id={config.social.googleClientId}
            onLoginStart={() => onLoginStart(AuthProvider.GOOGLE)}
            onResolve={onLoginSuccess}
            onReject={onLoginFail}
          >
            <GoogleButton
              disabled={loggingIn}
              loading={provider === AuthProvider.GOOGLE && loggingIn}
            />
          </LoginSocialGoogle>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
