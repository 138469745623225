import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { AuthApi } from '../../api/auth-api';
import { AlertMessage } from '../../components/alert-message';
import { AuthService } from '../../services/auth-service';

export const VerificationAlert = () => {
  const email = AuthService.getUser()?.email;
  const verifyEmailAlert =
    AuthService.isAuth() && !AuthService.getUser()?.isVerified;

  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);

  const sendVerificationEmail = async () => {
    if (!email) {
      return;
    }
    setSending(true);
    setSuccess(false);
    const { errors } = await AuthApi.resendVerification(email);
    if (!errors) {
      setSuccess(true);
    }
    setSending(false);
  };

  if (!verifyEmailAlert) {
    return null;
  }

  return (
    <React.Fragment>
      {success ? (
        <AlertMessage
          severity="success"
          messages={{
            m: 'Por favor sigue las instrucciones que enviamos a tu correo.',
          }}
          onClose={() => setSuccess(false)}
        />
      ) : (
        <AlertMessage
          severity="info"
          messages={{
            m: `Confirme su dirección de correo electrónico: ${email}`,
          }}
          mt={0}
          mb={0}
          action={
            <Button
              color="inherit"
              disabled={sending}
              onClick={sendVerificationEmail}
            >
              Reenviar correo
            </Button>
          }
        />
      )}
    </React.Fragment>
  );
};
