import React, { ReactNode, useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVert from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';

import { AddressesApi, AddressesIndex } from '../../../api/addresses-api';
import { BorderedList } from '../../../components/bordered-list';
import { ConfirmDialog } from '../../../components/confirm-dialog';
import { LoadingWrapper } from '../../../components/loading-wrapper';
import { Messages } from '../../../interfaces/responses/response';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { Address } from '../../../interfaces/models/address';

interface AddressAction {
  icon: ReactNode;
  label: string;
  onAction: (id: number) => void;
}

interface AddressesListProps {
  actions?: AddressAction[];
  upatedItem?: Address;
  selectable?: {
    value?: number | string;
    onChange: (address?: Address) => void;
  };
}

export const AddressesList: React.FC<AddressesListProps> = ({
  selectable,
  upatedItem,
  actions = [],
}) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<AddressesIndex>({ addresses: [] });

  const [menuRef, setMenuRef] = useState<null | HTMLElement>(null);
  const [indexId, setIndexId] = useState<number[]>([-1, 0]);

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errorsDelete, setErrorsDelete] = useState<Messages>();

  useEffect(() => {
    const fetchAddresses = async () => {
      setResponse(await AddressesApi.index());
      setLoading(false);
    };
    loading && fetchAddresses();
  }, [loading]);

  useEffect(() => {
    setLoading(true);
  }, [upatedItem]);

  const openMenu = (ref: HTMLElement, index: number, id: number) => {
    setMenuRef(ref);
    setIndexId([index, id]);
  };

  const closeMenu = () => {
    setMenuRef(null);
  };

  const openDeleteModal = () => {
    closeMenu();
    setDeleteModalOpened(true);
  };

  const closeDeleteModal = async () => {
    setDeleteModalOpened(false);
  };

  const handleDelete = async () => {
    const [index, id] = indexId;
    setDeleting(true);
    const { errors } = await AddressesApi.delete(id);
    setErrorsDelete(errors);
    if (!errors) {
      response.addresses.splice(index, 1);
      setResponse({
        addresses: response.addresses,
      });
      closeDeleteModal();
      if (Number(selectable?.value) === id) {
        selectable?.onChange();
      }
    }
    setDeleting(false);
  };

  const onItemSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectable) {
      return;
    }
    const id = e.target.value;
    const item = response.addresses.find((add) => `${add.id}` === id);
    selectable.onChange(item);
  };

  return (
    <LoadingWrapper loading={loading} errors={response.errors}>
      <React.Fragment>
        <List
          disablePadding
          component={RadioGroup}
          value={selectable?.value?.toString()}
          onChange={onItemSelected}
        >
          {response.addresses.map((address, i) => (
            <BorderedList key={address.id}>
              {selectable && (
                <ListItemIcon>
                  <Radio value={address.id.toString()} />
                </ListItemIcon>
              )}
              <ListItemText
                primary={address.name}
                secondary={address.formatted}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={(e) => openMenu(e.currentTarget, i, address.id)}
                >
                  <MoreVert />
                </IconButton>
              </ListItemSecondaryAction>
            </BorderedList>
          ))}
        </List>
        <Menu
          keepMounted
          open={Boolean(menuRef)}
          anchorEl={menuRef}
          onClose={closeMenu}
        >
          {actions.map((action) => (
            <MenuItem
              key={action.label}
              onClick={() => {
                closeMenu();
                action.onAction(indexId[1]);
              }}
            >
              <ListItemIcon>{action.icon}</ListItemIcon>
              <Typography variant="inherit">{action.label}</Typography>
            </MenuItem>
          ))}
          <MenuItem button onClick={openDeleteModal}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <Typography variant="inherit">Eliminar</Typography>
          </MenuItem>
        </Menu>
        <ConfirmDialog
          open={deleteModalOpened}
          onCancel={closeDeleteModal}
          onConfirm={handleDelete}
          title="Eliminar dirección"
          confirmText="Eliminar"
          loading={deleting}
          errors={errorsDelete}
        >
          ¿Estas seguro de eliminar esta dirección?. <br />
          Esta acción no puede revertirse.
        </ConfirmDialog>
      </React.Fragment>
    </LoadingWrapper>
  );
};
