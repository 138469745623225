import { AuthUser } from '../interfaces/models/auth-user';
import { parseJson } from '../utils/helpers';
import { AuthApi } from '../api/auth-api';
import { LoginRequest } from '../interfaces/requests/auth/login-request';
import { SignupRequest } from '../interfaces/requests/auth/signup-request';

export const setToken = (accessToken: string) => {
  window.localStorage.setItem('accessToken', accessToken);
};

export const setUser = (user: AuthUser) => {
  window.localStorage.setItem('user', JSON.stringify(user || {}));
};

export const getToken = (): string | null => {
  return window.localStorage.getItem('accessToken');
};

export const getUser = (): AuthUser | undefined => {
  return parseJson(window.localStorage.getItem('user') || '');
};

export const isAuth = (): boolean => {
  return !!getToken();
};

export const isGuest = () => !isAuth();

const signup = async (data: SignupRequest) => {
  const res = await AuthApi.doSignup(data);
  if (res.errors) {
    throw new Error(Object.values(res.errors).shift());
  }
  if (res.user && res.token) {
    setUser(res.user);
    setToken(res.token);
  }
  return res.user;
};

const login = async (data: LoginRequest) => {
  const res = await AuthApi.doLogin(data);
  if (res.errors) {
    throw new Error(Object.values(res.errors).shift());
  }
  if (res.user && res.token) {
    setUser(res.user);
    setToken(res.token);
  }
  return res.user;
};

const logout = async () => {
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('accessToken');
  window.location.href = '/';
};

export const AuthService = {
  signup,
  login,
  logout,
  isAuth,
  isGuest,
  setToken,
  getToken,
  setUser,
  getUser,
};
