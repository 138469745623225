import React, { ReactElement } from 'react';
import { Stepper, Step, StepLabel, Box } from '@material-ui/core';
import { OrderDetail } from '../../../interfaces/models/oders/order-detail';

interface Props {
  order: OrderDetail;
}

export default function OrderDetailSteps({ order }: Props): ReactElement {
  const setActiveStep = () => {
    if (order.status === 'rejected') {
      return -1;
    }
    if (order.status === 'accepted' || order.status === 'partial') {
      if (
        order.shipment &&
        Object.keys(order.shipment).length > 0 &&
        order.shipment.status === 'DL'
      ) {
        return 2;
      }
      return 1;
    }
    return 0;
  };
  return (
    <Box marginTop={6}>
      <Stepper activeStep={setActiveStep()} alternativeLabel>
        <Step>
          <StepLabel>Preparación</StepLabel>
        </Step>
        <Step>
          <StepLabel>Documentado</StepLabel>
        </Step>
        <Step>
          <StepLabel>Entregado</StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
}
