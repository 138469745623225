import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Delete from '@material-ui/icons/Delete';

import { CartDetail } from '../../interfaces/models/cart';
import { formatCurrency } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  avatar: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  itemPicture: {
    position: 'relative',
    borderRight: '1px solid',
    borderRightColor: theme.palette.divider,
  },
}));

interface CartDetailProps {
  item: CartDetail;
  onRemove?: (id: number) => void;
  guttterTop?: boolean;
}
export const CartPostItem: React.FC<CartDetailProps> = ({
  item,
  guttterTop = false,
  onRemove,
}) => {
  const classes = useStyles();
  return (
    <Box mt={+guttterTop}>
      <Paper variant="outlined">
        <Box display="flex">
          <Box
            width={86}
            height={86}
            style={{
              backgroundImage: `url(${item.pictureUrl})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
            className={classes.itemPicture}
            flexShrink={0}
          />
          <Box p={1} overflow="hidden" flexGrow={1}>
            <Typography variant="h6" component="p" noWrap>
              {item.name}
            </Typography>
            <Typography variant="caption" component="div" noWrap>
              Marca: {item.brand?.name}
            </Typography>
            <Typography variant="caption" component="div" noWrap>
              {item.attributes
                .map((attr) => `${attr.label}: ${attr.value}`)
                .join(' | ')}
            </Typography>
          </Box>
          <Box
            px={2}
            py={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            {onRemove && (
              <IconButton size="small" onClick={() => onRemove(item.postId)}>
                <Delete />
              </IconButton>
            )}

            <Typography noWrap color="secondary" component="div">
              <Box fontWeight="bold">{formatCurrency(item.price)}</Box>
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
