import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';

import { BaseUser } from '../interfaces/models/user/base-user';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  avatar: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
  },
}));

interface BorderAvatarProps {
  src?: string;
}
export const BorderedAvatar: React.FC<BorderAvatarProps> = ({ src }) => {
  const classes = useStyles();
  return (
    <Avatar
      src={src || '/img/placeholder-profile.svg'}
      className={classes.avatar}
    />
  );
};

interface UserAvatarProps {
  user: BaseUser;
  showName?: boolean;
}
export const UserAvatar: React.FC<UserAvatarProps> = ({
  user,
  showName = true,
}) => {
  return (
    <Link
      component={RouterLink}
      to={`/users/${user.id}`}
      color="inherit"
      underline="none"
    >
      <Box display="flex" alignItems="center" justifyContent="start">
        <BorderedAvatar src={user.pictureUrl} />
        {showName && (
          <Box ml={1}>
            <Typography variant="body2" color="secondary">
              <Box fontWeight={700} component="span">
                &nbsp;{user.firstName}
                &nbsp;{user.lastName}
              </Box>
            </Typography>
            <Rating value={user.rate || 0} readOnly precision={0.5} />
          </Box>
        )}
      </Box>
    </Link>
  );
};
