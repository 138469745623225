import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { CartsCheckout } from './carts-checkout';

export const CartRoutes = () => {
  const matchPath = useRouteMatch();

  return (
    <Switch>
      <Route path={`${matchPath.url}/:id/checkout`}>
        {(matchPath) => (
          <CartsCheckout id={+(matchPath.match?.params.id ?? 0)} />
        )}
      </Route>
    </Switch>
  );
};
