import { Api } from './api-base';
import { Messages, Response } from '../interfaces/responses/response';

export interface StatesIndex {
  errors?: Messages;
  states: string[];
}

export const StatesApi = {
  index: async (): Promise<StatesIndex> => {
    const { errors, response } = await Api.get<Response<string[]>>('/states');
    return { errors, states: response?.data || [] };
  },
};
