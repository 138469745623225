import { Dialog, DialogContent, List, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import React, { useEffect, useState } from 'react';
import { UsersApi } from '../../../../api/users-api';
import { LoadingWrapper } from '../../../../components/loading-wrapper';
import { RoundButton } from '../../../../components/round-button';
import { BorderedAvatar } from '../../../../components/user-avatar';
import { UserItem } from '../../../../interfaces/models/user-item';
import { AuthService } from '../../../../services/auth-service';

interface UserFollowsProps {
  userId: number;
  open: boolean;
  onClose: () => void;
}
export const UserFollows: React.FC<UserFollowsProps> = ({
  userId,
  open,
  onClose,
}) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<UserItem[]>([]);

  useEffect(() => {
    const fetch = async () => {
      setItems([]);
      const { errors, users } = await UsersApi.followers(userId);
      if (!errors) {
        setItems(users);
      }
      setLoading(false);
    };
    loading && fetch();
  }, [loading, userId]);

  const isMe = (id: number) => {
    return AuthService.getUser()?.id === id;
  };

  const toggleFollow = async (i: number) => {
    const user = items[i];
    if (!user) {
      return;
    }
    user.follows = !user.follows;
    setItems([...items]);
    const { errors } = await UsersApi.follow(user.id);
    if (errors) {
      user.follows = !user.follows;
      setItems([...items]);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <LoadingWrapper loading={loading}>
        {items.length === 0 ? (
          <DialogContent>
            <Box textAlign="center">
              <Typography variant="body1" color="textSecondary" gutterBottom>
                No hay seguidores
              </Typography>
            </Box>
          </DialogContent>
        ) : (
          <List>
            {items.map((user, i) => (
              <ListItem key={`${user.id}-${user.follows}`}>
                <ListItemAvatar>
                  <BorderedAvatar src={user.pictureUrl} />
                </ListItemAvatar>
                <ListItemText
                  primary={`${user.firstName || ''} ${user.lastName || ''}`}
                />
                <RoundButton
                  size="small"
                  color={user.follows ? 'primary' : 'secondary'}
                  disabled={isMe(user.id)}
                  onClick={() => toggleFollow(i)}
                >
                  {user.follows ? 'Siguiendo' : 'Seguir'}
                </RoundButton>
              </ListItem>
            ))}
          </List>
        )}
      </LoadingWrapper>
    </Dialog>
  );
};
