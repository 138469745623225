import { UserItem } from '../user-item';

export interface Comment {
  id: number;
  user: UserItem;
  text: string;
  createdAt: string;
}

export enum BargainStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export interface Bargain {
  id: number;
  status: BargainStatus;
  amount: number;
}

export interface Question extends Comment {
  bargain?: Bargain;
  answers?: Comment[];
}
