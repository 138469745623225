import React from 'react';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Tab, { TabProps } from '@material-ui/core/Tab';

export const OutlinedTab = withStyles((theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      margin: theme.spacing(0, 0.5),
      padding: theme.spacing(0.5, 4),
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
      '&:hover': {
        color: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
        opacity: 1,
      },
      '&$selected': {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '&:focus': {
        color: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
      },
    },
    labelIcon: {
      '& .MuiTab-wrapper > *:first-child': {
        marginBottom: 0,
      },
    },
    selected: {},
  }),
)((props: TabProps) => <Tab disableRipple {...props} />);
