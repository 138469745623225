import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';

interface TabPanelProps {
  value: number;
  index: number;
}

export const TabPanel: FunctionComponent<TabPanelProps> = ({
  value,
  index,
  children,
}) => {
  return (
    <Box role="tabpanel" hidden={value !== index}>
      {value === index && children}
    </Box>
  );
};
