import { Api } from './api-base';
import { Response, Messages } from '../interfaces/responses/response';

export interface SetSupportReport {
  errors?: Messages;
  response?: any;
}

export const SupportApi = {
  postSupportReport: async (comments: string): Promise<SetSupportReport> => {
    const { errors, response } = await Api.post<Response<void>>(
      '/support-cases',
      { comments },
    );
    return { errors, response };
  },
};
