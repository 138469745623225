import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    transition: 'color 0.35s linear',
  },
});

export const Loading = () => {
  const [color, setColor] = useState<CircularProgressProps['color']>('primary');
  const classes = useStyles();

  useEffect(() => {
    const id = setInterval(() => {
      setColor(color === 'primary' ? 'secondary' : 'primary');
    }, 1400);
    return () => clearInterval(id);
  });

  return (
    <Box
      height={1}
      width={1}
      position="absolute"
      top={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="background.default"
      zIndex="speedDial"
    >
      <CircularProgress classes={classes} color={color} />
    </Box>
  );
};
