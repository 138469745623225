import config from '../config';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    OpenPay: any;
  }
}

const OpenPay = window.OpenPay;

OpenPay.setId(config.openPay.merchantId);
OpenPay.setApiKey(config.openPay.publicKey);
OpenPay.setSandboxMode(!config.openPay.production);

interface TokenResponse {
  tokenId?: string;
  error?: string;
}

interface CardInput {
  holderName: string;
  cardNumber: string;
  expirationYear: string;
  expirationMonth: string;
  cvv2: string;
}

const getMessageError = (code: number) => {
  switch (code) {
    case 1000:
      return 'Servicio no disponible.';
    case 1001:
      return 'Los campos no tienen el formato correcto, o la petición no tiene campos que son requeridos.';
    case 1004:
      return 'Servicio no disponible.';
    case 1005:
      return 'Servicio no disponible.';
    case 2004:
      return 'El número de tarjeta es invalido.';
    case 2005:
      return 'La fecha de expiración de la tarjeta es anterior a la fecha actual.';
    case 2006:
      return 'El código de seguridad de la tarjeta (CVV) no fue proporcionado.';
    default:
      return 'La petición no pudo ser procesada.';
  }
};

export const OpenPayService = {
  getDeviceId: (): string => OpenPay.deviceData.setup(),
  generateToken: async (input: CardInput) => {
    return new Promise<TokenResponse>((resolve) => {
      OpenPay.token.create(
        {
          holder_name: input.holderName,
          card_number: input.cardNumber,
          expiration_year: input.expirationYear,
          expiration_month: input.expirationMonth,
          cvv2: input.cvv2,
        },
        (response: { data: { id: string } }) =>
          resolve({ tokenId: `${response?.data?.id}` }),
        (response: { data: { error_code: number } }) =>
          resolve({ error: getMessageError(response?.data?.error_code) }),
      );
    });
  },
};
