import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Comment from '@material-ui/icons/ModeCommentOutlined';
import Share from '@material-ui/icons/Share';
import Warning from '@material-ui/icons/Warning';
import Favorite from '@material-ui/icons/Favorite';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PostsApi } from '../../../api/posts-api';

interface DetailActionsProps {
  id: number;
  commentsCount: number;
  favoritesCount: number;
  userId?: number;
  likeToUser?: boolean;
}
export const DetailActions: React.FC<DetailActionsProps> = ({
  id,
  commentsCount,
  favoritesCount,
  userId,
  likeToUser = false,
}) => {
  const [like, setLike] = useState(likeToUser);
  const [likesCount, setLikesCount] = useState(favoritesCount);

  useEffect(() => setLike(likeToUser), [likeToUser]);
  useEffect(() => setLikesCount(favoritesCount), [favoritesCount]);

  const doToggle = async () => {
    const { errors, count } = await PostsApi.toggleLike(id);
    if (!errors) {
      setLike(!like);
      setLikesCount(count);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      color="text.secondary"
      my={1}
    >
      <Box ml={1}>
        <IconButton size="small" disabled={!userId} onClick={doToggle}>
          <Favorite color={like ? 'secondary' : 'inherit'} />
        </IconButton>
        ({likesCount})
      </Box>
      <Box ml={1}>
        <IconButton size="small">
          <Comment />
        </IconButton>
        ({commentsCount})
      </Box>
      <Box ml={1}>
        <IconButton size="small" component={Link} to={`/posts/${id}/report`}>
          <Warning />
        </IconButton>
      </Box>
      <Box ml={1}>
        <IconButton size="small">
          <Share />
        </IconButton>
      </Box>
    </Box>
  );
};
