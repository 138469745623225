import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { RoundButton } from '../../../components/round-button';
import { Address } from '../../../interfaces/models/address';
import { AddressPicker } from '../../user/addresses/address-picker';

interface StepAddressProps {
  value?: Address;
  onChange: (address?: Address) => void;
  onNext: () => void;
}
export const StepAddress: React.FC<StepAddressProps> = ({
  value,
  onChange,
  onNext,
}) => {
  return (
    <React.Fragment>
      <Box position="relative" minHeight={48}>
        <AddressPicker value={value?.id || ''} onChange={onChange} />
      </Box>
      <Box py={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <RoundButton fullWidth variant="outlined" disabled>
              Anterior
            </RoundButton>
          </Grid>
          <Grid item xs={6}>
            <RoundButton fullWidth disabled={!value} onClick={onNext}>
              Siguiente
            </RoundButton>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};
