import React from 'react';
import {
  OrderDetail,
  ReportValue,
  ReturnType,
} from '../../../../interfaces/models/oders/order-detail';
import {
  Dialog,
  useMediaQuery,
  useTheme,
  IconButton,
  makeStyles,
  DialogTitle,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { getActiveModal, checkOpenModal, getModalTitle } from './controller';

import { OrderDetailHelp } from './order-detail-help';
import { OrderDetailProblem } from './order-detail-problem';
import { OrderDetailConfirm } from './order-detail-confirm';
import { OrderDetailRefund } from './order-detail-refund';
import { OrderDetailRate } from './order-detail-rate';

interface OrderDetailModalProps {
  modalState?: any;
  order: OrderDetail;
  rejects: ReportValue[];
  getData(): void;
  onCloseModal(key?: string): void;
  setSnackState(state?: object): void;
  setReturnElement(state?: ReturnType): void;
  setRejectElements(state?: ReportValue): void;
}

const useStyles = makeStyles(() => ({
  title: {
    width: '100%',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'space-between',
  },
}));

export const OrderDetailModal: React.FC<OrderDetailModalProps> = ({
  modalState,
  order,
  rejects,
  getData,
  onCloseModal,
  setSnackState,
  setReturnElement,
  setRejectElements,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const currentModal: any = getActiveModal(modalState);
  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth={'sm'}
      fullScreen={fullScreen}
      open={checkOpenModal(modalState)}
      onClose={() => onCloseModal(currentModal)}
    >
      <div className={classes.title}>
        <DialogTitle>{getModalTitle(currentModal)}</DialogTitle>
        <IconButton onClick={() => onCloseModal(currentModal)}>
          <Close />
        </IconButton>
      </div>
      {currentModal === 'help' && (
        <OrderDetailHelp
          order={order}
          onCloseModal={onCloseModal}
          setSnackState={setSnackState}
        />
      )}
      {currentModal === 'refund' && (
        <OrderDetailRefund
          orderDetailId={modalState.id}
          onCloseModal={onCloseModal}
          setReturnElement={setReturnElement}
        />
      )}
      {currentModal === 'problem' && (
        <OrderDetailProblem
          id={modalState.id}
          onCloseModal={onCloseModal}
          setSnackState={setSnackState}
          setRejectElements={setRejectElements}
        />
      )}
      {currentModal === 'confirm' && (
        <OrderDetailConfirm
          order={order}
          getData={getData}
          rejects={rejects}
          orderId={order.id}
          onCloseModal={onCloseModal}
          setSnackState={setSnackState}
        />
      )}
      {currentModal === 'rate' && (
        <OrderDetailRate
          orderId={order.id}
          orderDetailId={modalState.id}
          getData={getData}
          onCloseModal={onCloseModal}
          setSnackState={setSnackState}
        />
      )}
    </Dialog>
  );
};
