import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React, { FunctionComponent } from 'react';
import { Messages } from '../interfaces/responses/response';

interface AlertMessageProps {
  messages: Messages;
  severity?: AlertProps['severity'];
  mt?: number;
  mb?: number;
  title?: string;
  icon?: React.ReactNode;
  onClose?: () => void;
  action?: React.ReactNode;
  outlined?: boolean;
}
export const AlertMessage: FunctionComponent<AlertMessageProps> = ({
  messages,
  severity = 'error',
  mt = 2,
  mb = 2,
  title = '',
  icon,
  onClose,
  action,
  outlined = false,
}) => {
  return (
    <Container maxWidth="sm" disableGutters>
      <Box mt={mt} mb={mb}>
        <Alert
          severity={severity}
          icon={icon}
          onClose={onClose}
          action={action}
          variant={outlined ? 'outlined' : 'standard'}
        >
          {title && <AlertTitle>{title}</AlertTitle>}
          {Object.values(messages).map((message, i) => (
            <Box key={message} mt={Number(i > 0)}>
              {message}
            </Box>
          ))}
        </Alert>
      </Box>
    </Container>
  );
};
