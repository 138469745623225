import React from 'react';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';

import { theme } from '../themes/default';
import { Navbar } from './navbar';
import { AppRoutes } from './app-routes';

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <BrowserRouter>
          <Box display="flex" flexDirection="column" minHeight="100vh">
            <Navbar />
            <Box
              id="content"
              position="relative"
              flexGrow={1}
              display="flex"
              flexDirection="column"
            >
              <AppRoutes />
            </Box>
          </Box>
        </BrowserRouter>
      </CssBaseline>
    </ThemeProvider>
  );
};
