import React from 'react';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import { AppToolbar } from '../../../components/app-toolbar';

import { PaymentMethodsEdit } from './payment-methods-edit';

export const PaymentMethodsEditPage = () => {
  const history = useHistory();

  const goList = () => {
    history.push('/me/payment-methods');
  };
  return (
    <React.Fragment>
      <AppToolbar title="Agregar tarjeta" />
      <Box maxWidth={480} py={4} mx="auto">
        <PaymentMethodsEdit onSuccess={goList} onCancel={goList} />
      </Box>
    </React.Fragment>
  );
};
