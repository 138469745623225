export enum CartConstants {
  CART_VISIBILITY_TOGGLE = 'CART_VISIBILITY_TOGGLE',
  CART_ADD_ITEM = 'CART_ADD_ITEM',
}

export enum AuthConstants {
  AUTH_REQUEST = 'AUTH_REQUEST',
  AUTH_CANCEL = 'AUTH_CANCEL',
  AUTH_AUTHENTICATING = 'AUTH_AUTHENTICATING',
  AUTH_SUCCESS = 'AUTH_SUCCESS',
  AUTH_FAILURE = 'AUTH_FAILURE',
  LOGOUT = 'USERS_LOGOUT',
}
