import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Link, Route, Switch } from 'react-router-dom';
import { useRouteMatch, useLocation } from 'react-router-dom';

import { MeApi, GetProfile } from '../../../api/me-api';
import { UserHeader } from './partials/user-header';
import { UserMenu } from './partials/user-menu';
import { LoadingWrapper } from '../../../components/loading-wrapper';
import { MePosts } from '../posts/me-posts';
import { AuthService } from '../../../services/auth-service';
import { UsersApi } from '../../../api/users-api';
import { PostsList } from '../../posts/posts-list';
import { UserRates } from '../user-rates';

export enum UserTabs {
  ACCOUNT,
  POSTS,
  FAVORITES,
  REVIEWS,
}

interface UserDetailProps {
  id?: number;
}
export const UserDetail: React.FC<UserDetailProps> = ({ id }) => {
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up('sm'));
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<GetProfile>({});
  const [tab, setTab] = useState(UserTabs.POSTS);

  const isMe = !id || id === AuthService.getUser()?.id;

  const match = useRouteMatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/posts')) {
      return setTab(UserTabs.POSTS);
    }
    if (location.pathname.includes('/favorites')) {
      return setTab(UserTabs.FAVORITES);
    }
    if (location.pathname.includes('/reviews')) {
      return setTab(UserTabs.REVIEWS);
    }
    setTab(isMe ? UserTabs.ACCOUNT : UserTabs.POSTS);
  }, [location.pathname, isMe]);

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      setResponse(await (id ? UsersApi.getProfile(id) : MeApi.getProfile()));
      setLoading(false);
    };
    fetchUser();
  }, [id]);

  return (
    <LoadingWrapper loading={loading} errors={response.errors}>
      <Box bgcolor="primary.light" color="primary.contrastText" py={4}>
        <Container maxWidth="md">
          {response.user !== undefined && <UserHeader user={response.user} />}
        </Container>
      </Box>
      <Box bgcolor="background.paper">
        <Container maxWidth="md">
          <Tabs
            textColor="primary"
            indicatorColor="primary"
            variant={isUpMd ? 'fullWidth' : 'scrollable'}
            centered={isUpMd}
            value={tab}
            onChange={(e, val) => setTab(val)}
          >
            {isMe && (
              <Tab
                label="Cuenta"
                component={Link}
                to={`${match.url}`}
                value={UserTabs.ACCOUNT}
              />
            )}
            <Tab
              label="Productos"
              component={Link}
              to={`${match.url}/posts`}
              value={UserTabs.POSTS}
            />
            <Tab
              label="Favoritos"
              component={Link}
              to={`${match.url}/favorites`}
              value={UserTabs.FAVORITES}
            />
            <Tab
              label="Calificaciones"
              component={Link}
              to={`${match.url}/reviews`}
              value={UserTabs.REVIEWS}
            />
          </Tabs>
        </Container>
      </Box>
      <Box
        flexGrow={1}
        position="relative"
        py={4}
        display="flex"
        flexDirection="column"
      >
        <Switch>
          <Route path={`${match.path}/posts`}>
            {isMe ? (
              <MePosts id={response.user?.id || 0} />
            ) : (
              <PostsList userId={response.user?.id || 0} />
            )}
          </Route>
          <Route path={`${match.path}/favorites`}>
            <PostsList likesTo={response.user?.id || 0} />
          </Route>
          <Route path={`${match.path}/reviews`}>
            <UserRates userId={response.user?.id || 0} />
          </Route>
          <Route path={`${match.path}`}>
            {response.user && isMe ? (
              <UserMenu id={response.user.id} />
            ) : (
              <PostsList userId={response.user?.id || 0} />
            )}
          </Route>
        </Switch>
      </Box>
    </LoadingWrapper>
  );
};
