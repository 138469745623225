import React, { FunctionComponent, useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Link from '@material-ui/core/Link';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Auth } from './auth/auth';
import { RoundButton } from '../components/round-button';
import { objToQueryStr, queryStrToObj } from '../utils/query-params';
import { PostFilters } from '../interfaces/requests/posts/posts-filters';
import { CartsIndex } from './carts/carts-index';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import { authActions } from '../actions';
import { cartActions } from '../actions/cart.actions';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 2,
  },
  logo: {
    display: 'block',
    height: '56px',
    [theme.breakpoints.up('md')]: {
      height: '72px',
    },
  },
  link: {
    '& img, & svg': {
      opacity: 0.33,
    },
    '&.active img, &.active svg': {
      opacity: 1,
    },
  },
  searchButton: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline-flex',
    },
  },
}));

const IconLink: FunctionComponent<{ to: string }> = ({ to, children }) => {
  const classes = useStyles();
  return (
    <IconButton
      component={NavLink}
      className={classes.link}
      activeClassName="active"
      to={to}
    >
      {children}
    </IconButton>
  );
};

export const Navbar: React.FC = () => {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();

  const [query, setQuery] = useState<PostFilters>({});
  const [search, setSearch] = useState(query.search || '');

  const loggedIn = useSelector<RootState, boolean>(
    (state) => state.authentication.loggedIn || false,
  );

  useEffect(() => {
    setQuery(queryStrToObj(location.search));
  }, [location.search]);

  const onSearch = (e: React.FormEvent) => {
    const params = { ...query, search };
    setQuery(params);
    history.push(`/?${objToQueryStr(params)}`);
    e.preventDefault();
  };

  if (location.pathname === '/mobileTerms') {
    return null;
  }
  return (
    <React.Fragment>
      <AppBar position="sticky" color="transparent" className={classes.appBar}>
        <Box bgcolor="background.paper">
          <Container>
            <Toolbar disableGutters>
              <Link component={NavLink} to="/">
                <img className={classes.logo} src="/logo.svg" alt="Gugu" />
              </Link>
              <form style={{ flexGrow: 1 }} onSubmit={onSearch}>
                <Box
                  display="flex"
                  alignItems="center"
                  bgcolor="background.default"
                  borderRadius="borderRadius"
                  maxWidth={360}
                  mr={1}
                >
                  <Box mx={1}>
                    <SearchIcon color="primary" />
                  </Box>
                  <InputBase
                    style={{ flexGrow: 1 }}
                    inputProps={{ 'aria-label': 'search' }}
                    name="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.searchButton}
                  >
                    Buscar
                  </Button>
                </Box>
              </form>
              <NavBarMenu isAuthenticated={loggedIn} />
            </Toolbar>
          </Container>
        </Box>
      </AppBar>
      <Auth />
      {loggedIn && <CartsIndex />}
    </React.Fragment>
  );
};

interface NavBarMenuProps {
  isAuthenticated: boolean;
}
export const NavBarMenu: FunctionComponent<NavBarMenuProps> = ({
  isAuthenticated,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const hideMenu = useMediaQuery(theme.breakpoints.up('sm'));
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLButtonElement>();
  const dispatch = useDispatch();

  const handleMenuClose = () => {
    setMenuAnchorEl(undefined);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const onToggleCart = () => dispatch(cartActions.toggleCart());

  const renderedMenu = (
    <Menu
      anchorEl={menuAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={!!menuAnchorEl}
      onClose={handleMenuClose}
    >
      <MenuItem
        component={NavLink}
        to="/orders"
        className={classes.link}
        activeClassName="active"
      >
        <ListItemIcon>
          <img src="/img/navigation/orders.svg" alt="Mis pedidos" height="24" />
          <Box ml={1}>Mis pedidos</Box>
        </ListItemIcon>
      </MenuItem>
      <MenuItem
        component={NavLink}
        to="/notifications"
        className={classes.link}
        activeClassName="active"
      >
        <ListItemIcon>
          <img
            src="/img/navigation/alert.svg"
            alt="Notificaciones"
            height="24"
          />
          <Box ml={1}>Notificaciones</Box>
        </ListItemIcon>
      </MenuItem>
      <MenuItem
        component={NavLink}
        to="/me"
        className={classes.link}
        activeClassName="active"
      >
        <ListItemIcon>
          <img src="/img/navigation/user.svg" alt="Mi perfil" height="24" />
          <Box ml={1}>Mi perfil</Box>
        </ListItemIcon>
      </MenuItem>
      <MenuItem
        component={NavLink}
        to="/posts/create"
        className={classes.link}
        activeClassName="active"
      >
        <ListItemIcon>
          <ShoppingBasketIcon color="primary" />
          <Box ml={1}>Vender</Box>
        </ListItemIcon>
      </MenuItem>
    </Menu>
  );

  const renderedButtons = isAuthenticated ? (
    <React.Fragment>
      {hideMenu ? (
        <React.Fragment>
          <IconLink to="/orders">
            <img src="/img/navigation/orders.svg" alt="Orders" height="24" />
          </IconLink>
          <IconLink to="/notifications">
            <img
              src="/img/navigation/alert.svg"
              alt="Notifications"
              height="24"
            />
          </IconLink>
          <IconLink to="/me">
            <img src="/img/navigation/user.svg" alt="My profile" height="24" />
          </IconLink>
          <RoundButton component={NavLink} to="/posts/create">
            Vender
          </RoundButton>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={handleMenuOpen}
          >
            <MoreIcon />
          </IconButton>
          {renderedMenu}
        </React.Fragment>
      )}
      <IconButton color="inherit" onClick={onToggleCart}>
        <ShoppingCartIcon />
      </IconButton>
    </React.Fragment>
  ) : (
    <RoundButton onClick={() => dispatch(authActions.requestAuth())}>
      Ingresar
    </RoundButton>
  );

  return renderedButtons;
};
