export enum PayProvider {
  PAYPAL = 'paypal',
  OPENPAY = 'openpay',
  WALLET = 'wallet',
}

export enum PayMethod {
  CARD = 'card',
  CASH = 'cash',
}
