import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { PostsDetail } from './post-detail';
import { PostsEdit } from './posts-edit';
import { PostsReport } from './posts-report';

export const PostsRoutes: React.FC = () => {
  const matchPath = useRouteMatch();

  return (
    <Switch>
      <Route path={`${matchPath.url}/create`}>
        <PostsEdit />
      </Route>
      <Route path={`${matchPath.url}/:id/edit`}>
        {(matchPath) => <PostsEdit id={+(matchPath.match?.params.id ?? 0)} />}
      </Route>
      <Route path={`${matchPath.url}/:id/report`}>
        {(matchPath) => <PostsReport id={+(matchPath.match?.params.id ?? 0)} />}
      </Route>
      <Route path={`${matchPath.url}/:id`}>
        {(matchPath) => <PostsDetail id={+(matchPath.match?.params.id ?? 0)} />}
      </Route>
    </Switch>
  );
};
