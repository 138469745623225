import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import { AppToolbar } from '../../../components/app-toolbar';
import { LoadingWrapper } from '../../../components/loading-wrapper';
import { ProfileEdit } from './profile-edit';
import { ChangePassword } from './change-password';

export const EditProfile = () => {
  const [profileView, setProfileView] = useState(true);

  return (
    <LoadingWrapper loading={false}>
      <AppToolbar title="Editar perfil" />
      <Container maxWidth="sm">
        <Paper>
          <Box px={{ xs: 2, sm: 4 }} py={4} my={4}>
            {profileView ? (
              <ProfileEdit onPasswordView={() => setProfileView(false)} />
            ) : (
              <ChangePassword onExit={() => setProfileView(true)} />
            )}
          </Box>
        </Paper>
      </Container>
    </LoadingWrapper>
  );
};
