import { Api } from './api-base';
import { Response, Messages } from '../interfaces/responses/response';
import { Option, OptionType } from '../interfaces/models/options';

export interface GetIndex {
  errors?: Messages;
  options: Option[];
}

export const OptionsApi = {
  index: async (type: OptionType): Promise<GetIndex> => {
    const { errors, response } = await Api.get<Response<Option[]>>('/options', {
      params: { type },
    });
    return { errors, options: response?.data || [] };
  },
};
