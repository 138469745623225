import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { PostSwipper } from './post-swiper';
import { PostQuestions } from './post-questions';
import { RoundButton } from '../../../components/round-button';
import { LoadingWrapper } from '../../../components/loading-wrapper';
import { UserAvatar } from '../../../components/user-avatar';
import { PostsApi, ShowPost } from '../../../api/posts-api';
import { formatCurrency } from '../../../utils/helpers';
import { AuthService } from '../../../services/auth-service';
import { PostItem } from '../../../interfaces/models/post-item';
import { RelatedItems } from './related-items';
import { DetailActions } from './detail-actions';
import { BargainPrice } from './bargain-price';
import { Question } from '../../../interfaces/models/questions';
import { AlertMessage } from '../../../components/alert-message';
import {
  PostStatusColor,
  PostStatusLabel,
} from '../../../interfaces/models/post';
import { ConfirmDialog } from '../../../components/confirm-dialog';
import { Messages } from '../../../interfaces/responses/response';
import { cartActions } from '../../../actions/cart.actions';

const BG_COLOR = 'background.paper';

export interface PostsDetailProps {
  id: number;
}
export const PostsDetail: React.FC<PostsDetailProps> = ({ id }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<ShowPost>();
  const [related, setRelated] = useState<PostItem[]>([]);
  const [showBargain, setShowBargain] = useState(false);
  const [bargain, setBargain] = useState<Question>();
  const [commentsCount, setCommentsCount] = useState(0);
  const [price, setPrice] = useState(0);
  const [askDelete, setAskDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteErrors, setDeleteErrors] = useState<Messages>();

  const getUserId = () => AuthService.getUser()?.id;

  const isOwner = () => {
    return getUserId() === response?.post?.user.id;
  };

  useEffect(() => {
    setLoading(true);
  }, [id]);

  useEffect(() => {
    const fetch = async () => {
      const [postRes, relatedRes] = await Promise.all([
        PostsApi.show(id),
        PostsApi.related(),
      ]);
      setResponse(postRes);
      setCommentsCount(postRes.post?.commentsCount || 0);
      setPrice(postRes.post?.price || 0);
      setRelated(relatedRes.posts);
      setLoading(false);
    };
    loading && fetch();
  }, [loading, id]);

  const deletePost = async () => {
    if (!response?.post) {
      return;
    }
    setDeleting(true);
    const { errors } = await PostsApi.delete(response.post.id);
    setDeleteErrors(errors);
    if (!errors) {
      setAskDelete(false);
      history.push('/me/posts');
    }
    setDeleting(false);
  };

  const onPostToCart = (itemId: number) =>
    dispatch(cartActions.addItem(itemId));

  return (
    <LoadingWrapper loading={loading} errors={response?.errors}>
      {response?.post && (
        <Box bgcolor={BG_COLOR} py={{ xs: 2, md: 4 }}>
          <Container maxWidth="md">
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box pr={{ md: 2 }} minHeight={1}>
                  <Box
                    position={{ md: 'sticky' }}
                    top={{ xs: 56, md: 72 }}
                    zIndex="appBar"
                  >
                    <PostSwipper pictures={response.post.pictures} />
                    <DetailActions
                      id={response.post.id}
                      commentsCount={commentsCount}
                      favoritesCount={response.post.favoritesCount}
                      userId={getUserId()}
                      likeToUser={response.post.likeToUser}
                    />
                    <Box display={{ xs: 'none', md: 'block' }}>
                      <RelatedItems items={related} />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box pl={{ md: 2 }}>
                  <Box
                    bgcolor={BG_COLOR}
                    py={1}
                    position="sticky"
                    top={{ xs: 56, md: 72 }}
                    zIndex="appBar"
                  >
                    <Typography variant="body2" color="textSecondary">
                      {response.post.brand.name}
                    </Typography>
                    <Typography variant="h5" component="h1">
                      {response.post.name}
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="start"
                    >
                      <Box mr={2}>
                        <Typography variant="h5" color="secondary">
                          {formatCurrency(price)}
                        </Typography>
                      </Box>
                      <Typography variant="body2" color="textSecondary">
                        Precio original
                        {formatCurrency(response.post.originalPrice, false)}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    {response.post.location}
                  </Typography>
                  <Box my={2}>
                    {!response.post.isActive ? (
                      <AlertMessage
                        messages={{
                          m: 'Este artículo se encuentra desactivado',
                        }}
                      />
                    ) : (
                      isOwner() && (
                        <AlertMessage
                          outlined
                          severity={PostStatusColor(response.post.status)}
                          messages={{
                            m: PostStatusLabel[response.post.status],
                          }}
                        />
                      )
                    )}
                    {response.post.attributes.map((attr) => (
                      <Box
                        key={attr.id}
                        display="inline-block"
                        border={1}
                        borderColor="text.secondary"
                        borderRadius={2}
                        px={1}
                        py={0.5}
                        mr={1}
                        my={0.5}
                      >
                        <Typography variant="caption" color="textSecondary">
                          {attr.label}: {attr.value}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    {response.post.description}
                  </Typography>
                  <Box py={2}>
                    <Divider></Divider>
                  </Box>
                  <Box mb={1}>
                    <Typography variant="caption">
                      <Box fontWeight={600} component="span">
                        Vendido por
                      </Box>
                    </Typography>
                  </Box>
                  <UserAvatar user={response.post.user} />
                  <Box mb={2}>
                    <Typography variant="body2" color="textSecondary">
                      {response.post.user?.soldCount || 0} productos vendidos
                    </Typography>
                  </Box>
                  <Box
                    pb={1}
                    bgcolor={BG_COLOR}
                    position="sticky"
                    top={{ xs: 156, md: 172 }}
                    zIndex="appBar"
                  >
                    <Grid container spacing={2}>
                      {isOwner() ? (
                        <React.Fragment>
                          <Grid item xs={6}>
                            <RoundButton
                              fullWidth
                              variant="outlined"
                              onClick={() => setAskDelete(true)}
                            >
                              Eliminar
                            </RoundButton>
                          </Grid>
                          <Grid item xs={6}>
                            <RoundButton
                              fullWidth
                              component={Link}
                              to={`${match.url}/edit`}
                            >
                              Editar
                            </RoundButton>
                          </Grid>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {response.post.isBargainable && (
                            <Grid item xs={12} md={6}>
                              <RoundButton
                                fullWidth
                                variant="outlined"
                                onClick={() => setShowBargain(true)}
                              >
                                Negociar precio
                              </RoundButton>
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={12}
                            md={response.post.isBargainable ? 6 : 12}
                          >
                            <RoundButton
                              fullWidth
                              onClick={() =>
                                response.post && onPostToCart(response.post.id)
                              }
                              disabled={!response.post?.quantityAvailable}
                            >
                              Agregar a Carrito
                            </RoundButton>
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                  </Box>
                  <Box display={{ md: 'none' }} my={2}>
                    <RelatedItems items={related} />
                  </Box>
                  <PostQuestions
                    postId={response.post.id}
                    user={response.post.user}
                    sticky={{ xs: 214, md: 230 }}
                    bargain={bargain}
                    onQuestion={() => setCommentsCount(commentsCount + 1)}
                    onBargain={setPrice}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
          {response.post.isBargainable && (
            <BargainPrice
              id={response.post.id}
              open={showBargain}
              onClose={() => setShowBargain(false)}
              onBargain={setBargain}
            />
          )}
          <ConfirmDialog
            open={askDelete}
            title="Eliminar publicación"
            confirmText="Eliminar"
            loading={deleting}
            onCancel={() => setAskDelete(false)}
            onConfirm={deletePost}
            errors={deleteErrors}
          >
            ¿Desea ELIMINAR la publicación?. Esta acción no puede revertirse.
          </ConfirmDialog>
        </Box>
      )}
    </LoadingWrapper>
  );
};
