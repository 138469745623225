import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { AppToolbar } from '../../../components/app-toolbar';
import { PaymentMethodsList } from './payment-methods-list';

export const PaymentMethodsListPage = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const onShowCreate = () => {
    history.push(`${match.path}/create`);
  };

  return (
    <React.Fragment>
      <AppToolbar title="Mis métodos de pago">
        <IconButton edge="end" color="inherit" onClick={onShowCreate}>
          <AddIcon />
        </IconButton>
      </AppToolbar>
      <Container maxWidth="sm">
        <Box py={4}>
          <PaymentMethodsList onShowCreate={onShowCreate} />
        </Box>
      </Container>
    </React.Fragment>
  );
};
