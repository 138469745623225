import React, { FC, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import EditIcon from '@material-ui/icons/Edit';

import { Address } from '../../../interfaces/models/address';
import { AddressesEdit } from './addresses-edit';

import { AddressesList } from './addresses-list';
import { AddressAddButton } from './address-add-button';

interface AddressPickerProps {
  value?: number | string;
  onChange: (address?: Address) => void;
}
export const AddressPicker: FC<AddressPickerProps> = ({ value, onChange }) => {
  const [edit, setEdit] = useState<{ show?: boolean; id?: number }>({});
  const [updatedItem, setUpdatedItem] = useState<Address>();

  const showCreateHandler = () => {
    setEdit({ show: true });
  };

  const onShowEdit = (id: number) => {
    setEdit({ show: true, id });
  };

  const onCloseEdit = () => {
    setEdit({ show: false });
  };

  const onSuccess = (address: Address) => {
    const isNew = !edit.id;
    if (isNew) {
      onChange(address);
    }
    setUpdatedItem(address);
    onCloseEdit();
  };

  return (
    <>
      <AddressesList
        selectable={{ value, onChange }}
        upatedItem={updatedItem}
        actions={[
          {
            icon: <EditIcon />,
            onAction: onShowEdit,
            label: 'Editar',
          },
        ]}
      />
      <AddressAddButton onClick={showCreateHandler} />
      <Dialog
        onClose={onCloseEdit}
        open={edit.show || false}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>{edit.id ? 'Editar' : 'Agregar'} dirección</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <AddressesEdit
              id={edit.id}
              onSuccess={onSuccess}
              onCancel={onCloseEdit}
              inset={true}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
