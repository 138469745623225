import React, { FunctionComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import { RoundButton } from './round-button';
import { Messages } from '../interfaces/responses/response';
import { AlertMessage } from './alert-message';

interface ConfirmDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  confirmText?: string;
  loading?: boolean;
  errors?: Messages;
}

export const ConfirmDialog: FunctionComponent<ConfirmDialogProps> = ({
  open,
  onCancel,
  onConfirm,
  title,
  confirmText,
  loading = false,
  errors,
  children,
}) => {
  const onClose = () => {
    if (!loading) {
      onCancel();
    }
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="xs">
      <DialogTitle>{title}</DialogTitle>
      {children && (
        <DialogContent>
          <DialogContentText component="div">
            {children}
            {!loading && errors && <AlertMessage messages={errors} />}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <RoundButton
          size="medium"
          onClick={onCancel}
          variant="outlined"
          disabled={loading}
        >
          Cancelar
        </RoundButton>
        <RoundButton size="medium" onClick={onConfirm} loading={loading}>
          {confirmText || 'OK'}
        </RoundButton>
      </DialogActions>
    </Dialog>
  );
};
