import { Api } from './api-base';
import { Rates } from '../interfaces/models/rates';
import { User } from '../interfaces/models/user';
import { UserItem } from '../interfaces/models/user-item';
import { HasErrors, Response } from '../interfaces/responses/response';

export interface GetProfile extends HasErrors {
  user?: User;
}

export interface GetFollowing extends HasErrors {
  follows?: boolean;
}

export interface GetRates extends HasErrors {
  rates: Rates;
}

export const UsersApi = {
  getProfile: async (id: number): Promise<GetProfile> => {
    const { errors, response } = await Api.get<Response<User>>(`/users/${id}`);
    return { errors, user: response?.data };
  },
  follow: async (id: number): Promise<GetFollowing> => {
    const { errors, response } = await Api.put<Response<boolean>>(
      `/users/${id}/toggle-follow`,
      {},
    );
    return { errors, follows: response?.data };
  },
  followers: async (id: number) => {
    const { errors, response } = await Api.get<Response<UserItem[]>>(
      `/users/${id}/followers`,
    );
    return { errors, users: response?.data || [] };
  },
  rates: async (id: number): Promise<GetRates> => {
    const { errors, response } = await Api.get<Response<Rates>>(
      `/users/${id}/rates`,
    );
    return {
      errors,
      rates: response?.data || { items: [], means: [], ratesCount: 0 },
    };
  },
};
