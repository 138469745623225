import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { UserDetail } from './user-detail';

interface UsersRoutesProps {
  userId?: number;
}
export const UsersRoutes: React.FC<UsersRoutesProps> = ({ userId }) => {
  const matchPath = useRouteMatch();

  return (
    <Switch>
      <Route path={`${matchPath.path}`}>
        <UserDetail id={userId} />
      </Route>
    </Switch>
  );
};
