import { Api } from './api-base';
import { Response, HasErrors } from '../interfaces/responses/response';
import { Comment, Question } from '../interfaces/models/questions';
import { QuestionFilters } from '../interfaces/requests/post-questions/question-filters';
import { QuestionRequest } from '../interfaces/requests/post-questions/question-request';
import { AnswerRequest } from '../interfaces/requests/post-questions/answer-request';
import { BargainCreateRequest } from '../interfaces/requests/post-questions/bargain-create-request';
import { BargainReplyRequest } from '../interfaces/requests/post-questions/bargain-reply-request';

export interface IndexQuestions extends HasErrors {
  questions: Question[];
}

export interface ShowQuestion extends HasErrors {
  question?: Question;
}

export interface ShowAnswer extends HasErrors {
  answer?: Comment;
}

export const QuestionsApi = {
  index: async (filters: QuestionFilters = {}): Promise<IndexQuestions> => {
    const { errors, response } = await Api.get<Response<Question[]>>(
      '/post-questions',
      {
        params: filters,
      },
    );
    return { errors, questions: response?.data || [] };
  },
  post: async (input: QuestionRequest): Promise<ShowQuestion> => {
    const { errors, response } = await Api.post<Response<Question>>(
      '/post-questions',
      input,
    );
    return { errors, question: response?.data };
  },
  answer: async (input: AnswerRequest): Promise<ShowAnswer> => {
    const { errors, response } = await Api.post<Response<Comment>>(
      '/post-questions/answers',
      input,
    );
    return { errors, answer: response?.data };
  },
  doBargain: async (input: BargainCreateRequest): Promise<ShowQuestion> => {
    const { errors, response } = await Api.post<Response<Question>>(
      '/post-bargains',
      input,
    );
    return { errors, question: response?.data };
  },
  replyBargain: async (
    id: number,
    input: BargainReplyRequest,
  ): Promise<ShowQuestion> => {
    const { errors, response } = await Api.post<Response<Question>>(
      `/post-bargains/${id}/review`,
      input,
    );
    return { errors, question: response?.data };
  },
};
