import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { Category } from '../../interfaces/models/category';
import { objToQueryStr, queryStrToObj } from '../../utils/query-params';
import { useHistory, useLocation } from 'react-router-dom';
import { PostFilters } from '../../interfaces/requests/posts/posts-filters';

interface HomeCategoriesProps {
  categories: Category[];
  selected?: number;
}
export const HomeCategories: React.FC<HomeCategoriesProps> = ({
  categories,
  selected,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [query, setQuery] = useState<PostFilters>({});

  useEffect(() => {
    setQuery(queryStrToObj(location.search));
  }, [location.search]);

  const onCategorySelect = (categoryId: number) => {
    const params = { ...query };
    if (params.categoryId?.toString() === categoryId.toString()) {
      delete params.categoryId;
    } else {
      params.categoryId = categoryId;
    }
    setQuery(params);
    history.push(`/?${objToQueryStr(params)}`);
  };

  return (
    <Box display="flex" p={0.5} overflow="auto">
      {categories.map((cat) => (
        <Box
          key={cat.id}
          minWidth={128}
          m={0.5}
          p={0.5}
          borderRadius="borderRadius"
          style={{
            backgroundColor: `#${cat.bgColor}`,
            color: `#${cat.fgColor}`,
            cursor: 'pointer',
            outline: selected === cat.id ? '3px solid' : undefined,
          }}
          onClick={() => onCategorySelect(cat.id)}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            p={0.5}
            borderRadius="borderRadius"
            style={{
              border: '1px dashed',
              borderColor: `#${cat.fgColor}`,
              textTransform: 'uppercase',
              backgroundImage: 'url(/img/patterns/circles.svg)',
              backgroundRepeat: 'repeat',
              backgroundSize: '10px',
              backgroundClip: 'padding-box',
            }}
            fontWeight={600}
          >
            <Box mt={0.5}>
              <img src={cat.iconUrl} height={36} alt={cat.name} />
            </Box>
            <Typography variant="inherit" noWrap>
              {cat.name}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
