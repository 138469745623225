import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { AddressListPage } from './addresses/address-list-page';
import { AddressEditPage } from './addresses/address-edit-page';
import { PaymentMethodsListPage } from './payment-methods/payment-methods-list-page';
import { PaymentMethodsEditPage } from './payment-methods/payment-methods-edit-page';
import { BankAccountEdit } from './bank-account/bank-account-edit';
import { UserDetail } from './user-detail';
import { EditProfile } from './user-edit';
import { FaqsList } from './faqs/faqs-list';
import { Support } from '../support/index';
import { PrivateRoute } from '../../components/private-route';

export const MeRoutes: React.FC = () => {
  const matchPath = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute path={`${matchPath.path}/edit`}>
        <EditProfile />
      </PrivateRoute>
      <PrivateRoute path={`${matchPath.path}/addresses/:id/edit`}>
        {({ match }) => <AddressEditPage id={+(match?.params.id ?? 0)} />}
      </PrivateRoute>
      <PrivateRoute path={`${matchPath.path}/addresses/create`}>
        <AddressEditPage />
      </PrivateRoute>
      <PrivateRoute path={`${matchPath.path}/addresses`}>
        <AddressListPage />
      </PrivateRoute>
      <PrivateRoute path={`${matchPath.path}/support`}>
        <Support />
      </PrivateRoute>
      <PrivateRoute path={`${matchPath.path}/payment-methods/create`}>
        <PaymentMethodsEditPage />
      </PrivateRoute>
      <PrivateRoute path={`${matchPath.path}/payment-methods`}>
        <PaymentMethodsListPage />
      </PrivateRoute>
      <PrivateRoute path={`${matchPath.path}/bank-accounts/:id`}>
        {({ match }) => <BankAccountEdit id={+(match?.params.id ?? 0)} />}
      </PrivateRoute>
      <PrivateRoute path={`${matchPath.path}/faqs`}>
        <FaqsList />
      </PrivateRoute>
      <PrivateRoute path={`${matchPath.path}`}>
        <UserDetail />
      </PrivateRoute>
    </Switch>
  );
};
