import React from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { formatCurrency } from '../../../utils/helpers';
import {
  OrderDetail,
  FinalSalePriceType,
} from '../../../interfaces/models/oders/order-detail';

export interface OrderDetailTotalsProps {
  isSale: Boolean;
  order: OrderDetail;
  finalSalePrice: FinalSalePriceType;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    price: {
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.info.main,
    },
    priceTitle: {
      fontSize: 16,
      color: theme.palette.info.main,
    },
    total: {
      fontSize: 18,
      fontWeight: 600,
      color: theme.palette.secondary.main,
    },
    totalTitle: {
      fontSize: 18,
      fontWeight: 600,
      color: theme.palette.secondary.dark,
    },
  }),
);

export const OrderDetailTotals: React.FC<OrderDetailTotalsProps> = ({
  order,
  isSale,
  finalSalePrice,
}) => {
  const classes = useStyles();

  const renderFees = () => {
    if (order.fees && order.fees.length > 0) {
      return order.fees.map((fee: any) => (
        <Grid container item key={fee.name} justify="space-between">
          <Typography className={classes.priceTitle}>{fee.name}</Typography>
          <Typography className={classes.price}>
            {formatCurrency(fee.amount)}
          </Typography>
        </Grid>
      ));
    }
    return null;
  };

  const renderRefundFees = () => {
    if (isSale && order.refundFees && order.refundFees.length > 0) {
      return order.refundFees.map((fee: any) => (
        <Grid container item key={fee.name} justify="space-between">
          <Typography className={classes.priceTitle}>{fee.name}</Typography>
          <Typography className={classes.price}>
            {formatCurrency(fee.amount)}
          </Typography>
        </Grid>
      ));
    }
    return null;
  };

  return (
    <Box marginBottom={2} p={4} bgcolor="background.paper" height={'auto'}>
      <Grid container direction="column" justify="flex-start">
        {order.subtotal !== null && (
          <Grid container item justify="space-between">
            <Typography className={classes.priceTitle}>{'Subtotal'}</Typography>
            <Typography className={classes.price}>
              {formatCurrency(
                order.confirmable ? finalSalePrice.price : order.subtotal,
              )}
            </Typography>
          </Grid>
        )}
      </Grid>
      {isSale && order.confirmable && (
        <Grid container item justify="space-between">
          <Typography className={classes.priceTitle}>{'Comisión'}</Typography>
          <Typography className={classes.price}>
            {`-${formatCurrency(finalSalePrice.sellerFee)}`}
          </Typography>
        </Grid>
      )}
      {isSale && !order.confirmable && renderFees()}
      {!isSale && renderFees()}
      {!isSale && order.shipping !== null && (
        <Grid container item justify="space-between">
          <Typography className={classes.priceTitle}>{'Envío'}</Typography>
          <Typography className={classes.price}>
            {formatCurrency(order.shipping)}
          </Typography>
        </Grid>
      )}
      <Grid container item justify="space-between">
        <Typography className={classes.totalTitle}>
          {isSale ? 'Precio final de venta' : 'Total'}
        </Typography>
        <Typography className={classes.total}>
          {formatCurrency(
            !isSale
              ? order.total
              : order.confirmable
              ? finalSalePrice.payable
              : order.payable,
          )}
        </Typography>
      </Grid>
      {renderRefundFees()}
      {order.refunded > 0 && (
        <Grid container item justify="space-between">
          <Typography className={classes.priceTitle}>
            {'Total a devolver'}
          </Typography>
          <Typography className={classes.price}>
            {formatCurrency(order.refunded)}
          </Typography>
        </Grid>
      )}
      {order.pendingRefund > 0 && (
        <Grid container item justify="space-between">
          <Typography className={classes.priceTitle}>
            {'Pendiente a devolver'}
          </Typography>
          <Typography className={classes.price}>
            {formatCurrency(order.pendingRefund)}
          </Typography>
        </Grid>
      )}
    </Box>
  );
};
