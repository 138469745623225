import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { Home } from './home';

export const HomeRoutes: React.FC = () => {
  const matchPath = useRouteMatch();

  return (
    <Switch>
      <Route path={`${matchPath.url}`}>
        <Home />
      </Route>
    </Switch>
  );
};
