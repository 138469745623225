import React, { FC } from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';

import { BorderedList } from '../../../components/bordered-list';

interface AddressAddButtonProps {
  onClick: () => void;
}
export const AddressAddButton: FC<AddressAddButtonProps> = ({ onClick }) => {
  return (
    <List>
      <BorderedList button onClick={onClick}>
        <ListItemText primary="Agregar dirección" />
        <ListItemSecondaryAction>
          <IconButton edge="end" color="secondary" onClick={onClick}>
            <NavigateNextIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </BorderedList>
    </List>
  );
};
