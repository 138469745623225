import { Container, Paper, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import React, { useEffect, useState } from 'react';
import { UsersApi } from '../../../api/users-api';
import { LoadingWrapper } from '../../../components/loading-wrapper';
import { Placeholder } from '../../../components/placeholder';
import { UserAvatar } from '../../../components/user-avatar';
import { Mean, Review } from '../../../interfaces/models/rates';

interface UserRatesProps {
  userId: number;
}
export const UserRates: React.FC<UserRatesProps> = ({ userId }) => {
  const [loading, setLoading] = useState(false);
  const [means, setMeans] = useState<Mean[]>([]);
  const [items, setItems] = useState<Review[]>([]);
  const [ratesCount, setRatesCount] = useState(0);

  useEffect(() => {
    setLoading(true);
  }, [userId]);

  useEffect(() => {
    const fetch = async () => {
      setMeans([]);
      setItems([]);
      setRatesCount(0);
      const { errors, rates } = await UsersApi.rates(userId);
      if (!errors) {
        setMeans(rates.means);
        setItems(rates.items);
        setRatesCount(rates.ratesCount);
      }
      setLoading(false);
    };
    loading && fetch();
  }, [loading, userId]);

  return (
    <LoadingWrapper loading={loading}>
      <Container maxWidth="sm">
        {ratesCount === 0 ? (
          <Placeholder
            picture="reviews"
            title="Sin calificaciones"
            body="Este usuario todavía no tiene calificaciones."
          />
        ) : (
          <React.Fragment>
            <Typography gutterBottom>({ratesCount}) Valoraciones</Typography>
            <Paper>
              <Box p={2}>
                {means.map((mean) => (
                  <Box
                    key={mean.code}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    py={1}
                  >
                    <Typography>{mean.name}</Typography>
                    <Rating value={mean.rate || 0} readOnly precision={0.5} />
                  </Box>
                ))}
              </Box>
            </Paper>
            {items.length > 0 && (
              <React.Fragment>
                <Box py={2} />
                <Typography gutterBottom>
                  ({items.length}) Comentarios
                </Typography>
                <Paper>
                  <Box p={2}>
                    {items.map((review) => (
                      <Box key={review.id}>
                        <UserAvatar user={review.user} />
                        <Typography
                          variant="body2"
                          gutterBottom
                          color="textSecondary"
                        >
                          {review.comments}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Paper>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Container>
    </LoadingWrapper>
  );
};
