import { Api } from './api-base';
import { Response, Messages } from '../interfaces/responses/response';
import { Faqs } from '../interfaces/models/faqs';

export interface FaqsResponse {
  errors?: Messages;
  faqs: Faqs[];
}

export const FaqsApi = {
  getFaqs: async (): Promise<FaqsResponse> => {
    const { errors, response } = await Api.get<Response<Faqs[]>>('/faqs/all');
    return { errors, faqs: response?.data || [] };
  },
};
