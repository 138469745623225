export enum OptionType {
  POST_COMPLAIN = 'post_complain',
  ORDER_RATE = 'order_rate',
  ORDER_SUPPORT = 'order_support',
  ORDER_RETURN = 'order_return',
  ORDER_REJECT = 'order_reject',
}

export interface Option {
  code: string;
  name: string;
  open: boolean;
}
