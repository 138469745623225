import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';

import { PostsApi } from '../../api/posts-api';
import { AppToolbar } from '../../components/app-toolbar';
import { RoundButton } from '../../components/round-button';
import { LoadingWrapper } from '../../components/loading-wrapper';
import { ImagePicker } from '../../components/image-picker';
import { Messages } from '../../interfaces/responses/response';
import { PostsRequest } from '../../interfaces/requests/posts/posts-request';
import { Post } from '../../interfaces/models/post';
import { Category } from '../../interfaces/models/category';
import { Attribute } from '../../interfaces/models/attribute';
import { Brand } from '../../interfaces/models/brand';
import { groupOptions } from '../../utils/helpers';
import { MediaApi } from '../../api/media-api';
import { Address } from '../../interfaces/models/address';
import { AlertMessage } from '../../components/alert-message';
import { Redirect } from 'react-router-dom';
import { AddressPicker } from '../user/addresses/address-picker';

const getValues = (item?: Post): PostsRequest => ({
  name: item?.name || '',
  description: item?.description || '',
  addressId: item?.address?.id?.toString() || '',
  categoryId: item?.category?.id?.toString() || '',
  brandName: item?.brand?.name || '',
  attributes:
    item?.attributes?.map(({ id, optionId }) => ({
      id: id.toString(),
      optionId: optionId.toString(),
    })) || [],
  originalPrice: item?.originalPrice?.toString() || '',
  price: item?.price?.toString() || '',
  pictures: {
    front: item?.pictures?.front || '',
    back: item?.pictures?.back || '',
    tag: item?.pictures?.tag || '',
    extra1: item?.pictures?.extra1 || '',
    extra2: item?.pictures?.extra2 || '',
    extra3: item?.pictures?.extra3 || '',
  },
  isBargainable: item?.isBargainable || false,
});

interface EditResources {
  attributes: Attribute[];
  categories: Category[];
  brands: Brand[];
  post?: Post;
}

interface PostsEditProps extends FormikProps<PostsRequest> {
  id?: number;
}

const PostsEditForm: React.FC<PostsEditProps> = ({
  id,
  setValues,
  setFieldValue,
  isSubmitting,
  ...formik
}) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Messages>();
  const [resources, setResources] = useState<EditResources>({
    attributes: [],
    categories: [],
    brands: [],
  });
  const [currentAttrs, setCurrentAttrs] = useState<Attribute[]>([]);

  useEffect(() => {
    const fetchResource = async () => {
      const res = await Promise.all([
        PostsApi.attributes(),
        PostsApi.categories(),
        PostsApi.brands(),
        id ? PostsApi.show(id) : Promise.resolve({ errors: undefined }),
      ]);
      const errored = res.find((r) => r.errors);
      if (errored?.errors) {
        setErrors(errored?.errors);
        return setLoading(false);
      }
      setResources({
        attributes: res[0].attributes,
        categories: res[1].categories,
        brands: res[2].brands,
        post: res[3].post,
      });
      setValues(getValues(res[3].post));
      setLoading(false);
    };
    loading && fetchResource();
  }, [id, loading, setValues]);

  useEffect(() => {
    const category = resources.categories.find(
      (c) => c.id === +formik.values.categoryId,
    );
    if (!category) {
      return setCurrentAttrs([]);
    }
    const attributes = category.attributes.reduce((carry: Attribute[], ca) => {
      const attr = resources.attributes.find((a) => a.id === ca.id);
      if (!attr) {
        return carry;
      }
      const options = attr.options.filter((o) => ca.options.includes(o.value));
      return carry.concat({ ...attr, options });
    }, []);

    const optionFor = (attr: Attribute) => {
      return `${
        resources.post?.attributes.find((pa) => pa.id === attr.id)?.optionId ||
        ''
      }`;
    };
    setFieldValue(
      'attributes',
      attributes.map((a) => ({
        id: a.id.toString(),
        optionId: optionFor(a),
      })),
    );
    setCurrentAttrs(attributes);
  }, [formik.values.categoryId, resources, setFieldValue]);

  const getErrorProps = (field: string) => {
    const meta = formik.getFieldMeta(field);
    return {
      error: meta.touched && !!meta.error,
      helperText: meta.touched && meta.error,
    };
  };

  const setBrand = (name: string) => {
    setFieldValue('brandName', name);
    const brand = resources.brands.find((brand) => brand.name === name);
    setFieldValue('brandId', brand?.id);
  };

  const title = id ? 'Editar producto' : 'Publicar producto';

  return (
    <React.Fragment>
      <AppToolbar title={title} />
      <LoadingWrapper loading={loading} errors={errors}>
        <Box maxWidth={480} py={{ sm: 4 }} mx="auto">
          <Paper>
            <Box px={{ xs: 2, sm: 4 }} py={4}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <ImagePicker
                      label="Frente *"
                      name="pictures.front"
                      value={formik.values.pictures.front}
                      onChange={formik.handleChange}
                      uploadHandler={MediaApi.uploadPicture}
                      {...getErrorProps('pictures.front')}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ImagePicker
                      label="Vuelta *"
                      name="pictures.back"
                      value={formik.values.pictures.back}
                      onChange={formik.handleChange}
                      uploadHandler={MediaApi.uploadPicture}
                      {...getErrorProps('pictures.back')}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ImagePicker
                      label="Etiqueta"
                      name="pictures.tag"
                      value={formik.values.pictures.tag}
                      onChange={formik.handleChange}
                      uploadHandler={MediaApi.uploadPicture}
                      {...getErrorProps('pictures.tag')}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <ImagePicker
                      label="Opcional"
                      name="pictures.extra1"
                      value={formik.values.pictures.extra1}
                      onChange={formik.handleChange}
                      uploadHandler={MediaApi.uploadPicture}
                      {...getErrorProps('pictures.extra1')}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ImagePicker
                      label="Opcional"
                      name="pictures.extra2"
                      value={formik.values.pictures.extra2}
                      onChange={formik.handleChange}
                      uploadHandler={MediaApi.uploadPicture}
                      {...getErrorProps('pictures.extra2')}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ImagePicker
                      label="Opcional"
                      name="pictures.extra3"
                      value={formik.values.pictures.extra3}
                      onChange={formik.handleChange}
                      uploadHandler={MediaApi.uploadPicture}
                      {...getErrorProps('pictures.extra3')}
                    />
                  </Grid>
                </Grid>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Nombre"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  {...getErrorProps('name')}
                  required
                />
                <TextField
                  variant="outlined"
                  type="text"
                  multiline
                  minRows={2}
                  maxRows={4}
                  label="Descripción"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  {...getErrorProps('description')}
                  required
                />
                <TextField
                  variant="outlined"
                  select
                  label="Categoría"
                  name="categoryId"
                  value={formik.values.categoryId}
                  onChange={formik.handleChange}
                  {...getErrorProps('categoryId')}
                  required
                >
                  {resources.categories.map((c) => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.name}
                    </MenuItem>
                  ))}
                </TextField>
                <Autocomplete
                  freeSolo
                  options={resources.brands.map((b) => b.name)}
                  value={formik.values.brandName}
                  onChange={(e, value) => setBrand(value || '')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Marca"
                      variant="outlined"
                      onBlur={(e) => setFieldValue('brandName', e.target.value)}
                      {...getErrorProps('brandName')}
                      required
                    />
                  )}
                />
                {currentAttrs.map((attr, i) => (
                  <React.Fragment key={attr.id}>
                    <input
                      type="hidden"
                      name={`attributes.${i}.id`}
                      value={formik.values.attributes[i]?.id}
                      readOnly
                    />
                    <TextField
                      variant="outlined"
                      select
                      label={attr.name}
                      name={`attributes.${i}.optionId`}
                      value={formik.values.attributes[i]?.optionId}
                      onChange={formik.handleChange}
                      required
                      {...getErrorProps(`attributes.${i}.optionId`)}
                    >
                      {groupOptions(attr.options).map(([g, options]) => [
                        g && <ListSubheader>{g}</ListSubheader>,
                        options.map((o) => (
                          <MenuItem key={o.value} value={o.value}>
                            {o.label}
                          </MenuItem>
                        )),
                      ])}
                    </TextField>
                  </React.Fragment>
                ))}
                <TextField
                  variant="outlined"
                  type="number"
                  label="Precio original"
                  name="originalPrice"
                  value={formik.values.originalPrice}
                  onChange={formik.handleChange}
                  {...getErrorProps('originalPrice')}
                  required
                />
                <TextField
                  variant="outlined"
                  type="number"
                  label="Precio"
                  name="price"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  {...getErrorProps('price')}
                  required
                />
                <FormControlLabel
                  control={
                    <Switch
                      name="isBargainable"
                      checked={formik.values.isBargainable}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Precio negociable"
                />
                <div style={{ marginTop: 12 }}>
                  <FormLabel>Dirección de envio</FormLabel>
                  {formik.touched.addressId && formik.errors.addressId && (
                    <FormHelperText error={true}>
                      {formik.errors.addressId}
                    </FormHelperText>
                  )}
                  <AddressPicker
                    value={formik.values.addressId}
                    onChange={(address?: Address) =>
                      setFieldValue('addressId', address?.id.toString() || '')
                    }
                  />
                </div>
                {formik.status?.error && (
                  <AlertMessage messages={{ '': formik.status.error }} />
                )}
                {formik.status?.success && (
                  <Redirect to={`/posts/${formik.status.resId || ''}`} />
                )}
                <Box mt={2}>
                  <RoundButton type="submit" loading={isSubmitting} fullWidth>
                    Guardar
                  </RoundButton>
                </Box>
              </form>
            </Box>
          </Paper>
        </Box>
      </LoadingWrapper>
    </React.Fragment>
  );
};

const validationSchema = yup.object({
  name: yup.string().required('Nombre es requerido.'),
  description: yup.string().required('Descripción es requerida.'),
  addressId: yup.number().required('Dirección de envío es requerida.'),
  categoryId: yup.number().required('Categoría es requerida.'),
  brandName: yup.string().required('Marca es requerida.'),
  originalPrice: yup.number().required('Precio original es requerido.'),
  price: yup.number().required('Precio es requerido.'),
  isBargainable: yup.boolean(),
  attributes: yup.array().of(
    yup.object({
      id: yup.number().required(),
      optionId: yup.number().required('Este campo es requerido.'),
    }),
  ),
  pictures: yup.object({
    front: yup.string().url().required('Foto de frente es requerida.'),
    back: yup.string().url().required('Foto de vuelta es requerida.'),
    tag: yup.string().url().optional(),
    extra1: yup.string().url().optional(),
    extra2: yup.string().url().optional(),
    extra3: yup.string().url().optional(),
  }),
});

export const PostsEdit = withFormik<{ id?: number }, PostsRequest>({
  mapPropsToValues: () => getValues(),
  validationSchema,
  handleSubmit: async (
    values,
    { props, setSubmitting, setErrors, setStatus },
  ) => {
    setSubmitting(true);
    let errors, post;
    if (props.id) {
      ({ errors = {}, post } = await PostsApi.update(props.id, values));
    } else {
      ({ errors = {}, post } = await PostsApi.store(values));
    }
    const error = Object.values(errors).shift();
    setStatus({ error, success: !error, resId: post?.id });
    if (error) {
      setErrors(errors);
    }
  },
})(PostsEditForm);
