import React from 'react';
import { Box, makeStyles, Typography, Grid } from '@material-ui/core';
import { RoundButton } from '../../../components/round-button';
import {
  OrderDetail,
  ReturnState,
} from '../../../interfaces/models/oders/order-detail';
import { OrdersApi } from '../../../api/orders-api';

export interface OrderDetailFooterProps {
  isSale: Boolean;
  order: OrderDetail;
  getData(): void;
  returns: ReturnState;
  openModal(key?: string): void;
  setLoading(value?: boolean): void;
  setSnackState(state?: object): void;
}

const useStyles = makeStyles(() => ({
  button: {
    color: 'white',
  },
}));

export const OrderDetailFooter: React.FC<OrderDetailFooterProps> = ({
  order,
  isSale,
  returns,
  getData,
  openModal,
  setLoading,
  setSnackState,
}) => {
  const classes = useStyles();
  const areReturns = Boolean(returns.orderDetails.length > 0);
  const submitReturns = async () => {
    setLoading(true);
    const { errors } = await OrdersApi.setRefund(
      order.id,
      returns.orderDetails,
    );
    if (errors) {
      await setSnackState({
        open: true,
        message:
          'Surgió un problema, favor de intentar realizar la devolución de estos productos más tarde',
        severity: 'error',
      });
      setLoading(false);
    } else {
      await setSnackState({
        open: true,
        message:
          'Hemos enviado tu devolución, nos comunicaremos contigo a la brevedad',
        severity: 'success',
      });
      getData();
      setLoading(false);
    }
  };
  const renderPurchaseActions = () => {
    if (order.shipment.status === 'DL') {
      if (order.details.length > 0 && areReturns) {
        return (
          <Grid item>
            <RoundButton onClick={submitReturns} variant={'outlined'} fullWidth>
              <Typography>{'Confirmar devolución'}</Typography>
            </RoundButton>
          </Grid>
        );
      }
    }
    return (
      <Grid item>
        <RoundButton onClick={() => openModal('help')} fullWidth>
          <Typography className={classes.button}>
            {'Solicitar ayuda'}
          </Typography>
        </RoundButton>
      </Grid>
    );
  };
  const renderSaleActions = () => {
    if (order.confirmable) {
      return (
        <Grid item>
          <RoundButton onClick={() => openModal('confirm')} fullWidth>
            <Typography className={classes.button}>
              {'Confirmar orden'}
            </Typography>
          </RoundButton>
        </Grid>
      );
    }
    return null;
  };
  return (
    <Box marginBottom={2} p={4} bgcolor="background.paper">
      <Grid container justify="flex-end">
        {isSale ? renderSaleActions() : renderPurchaseActions()}
      </Grid>
    </Box>
  );
};
