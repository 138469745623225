import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { Messages } from '../interfaces/responses/response';
import { AuthService } from '../services/auth-service';
import config from '../config';

const BaseApi = () => {
  const headers: Messages = {
    'Accept-Language': 'es',
  };
  const token = AuthService.getToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return axios.create({
    baseURL: config.baseUrl,
    headers: headers,
  });
};

const handleRequest = async <T>(
  request: Promise<AxiosResponse<T>>,
  accept401 = false,
): Promise<{ response?: T; errors?: Messages }> => {
  try {
    const { data: response } = await request;
    return { response };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (!accept401 && e?.response?.status === 401) {
      AuthService.logout();
    }
    const messages: Messages = e.response?.data?.messages;
    const message: string = e.message;
    const errors = messages || { error: message };
    return { errors };
  }
};

export const Api = {
  get: async <T>(
    url: string,
    config?: AxiosRequestConfig,
    accept401 = false,
  ) => {
    return handleRequest(BaseApi().get<T>(url, config), accept401);
  },
  post: async <T, D = {}>(
    url: string,
    data: D,
    config?: AxiosRequestConfig,
    accept401 = false,
  ) => {
    return handleRequest(BaseApi().post<T>(url, data, config), accept401);
  },
  put: async <T, D = {}>(
    url: string,
    data: D,
    config?: AxiosRequestConfig,
    accept401 = false,
  ) => {
    return handleRequest(BaseApi().put<T>(url, data, config), accept401);
  },
  delete: async <T>(
    url: string,
    config?: AxiosRequestConfig,
    accept401 = false,
  ) => {
    return handleRequest(BaseApi().delete<T>(url, config), accept401);
  },
};
