import React from 'react';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    '& + &': {
      marginTop: theme.spacing(1),
    },
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    border: '1px solid',
    borderColor: theme.palette.divider,
  },
}));

export const BorderedList = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: ListItemProps<any, { button?: any }>,
) => {
  const classes = useStyles();
  return <ListItem classes={classes} {...props} />;
};
