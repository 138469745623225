import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List/List';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';

import { PostsList } from '../../posts/posts-list';
import { BorderedList } from '../../../components/bordered-list';

interface MePostsProps {
  id: number;
}
export const MePosts: React.FC<MePostsProps> = ({ id }) => {
  const [openStore, setOpenStore] = useState(true);
  return (
    <React.Fragment>
      <Container maxWidth="sm">
        <Box maxWidth={480} margin="auto" mb={4}>
          <List>
            <BorderedList>
              <ListItemText primary="Tienda abierta" />
              <ListItemSecondaryAction>
                <Switch
                  checked={openStore}
                  onChange={(e) => setOpenStore(e.target.checked)}
                />
              </ListItemSecondaryAction>
            </BorderedList>
          </List>
        </Box>
      </Container>
      <PostsList userId={id} />
    </React.Fragment>
  );
};
