/* eslint-disable @typescript-eslint/no-explicit-any */

export declare type QueryParams = { [key: string]: any };

export const queryStrToObj = (query: string) => {
  query = query.substring(query.indexOf('?') + 1);

  const re = /([^&=]+)=?([^&]*)/g;
  const decodeRE = /\+/g;

  const decode = (str: string) => {
    return decodeURIComponent(str.replace(decodeRE, ' '));
  };

  const params: QueryParams = {};
  let e: string[] | null;

  while ((e = re.exec(query))) {
    let k = decode(e[1]);
    const v = decode(e[2]);
    if (k.substring(k.length - 2) === '[]') {
      k = k.substring(0, k.length - 2);
      (params[k] || (params[k] = [])).push(v);
    } else params[k] = v;
  }

  const assign = (obj: QueryParams, keyPath: string[], value: any) => {
    const lastKeyIndex = keyPath.length - 1;
    for (let i = 0; i < lastKeyIndex; ++i) {
      const key = keyPath[i];
      if (!(key in obj)) obj[key] = {};
      obj = obj[key];
    }
    obj[keyPath[lastKeyIndex]] = value;
  };

  for (const prop in params) {
    const structure = prop.split('[');
    if (structure.length > 1) {
      const levels: string[] = [];
      structure.forEach((item) => {
        const key = item.replace(/[?[\]\\ ]/g, '');
        levels.push(key);
      });
      assign(params, levels, params[prop]);
      delete params[prop];
    }
  }
  return params;
};

export const objToQueryStr = (initialObj: QueryParams) => {
  const reducer =
    (obj: QueryParams, parentPrefix = '') =>
    (prev: string[], key: string) => {
      const val = obj[key];
      key = encodeURIComponent(key);
      const prefix = parentPrefix ? `${parentPrefix}[${key}]` : key;

      if (val === null || val === undefined || typeof val === 'function') {
        prev.push(`${prefix}=`);
        return prev;
      }

      if (['number', 'boolean', 'string'].includes(typeof val)) {
        prev.push(`${prefix}=${encodeURIComponent(val)}`);
        return prev;
      }

      prev.push(Object.keys(val).reduce(reducer(val, prefix), []).join('&'));
      return prev;
    };

  return Object.keys(initialObj).reduce(reducer(initialObj), []).join('&');
};
