import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { AppToolbar } from '../../../components/app-toolbar';
import { AddressesList } from './addresses-list';
import { Box, Container } from '@material-ui/core';
import { AddressAddButton } from './address-add-button';

export const AddressListPage = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const onShowCreate = () => {
    history.push(`${match.path}/create`);
  };

  const onShowEdit = (id: number) => {
    history.push(`${match.path}/${id}/edit`);
  };

  return (
    <React.Fragment>
      <AppToolbar title="Mis direcciones">
        <IconButton edge="end" color="inherit" onClick={() => onShowCreate()}>
          <AddIcon />
        </IconButton>
      </AppToolbar>
      <Container maxWidth="sm">
        <Box py={4}>
          <AddressesList
            actions={[
              {
                icon: <EditIcon />,
                onAction: onShowEdit,
                label: 'Editar',
              },
            ]}
          />
          <AddressAddButton onClick={onShowCreate} />
        </Box>
      </Container>
    </React.Fragment>
  );
};
