import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { MeApi } from '../../../api/me-api';
import { AlertMessage } from '../../../components/alert-message';
import { RoundButton } from '../../../components/round-button';
import { TogglePasswordInput } from '../../../components/toggle-password-input';
import { ChangePasswordRequest } from '../../../interfaces/requests/me/change-password';
import { Messages } from '../../../interfaces/responses/response';

interface ChangePasswordProps {
  onExit: () => void;
}
export const ChangePassword: React.FC<ChangePasswordProps> = ({ onExit }) => {
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState<Messages>();
  const [success, setSuccess] = useState(false);

  const formik = useFormik<ChangePasswordRequest>({
    initialValues: {
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: yup.object({
      currentPassword: yup.string().required('Contraseña actual es requerida.'),
      password: yup.string().required('Contraseña es requerida.'),
      passwordConfirmation: yup
        .string()
        .oneOf([yup.ref('password')], 'Contraseñas no coinciden.')
        .required('Cnformación de contraseña es requerida.'),
    }),
    onSubmit: async (values) => {
      setSaving(true);
      setErrors(undefined);
      setSuccess(false);
      const { errors } = await MeApi.changePassword(values);
      if (!errors) {
        setSuccess(true);
      }
      setErrors(errors);
      setSaving(false);
    },
  });

  const getErrorProps = (field: string) => {
    const meta = formik.getFieldMeta(field);
    return {
      error: meta.touched && !!meta.error,
      helperText: meta.touched && meta.error,
    };
  };

  return (
    <Box mt={2}>
      <form onSubmit={formik.handleSubmit}>
        <TogglePasswordInput
          label="Contraseña actual"
          name="currentPassword"
          value={formik.values.currentPassword}
          onChange={formik.handleChange}
          {...getErrorProps('currentPassword')}
        />
        <TogglePasswordInput
          label="Contraseña"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          {...getErrorProps('password')}
        />
        <TogglePasswordInput
          label="Confirmar contraseña"
          name="passwordConfirmation"
          value={formik.values.passwordConfirmation}
          onChange={formik.handleChange}
          {...getErrorProps('passwordConfirmation')}
        />
        <Box mt={3} mb={1}>
          {errors && <AlertMessage messages={errors} />}
          {success && (
            <AlertMessage
              severity="success"
              messages={{ m: 'Contraseña actualizada exitosanente.' }}
              action={
                <Button color="inherit" size="small" onClick={onExit}>
                  Perfil
                </Button>
              }
            />
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <RoundButton
                fullWidth
                variant="outlined"
                disabled={saving}
                onClick={() => onExit()}
              >
                Cancelar
              </RoundButton>
            </Grid>
            <Grid item xs={6}>
              <RoundButton fullWidth type="submit" loading={saving}>
                Guardar
              </RoundButton>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};
