import { Api } from './api-base';
import { Response } from '../interfaces/responses/response';

export const HelpApi = {
  postReport: async (select: object) => {
    const { errors, response } = await Api.post<Response<void>>(
      '/order-cases',
      select,
    );
    return { errors, response };
  },
};
