import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthRoutes } from './auth/auth-routes';
import { CartRoutes } from './carts/carts-routes';
import { HomeRoutes } from './home/home-routes';
import { OrdersRoutes } from './orders/orders-routes';

import { PostsRoutes } from './posts/posts-routes';
import { UsersRoutes } from './user/users-routes';
import { OrderDetails } from './orders/order-details';
import { Terms } from './terms';
import { MeRoutes } from './user/me-routes';

export const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path="/users/:id">
        {(matchRoute) => (
          <UsersRoutes userId={Number(matchRoute.match?.params.id)} />
        )}
      </Route>
      <Route path="/me">
        <MeRoutes />
      </Route>
      <Route path="/posts">
        <PostsRoutes />
      </Route>
      <Route path="/orders">
        <OrdersRoutes />
      </Route>
      <Route path={['/sales/:id', '/purchases/:id']}>
        {(matchPath) => {
          const path: Array<string> = matchPath.match?.path.split('/') || [];
          return (
            <OrderDetails
              isSale={Boolean(path[1] === 'sales')}
              orderId={+(matchPath.match?.params.id ?? 0)}
            />
          );
        }}
      </Route>
      <Route path="/carts">
        <CartRoutes />
      </Route>
      <Route path="/auth">
        <AuthRoutes />
      </Route>
      <Route path={['/terms', '/mobileTerms']}>
        <Terms />
      </Route>
      <Route path="/" exact={true}>
        <HomeRoutes />
      </Route>
    </Switch>
  );
};
