export const __html = `<!DOCTYPE html>

<html>
  <head>
    <title>Términos y condiciones</title>
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/bootstrap/3.2.0/css/bootstrap.min.css"
    />
  </head>
  <body>

    <div id="container" class="d-flex w-100 flex-column" style="margin-top: 5em;">
      <label id="title">Términos y condiciones</label>
      <Label id="subtitle">Subtitulo</Label>
      <p class="p">
        Irure culpa officia laboris sit irure dolore non. Dolore excepteur veniam exercitation cillum. Eu anim reprehenderit consequat minim eiusmod incididunt nulla occaecat veniam. In labore minim eu velit ea duis aliqua eu aliquip irure id nisi pariatur. Aute minim sit non adipisicing proident adipisicing do culpa Lorem aliqua occaecat deserunt eiusmod. Mollit aliqua anim sint minim. Cillum veniam laboris pariatur dolor.

Anim laboris irure non dolore. Incididunt do proident amet ut consectetur irure officia eiusmod. Anim eu culpa elit fugiat id velit. Deserunt aliqua ea occaecat commodo reprehenderit ad in excepteur. Ad nisi aute in reprehenderit mollit exercitation amet elit in et. Lorem est voluptate dolore laborum aliquip qui.

Deserunt veniam labore excepteur et irure occaecat. Aliquip labore ea proident adipisicing velit ad adipisicing dolore. Culpa tempor dolore Lorem labore aliquip cillum voluptate in tempor. Quis ut id elit consectetur eu et eu ex pariatur.
      </p>
      <Label id="subtitle">Subtitulo</Label>
      <p class="p">
        Irure culpa officia laboris sit irure dolore non. Dolore excepteur veniam exercitation cillum. Eu anim reprehenderit consequat minim eiusmod incididunt nulla occaecat veniam. In labore minim eu velit ea duis aliqua eu aliquip irure id nisi pariatur. Aute minim sit non adipisicing proident adipisicing do culpa Lorem aliqua occaecat deserunt eiusmod. Mollit aliqua anim sint minim. Cillum veniam laboris pariatur dolor.

Anim laboris irure non dolore. Incididunt do proident amet ut consectetur irure officia eiusmod. Anim eu culpa elit fugiat id velit. Deserunt aliqua ea occaecat commodo reprehenderit ad in excepteur. Ad nisi aute in reprehenderit mollit exercitation amet elit in et. Lorem est voluptate dolore laborum aliquip qui.

Deserunt veniam labore excepteur et irure occaecat. Aliquip labore ea proident adipisicing velit ad adipisicing dolore. Culpa tempor dolore Lorem labore aliquip cillum voluptate in tempor. Quis ut id elit consectetur eu et eu ex pariatur.
      </p>
    </div>
  </body>

  <style>
    #container {
      display: flex;
      flex-direction: column;
    }
    #subtitle {
      font-size: 2.5rem !important;
      font-weight: 400;
    }
    #title, #subtitle {
      font-size: 3.5rem;
      font-family: Nunito, sans-serif;
      font-weight: 500;
      line-height: 1;
      margin-top: 1rem;
    }
    .p {
      text-align: justify;
    }
  </style>
</html>`;
