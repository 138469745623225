import React from 'react';
import {
  Card,
  makeStyles,
  createStyles,
  Theme,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Divider,
  Chip,
  CardActions,
} from '@material-ui/core';
import {
  refundStatusLabel,
  shipmentStatusLabel,
  orderStatusLabel,
  formatCurrency,
} from '../../../utils/helpers';
import { RoundButton } from '../../../components/round-button';
import {
  Rate,
  ReportValue,
  OrderDetailItemType,
} from '../../../interfaces/models/oders/order-detail';
import { Info } from '@material-ui/icons';
import Rating from '@material-ui/lab/Rating';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      borderColor: '#DCE9F5',
      borderWidth: 1,
      borderRadius: 10,
      marginBottom: 10,
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    content: {
      width: '100%',
      flex: '1 0 auto',
    },
    cover: {
      width: '16vw',
    },
    listName: {
      color: theme.palette.secondary.main,
      fontWeight: 700,
      marginBottom: 0,
      textTransform: 'capitalize',
    },
    listDetail: {
      fontSize: 14,
      color: theme.palette.info.light,
      textTransform: 'capitalize',
    },
    listStatus: {
      fontSize: 14,
      color: theme.palette.primary.main,
      fontWeight: 700,
      textTransform: 'capitalize',
    },
    price: {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.info.main,
      textTransform: 'uppercase',
    },
    priceTotal: {
      fontSize: 16,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      textTransform: 'uppercase',
    },
    priceTitle: {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.info.light,
    },
    rateComments: {
      fontSize: 14,
      fontWeight: 400,
      textAlign: 'center',
      fontStyle: 'italic',
      color: theme.palette.info.light,
    },
    priceTitleUnselect: {
      textDecorationLine: 'line-through',
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.info.light,
    },
    priceUnselect: {
      textDecorationLine: 'line-through',
      fontSize: 14,
      fontWeight: 400,
      color: '#010102',
      textTransform: 'uppercase',
    },
    priceUnselectTotal: {
      textDecorationLine: 'line-through',
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.secondary.main,
      textTransform: 'uppercase',
    },
    priceTitleTotal: {
      fontSize: 16,
      fontWeight: 700,
      color: theme.palette.info.main,
    },
    priceTitleUnselectTotal: {
      textDecorationLine: 'line-through',
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.info.main,
    },
    refundButton: {
      backgroundColor: 'black',
    },
  }),
);

export const OrderDetailItem: React.FC<OrderDetailItemType> = ({
  isSale,
  rejects,
  id,
  pictureUrl,
  name,
  attributes,
  brand,
  price,
  status,
  shipment,
  sellerFee,
  returnStatus,
  buyerFee,
  payable,
  returnable,
  confirmable,
  rateable,
  rate,
  orderDetails,
  openModalWithItem,
}) => {
  const classes = useStyles();

  const renderAtt = (attributes: any) => {
    const footer = [];
    const size: any =
      attributes && attributes.length > 0
        ? attributes.find(
            (att: any) =>
              att.id === 2 || att.id === 3 || att.id === 4 || att.id === 7,
          )
        : null;
    if (size !== undefined && size !== null) {
      footer.push(size.value);
    }
    const brand =
      attributes.brand && attributes.brand.name ? attributes.brand.name : null;
    if (brand) {
      footer.push(brand);
    }
    const condition =
      attributes && attributes.length > 0
        ? attributes.find((att: any) => att.id === 5)
        : null;
    if (condition !== undefined && condition !== null) {
      footer.push(condition.value);
    }
    if (footer.length > 0) {
      return footer.join(' | ');
    }
    return null;
  };

  const renderStatus = (): string => {
    if (returnStatus !== undefined && returnStatus !== '') {
      return refundStatusLabel(returnStatus);
    } else if (status === 'accepted' && shipment && shipment.status) {
      return shipmentStatusLabel(shipment);
    }
    return orderStatusLabel(status);
  };

  const inRejects = Boolean(
    (isSale && rejects.some((reject: ReportValue) => reject.id === id)) ||
      status === 'rejected',
  );

  const isInReturns = Boolean(
    orderDetails.some((detail) => detail.orderDetailId === id),
  );

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.cover}
        image={pictureUrl}
        title={name}
        src={'img'}
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Grid container direction="column">
            <Grid item>
              <Typography
                gutterBottom
                variant="h5"
                className={classes.listName}
              >
                {name}
              </Typography>
            </Grid>
            <Grid item>
              {brand && brand.name && (
                <Typography className={classes.listDetail}>
                  {`Marca: ${brand.name}`}
                </Typography>
              )}
            </Grid>
            <Grid
              container
              wrap="wrap"
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography className={classes.listDetail}>
                {renderAtt(attributes)}
              </Typography>
              <Typography className={classes.listStatus}>
                {renderStatus()}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent className={classes.content}>
          <Grid container direction="column">
            <Grid container item justify="space-between">
              <Typography
                className={
                  status === 'rejected' || inRejects
                    ? classes.priceTitleUnselect
                    : classes.priceTitle
                }
              >
                {'Costo del producto'}
              </Typography>
              <Typography
                className={
                  status === 'rejected' || inRejects
                    ? classes.priceUnselect
                    : classes.price
                }
              >
                {formatCurrency(price)}
              </Typography>
            </Grid>
            <Grid container item justify="space-between">
              <Typography
                className={
                  status === 'rejected' || inRejects
                    ? classes.priceTitleUnselect
                    : classes.priceTitle
                }
              >
                {isSale ? 'Comisión Gugu' : 'Impuestos'}
              </Typography>
              <Typography
                className={
                  status === 'rejected' || inRejects
                    ? classes.priceUnselect
                    : classes.price
                }
              >
                {isSale
                  ? `-${formatCurrency(sellerFee)}`
                  : formatCurrency(buyerFee)}
              </Typography>
            </Grid>
            <Grid container item justify="space-between">
              <Typography
                className={
                  status === 'rejected' || inRejects
                    ? classes.priceTitleUnselectTotal
                    : classes.priceTitleTotal
                }
              >
                {'Total'}
              </Typography>
              <Typography
                className={
                  status === 'rejected' || inRejects
                    ? classes.priceUnselectTotal
                    : classes.priceTotal
                }
              >
                {formatCurrency(isSale ? payable : price + buyerFee)}
              </Typography>
            </Grid>
            <Grid container item alignItems="center" spacing={1}>
              {isInReturns && (
                <Chip
                  color="primary"
                  icon={<Info />}
                  label="Producto a devolver"
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          {isSale && !inRejects && confirmable && (
            <Grid item>
              <RoundButton
                size={'small'}
                onClick={() => openModalWithItem('problem', id)}
              >
                <Typography>{'Tengo un problema con este producto'}</Typography>
              </RoundButton>
            </Grid>
          )}
          {!isSale &&
            status === 'accepted' &&
            (shipment.status === 'DL' || shipment.status === 'partial') &&
            returnable &&
            !isInReturns && (
              <Grid item>
                <RoundButton
                  size={'small'}
                  className={classes.refundButton}
                  onClick={() => openModalWithItem('refund', id)}
                >
                  <Typography>{'Devolver producto'}</Typography>
                </RoundButton>
              </Grid>
            )}
          {!isSale &&
            status === 'accepted' &&
            (shipment.status === 'DL' || shipment.status === 'partial') &&
            rateable && (
              <Grid item>
                <RoundButton
                  size={'small'}
                  onClick={() => openModalWithItem('rate', id)}
                >
                  <Typography>{'Calificar'}</Typography>
                </RoundButton>
              </Grid>
            )}
        </CardActions>
        {rate && rate.rates && rate.rates.length > 0 ? <Divider /> : null}
        {rate && rate.rates && rate.rates.length > 0 ? (
          <CardContent>
            <Grid container item direction="column">
              {rate.rates.map((rate: Rate) => (
                <Grid key={rate.name} container item justify="space-between">
                  <Typography className={classes.priceTitle}>
                    {rate.name}
                  </Typography>
                  <Rating
                    readOnly
                    size="small"
                    name={`rate-${rate.name}`}
                    value={rate.rate}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid container item direction="column">
              <Typography className={classes.rateComments}>
                {`"${rate.comments}"`}
              </Typography>
            </Grid>
          </CardContent>
        ) : null}
      </div>
    </Card>
  );
};
