import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { TabPanel } from '../../components/tab-panel';
import { PurchasesIndex } from './purchases-index';
import { SalesIndex } from './sales-index';

export const OrdersIndex = () => {
  const [tab, setTab] = useState(0);
  return (
    <React.Fragment>
      <Box bgcolor="background.paper">
        <Container maxWidth="sm">
          <Tabs
            textColor="primary"
            indicatorColor="primary"
            variant="fullWidth"
            centered={true}
            value={tab}
            onChange={(e, val) => setTab(val)}
          >
            <Tab label="Mis compras" value={0} />
            <Tab label="Mis ventas" value={1} />
          </Tabs>
        </Container>
      </Box>
      <Container maxWidth="md">
        <Box my={4}>
          <TabPanel index={0} value={tab}>
            <PurchasesIndex />
          </TabPanel>
          <TabPanel index={1} value={tab}>
            <SalesIndex />
          </TabPanel>
        </Box>
      </Container>
    </React.Fragment>
  );
};
