import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';

import { OptionsApi } from '../../../../api/options-api';
import { OrdersApi } from '../../../../api/orders-api';
import { LoadingWrapper } from '../../../../components/loading-wrapper';
import { RoundButton } from '../../../../components/round-button';
import { Messages } from '../../../../interfaces/responses/response';
import { OptionType } from '../../../../interfaces/models/options';
import { RatePostType } from '../../../../interfaces/models/oders/order-detail';

interface Option {
  code: string;
  name: string;
  open: boolean;
  rate: number | null;
}

interface GetIndex {
  errors?: Messages;
  options: Option[];
}

const useStyles = makeStyles(() => ({
  button: {
    color: 'white',
  },
}));

interface OrderDetailRateProps {
  orderId: number;
  orderDetailId: number;
  getData(): void;
  onCloseModal(key?: string): void;
  setSnackState(state?: object): void;
}

interface SelectState {
  orderId: number;
  orderDetailId: number;
  comments: string;
}

export const OrderDetailRate: React.FC<OrderDetailRateProps> = ({
  orderId,
  orderDetailId,
  getData,
  onCloseModal,
  setSnackState,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<GetIndex>();
  const [select, setSelect] = React.useState<SelectState>({
    comments: '',
    orderId: orderId || 0,
    orderDetailId: orderDetailId || 0,
  });

  useEffect(() => {
    const initData = async () => {
      const result = await OptionsApi.index(OptionType.ORDER_RATE);
      const options: Array<Option> = result.options.map((option) => ({
        ...option,
        rate: 0,
      }));
      setResponse({ ...result, options });
      setLoading(false);
    };
    initData();
  }, []);

  const handleAltTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelect({ ...select, comments: event.target.value });
  };

  const handleRateChange = (value: number | null, option: Option) => {
    let options: Array<Option> = [];
    (response?.options || []).forEach((item: Option) => {
      const currentOption: Option = { ...item };
      if (currentOption.code === option.code) {
        currentOption.rate = value;
      }
      options = [...options, currentOption];
    });
    setResponse({ ...response, options });
  };

  const onSubmit = async () => {
    setLoading(true);
    const info: RatePostType = {
      comments: select.comments,
      rates:
        response?.options && response?.options.length > 0
          ? response?.options.map((opt: Option) => ({
              code: opt.code,
              rate: opt.rate,
            }))
          : [],
    };
    const { errors } = await OrdersApi.setRate(
      info,
      select.orderId,
      select.orderDetailId,
    );
    if (errors) {
      await setSnackState({
        open: true,
        message:
          'Surgió un problema, favor de intentar mandar la reseña más tarde',
        severity: 'error',
      });
      setLoading(false);
    } else {
      await setSnackState({
        open: true,
        message: 'Hemos enviado tu reseña, muchas gracias',
        severity: 'success',
      });
      setTimeout(() => {
        onCloseModal('rate');
        getData();
      }, 1000);
    }
  };

  const disabled: boolean | undefined =
    response?.options &&
    response?.options.some((opt: Option) => opt.rate === 0);

  return (
    <LoadingWrapper loading={loading} errors={response?.errors}>
      {!loading && response && response.options && response.options.length > 0 && (
        <div>
          <List>
            {response.options.map((option: Option) => {
              return (
                <ListItem key={option.code}>
                  <ListItemText primary={option.name} />
                  <Rating
                    value={option.rate}
                    onChange={(event, newValue: number | null) => {
                      handleRateChange(newValue, option);
                    }}
                    name={`rate-input-${option.code}`}
                  />
                </ListItem>
              );
            })}
            {!disabled && (
              <Box px={2}>
                <TextField
                  autoFocus
                  id="comments-multiline-flexible"
                  variant="outlined"
                  label="Comentarios del producto ..."
                  multiline
                  maxRows={4}
                  value={select.comments}
                  onChange={handleAltTopicChange}
                />
              </Box>
            )}
          </List>
        </div>
      )}
      <DialogActions>
        <RoundButton
          size={'small'}
          loading={loading}
          disabled={disabled}
          onClick={onSubmit}
        >
          <Typography className={classes.button}>{'Enviar reseña'}</Typography>
        </RoundButton>
      </DialogActions>
    </LoadingWrapper>
  );
};
