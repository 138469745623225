import { combineReducers } from 'redux';

import { authReducer } from './auth.reducer';
import { cartReducer } from './cart.reducer';

const rootReducer = combineReducers({
  authentication: authReducer,
  cart: cartReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
