import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { UserAvatar } from '../../../components/user-avatar';
import { Bargain, Question } from '../../../interfaces/models/questions';
import { humanizeStrDate } from '../../../utils/helpers';

interface CommentItemProps {
  comment: Question;
  onReply?: (question: Question) => void;
  onAccept?: (bargain: Bargain) => void;
  onReject?: (bargain: Bargain) => void;
  ident?: boolean;
}
export const CommentItem: React.FC<CommentItemProps> = ({
  comment: { bargain, ...comment },
  ident = false,
  onReply,
  onAccept,
  onReject,
}) => {
  return (
    <Box ml={ident ? 4 : 0} id={`comment-${comment.id}`}>
      <UserAvatar user={comment.user} />
      <Typography color="textSecondary" variant="body2">
        {comment.text}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        minHeight={36}
      >
        <Typography color="textSecondary" variant="caption">
          {humanizeStrDate(comment.createdAt)}
        </Typography>
        {bargain
          ? bargain.status === 'pending' && (
              <Box>
                {onReject && (
                  <Button color="primary" onClick={() => onReject(bargain)}>
                    Rechazar
                  </Button>
                )}
                {onAccept && (
                  <Button color="secondary" onClick={() => onAccept(bargain)}>
                    Aceptar
                  </Button>
                )}
              </Box>
            )
          : onReply && (
              <Button color="secondary" onClick={() => onReply(comment)}>
                Responder
              </Button>
            )}
      </Box>
    </Box>
  );
};
