import { Api } from './api-base';
import { Response, Messages } from '../interfaces/responses/response';
import { Card } from '../interfaces/models/card';
import {
  PaymentMethodsRequest,
  Provider,
} from '../interfaces/requests/payment-methods';

export interface ListCards {
  errors?: Messages;
  cards: Card[];
}

export interface ShowCard {
  errors?: Messages;
  card?: Card;
}

export const PaymentMethodsApi = {
  index: async (): Promise<ListCards> => {
    const { errors, response } = await Api.get<Response<Card[]>>(
      '/payment-methods',
      { params: { provider: Provider.OPENPAY } },
    );
    return { errors, cards: response?.data || [] };
  },
  store: async (input: PaymentMethodsRequest): Promise<ShowCard> => {
    const { errors, response } = await Api.post<Response<Card>>(
      '/payment-methods',
      input,
    );
    return { errors, card: response?.data };
  },
  delete: async (id: string): Promise<ShowCard> => {
    const { errors } = await Api.delete<Response<void>>(
      `/payment-methods/${id}`,
    );
    return { errors };
  },
};
