import { Api } from './api-base';
import { LoginResponse } from '../interfaces/responses/auth/login-response';
import { LoginRequest } from '../interfaces/requests/auth/login-request';
import { SignupRequest } from '../interfaces/requests/auth/signup-request';
import { SignupResponse } from '../interfaces/responses/auth/signup-response';
import { Response } from '../interfaces/responses/response';

export const AuthApi = {
  doLogin: async (input: LoginRequest) => {
    const { response, errors } = await Api.post<LoginResponse>(
      '/auth/login',
      input,
      {},
      true,
    );
    return {
      errors,
      token: response?.data?.token || '',
      user: response?.data?.user,
    };
  },
  doSignup: async (input: SignupRequest) => {
    const { response, errors } = await Api.post<SignupResponse>(
      '/auth/signup',
      input,
      {},
      true,
    );
    return {
      errors,
      token: response?.data?.token || '',
      user: response?.data?.user,
    };
  },
  resendVerification: async (email: string) => {
    const { errors } = await Api.post<Response<void>>(
      '/auth/resend-verification',
      { email },
    );
    return { errors };
  },
  verificateEmail: async (token: string) => {
    const { errors } = await Api.post<Response<void>>(
      '/auth/email-verification',
      { token },
    );
    return { errors };
  },
  resetPassword: async (token: string, password: string) => {
    const { errors } = await Api.post<Response<void>>('/auth/reset', {
      token,
      password,
    });
    return { errors };
  },
};
