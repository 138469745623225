import React, { useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Reply from '@material-ui/icons/Reply';

import { QuestionsApi } from '../../../api/questions';
import { AlertMessage } from '../../../components/alert-message';
import { LoadingWrapper } from '../../../components/loading-wrapper';
import { RoundButton } from '../../../components/round-button';
import { UserAvatar } from '../../../components/user-avatar';
import {
  Bargain,
  BargainStatus,
  Question,
} from '../../../interfaces/models/questions';
import { UserItem } from '../../../interfaces/models/user-item';
import { Messages } from '../../../interfaces/responses/response';
import { AuthService } from '../../../services/auth-service';
import { CommentItem } from './comment';

interface PostQuestionsProps {
  postId: number;
  user: UserItem;
  sticky?: { xs: number; md: number };
  bargain?: Question;
  onQuestion: () => void;
  onBargain: (price: number) => void;
}
export const PostQuestions: React.FC<PostQuestionsProps> = ({
  user,
  postId,
  sticky,
  bargain,
  onQuestion,
  onBargain,
}) => {
  const input = useRef<HTMLInputElement>();
  const [questions, setCuestions] = useState<Question[]>([]);
  const [loading, setLoading] = useState(true);
  const [fetchErrors, setFetchErrors] = useState<Messages>();
  const [text, setText] = useState('');
  const [saving, setSaving] = useState(false);
  const [saveErrors, setSaveErrors] = useState<Messages>();
  const [replyTo, setReplyTo] = useState<Question>();

  useEffect(() => {
    setLoading(true);
  }, [postId]);

  useEffect(() => {
    if (bargain && !questions.some((q) => q.id === bargain.id)) {
      setCuestions([bargain, ...questions]);
    }
  }, [bargain, questions]);

  useEffect(() => {
    const fetch = async () => {
      setCuestions([]);
      setFetchErrors(undefined);
      const { errors, questions } = await QuestionsApi.index({ postId });
      setFetchErrors(errors);
      if (!errors) {
        setCuestions(questions);
      }
      setLoading(false);
    };
    loading && fetch();
  }, [loading, postId]);

  const saveQuestion = async () => {
    if (!text) {
      return;
    }
    setSaving(true);
    setSaveErrors(undefined);
    if (replyTo) {
      const { answer, errors } = await QuestionsApi.answer({
        text,
        questionId: replyTo.id,
      });
      setSaveErrors(errors);
      if (!errors && answer) {
        replyTo.answers = replyTo.answers || [];
        replyTo.answers.push(answer);
        setCuestions(questions);
        setReplyTo(undefined);
      }
    } else {
      const { question, errors } = await QuestionsApi.post({ text, postId });
      setSaveErrors(errors);
      if (!errors && question) {
        setCuestions([question, ...questions]);
        onQuestion();
      }
    }
    setText('');
    setSaving(false);
  };

  useEffect(() => {
    if (replyTo && input.current) {
      input.current.focus();
    }
  }, [replyTo, input]);

  const replyBargain = async (status: BargainStatus, bargain: Bargain) => {
    const { errors, question } = await QuestionsApi.replyBargain(bargain.id, {
      status,
    });
    setSaveErrors(errors);
    if (!errors && question) {
      const index = questions.findIndex((q) => q.id === question.id);
      if (index !== -1) {
        questions.splice(index, 1);
      }
      setCuestions([question, ...questions]);
      onBargain(bargain.amount);
    }
  };

  const viewer = AuthService.getUser();

  return (
    <Box minHeight={48} position="relative">
      <LoadingWrapper loading={loading} errors={fetchErrors}>
        <Box mb={2}>
          <Typography variant="caption">
            <Box fontWeight={600} component="span">
              Comentarios
            </Box>
          </Typography>
        </Box>
        {viewer && (
          <Box
            pb={1}
            bgcolor="background.paper"
            zIndex="appBar"
            {...(sticky ? { position: 'sticky', top: sticky } : {})}
          >
            {replyTo && (
              <AlertMessage
                mt={2}
                mb={-1}
                severity="info"
                messages={{ text: replyTo.text }}
                icon={<Reply />}
                onClose={() => setReplyTo(undefined)}
              />
            )}
            <Box display="flex" alignItems="flex-start">
              <Box mt={2}>
                <UserAvatar user={viewer} showName={false} />
              </Box>
              <Box ml={1} flexGrow={1}>
                <TextField
                  id="text-input"
                  inputRef={input}
                  variant="outlined"
                  placeholder={`${replyTo ? 'Responder' : 'Añadir'} comentario`}
                  multiline
                  minRows={2}
                  maxRows={4}
                  value={text}
                  onChange={(e) => setText(e.target.value.trimStart())}
                />
              </Box>
            </Box>
            <Box textAlign="right">
              {saveErrors && <AlertMessage messages={saveErrors} />}
              <RoundButton
                size="small"
                disabled={!text}
                loading={saving}
                onClick={saveQuestion}
              >
                {replyTo ? 'Responder' : 'Enviar'}
              </RoundButton>
            </Box>
          </Box>
        )}
        {questions.map((q) => (
          <Box key={q.id} mb={1}>
            <CommentItem
              comment={q}
              onReply={viewer ? setReplyTo : undefined}
              onAccept={
                viewer?.id === user.id
                  ? (b) => replyBargain(BargainStatus.ACCEPTED, b)
                  : undefined
              }
              onReject={
                viewer?.id === user.id
                  ? (b) => replyBargain(BargainStatus.REJECTED, b)
                  : undefined
              }
            />
            {q.answers?.map((a) => (
              <CommentItem key={a.id} comment={a} ident />
            ))}
          </Box>
        ))}
      </LoadingWrapper>
    </Box>
  );
};
