import React, { FC } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { AuthService } from '../services';

export const PrivateRoute: FC<RouteProps> = ({
  component: Component,
  children,
  ...rest
}) => (
  <Route
    {...rest}
    render={
      Component
        ? (props) =>
            AuthService.isAuth() ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{ pathname: '/', state: { from: props.location } }}
              />
            )
        : undefined
    }
  >
    {AuthService.isAuth() ? children : <Redirect to={{ pathname: '/' }} />}
  </Route>
);
