import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { PaymentMethodsApi, ListCards } from '../../../api/payment-methods-api';
import { BorderedList } from '../../../components/bordered-list';
import { ConfirmDialog } from '../../../components/confirm-dialog';
import { LoadingWrapper } from '../../../components/loading-wrapper';
import { Messages } from '../../../interfaces/responses/response';
import { Card } from '../../../interfaces/models/card';
import { equalsObj } from '../../../utils/helpers';

interface PaymentMethodsListProps {
  onShowCreate: () => void;
  select?: {
    value?: Card;
    onChange: (card?: Card) => void;
  };
}
export const PaymentMethodsList: React.FC<PaymentMethodsListProps> = ({
  select,
  onShowCreate,
}) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<ListCards>({ cards: [] });

  const [menuRef, setMenuRef] = useState<null | HTMLElement>(null);
  const [indexId, setIndexId] = useState<[number, string]>([-1, '']);

  const [openDelete, setOpenDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errorsDelete, setErrorsDelete] = useState<Messages>();

  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    const fetcCards = async () => {
      setResponse(await PaymentMethodsApi.index());
      setLoading(false);
    };
    loading && fetcCards();
  }, [loading]);

  useEffect(() => {
    setSelectedId(select?.value?.id || '');
  }, [select]);

  useEffect(() => {
    if (loading) {
      return;
    }
    const item = response.cards.find((c) => c.id === selectedId);
    if (select && !equalsObj(item, select.value)) {
      select.onChange(item);
    }
  }, [select, loading, response, selectedId]);

  const openMenu = (el: HTMLElement, id: string, index: number) => {
    setMenuRef(el);
    setIndexId([index, id]);
  };

  const closeMenu = () => setMenuRef(null);

  const openDeleteModal = () => {
    closeMenu();
    setOpenDelete(true);
  };

  const closeDeleteModal = () => setOpenDelete(false);

  const handleDelete = async () => {
    const [index, id] = indexId;
    setDeleting(true);
    const { errors } = await PaymentMethodsApi.delete(id);
    setErrorsDelete(errors);
    if (!errors) {
      response.cards.splice(index, 1);
      setResponse({
        cards: response.cards,
      });
      closeDeleteModal();
    }
    setDeleting(false);
  };

  return (
    <React.Fragment>
      <LoadingWrapper loading={loading} errors={response.errors}>
        <List
          disablePadding
          component={RadioGroup}
          value={selectedId}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSelectedId(e.target.value)
          }
        >
          {response.cards.map((card, i) => (
            <BorderedList key={card.id}>
              {select && (
                <ListItemIcon>
                  <Radio value={card.id} />
                </ListItemIcon>
              )}
              <ListItemText
                primary={`${card.brand} **${card.lastFour}`}
                secondary={`${card.name} ${card.expiry}`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={(e) => openMenu(e.currentTarget, card.id, i)}
                >
                  <MoreVertIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </BorderedList>
          ))}
          <BorderedList button onClick={onShowCreate}>
            <ListItemText primary="Agregar tarjeta" />
            <ListItemSecondaryAction>
              <IconButton edge="end" color="secondary" onClick={onShowCreate}>
                <NavigateNextIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </BorderedList>
        </List>
      </LoadingWrapper>
      <Menu
        keepMounted
        open={Boolean(menuRef)}
        anchorEl={menuRef}
        onClose={closeMenu}
      >
        <MenuItem button onClick={openDeleteModal}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <Typography variant="inherit">Eliminar</Typography>
        </MenuItem>
      </Menu>
      <ConfirmDialog
        open={openDelete}
        loading={deleting}
        onCancel={closeDeleteModal}
        onConfirm={handleDelete}
        errors={errorsDelete}
        title="Eliminar tarjeta"
        confirmText="Eliminar"
      >
        ¿Estas seguro de eliminar esta tarjeta?. Esta acción no puede
        revertirse.
      </ConfirmDialog>
    </React.Fragment>
  );
};
