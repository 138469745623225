import { Container, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

interface PlaceholderPicture {
  posts: string;
  carts: string;
  favorites: string;
  reviews: string;
}

const pictures: PlaceholderPicture = {
  posts: '/img/placeholders/no-posts.svg',
  favorites: '/img/placeholders/no-favorites.svg',
  reviews: '/img/placeholders/no-reviews.svg',
  carts: '/img/placeholders/empty-cart.svg',
};

const useClasess = makeStyles((theme) => ({
  picture: {
    display: 'block',
    maxWidth: '100%',
    margin: theme.spacing(0, 'auto', 2),
  },
}));

interface PlaceholderProps {
  picture: keyof PlaceholderPicture;
  title: React.ReactNode;
  body?: React.ReactNode;
}
export const Placeholder: React.FC<PlaceholderProps> = ({
  picture,
  title,
  body,
}) => {
  const classes = useClasess();

  return (
    <Box
      flexGrow={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <Container maxWidth="xs">
        <img
          src={pictures[picture]}
          className={classes.picture}
          alt={picture}
        />
        <Typography variant="h5" color="primary" gutterBottom={!!body}>
          {title}
        </Typography>
        {body && (
          <Typography variant="body2" color="textSecondary">
            {body}
          </Typography>
        )}
      </Container>
    </Box>
  );
};
