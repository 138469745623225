import { Api } from './api-base';
import { Response, Messages } from '../interfaces/responses/response';
import { Cart } from '../interfaces/models/cart';
import { ShippingQuotes } from '../interfaces/models/shipping-quote';
import { ShippingQuoteRequest } from '../interfaces/requests/carts/shipping-request';
import { objToQueryStr } from '../utils/query-params';

export interface GetCarts {
  errors?: Messages;
  carts: Cart[];
}

export interface GetCart {
  errors?: Messages;
  cart?: Cart;
}

export interface GetQuotes {
  errors?: Messages;
  quotes: ShippingQuotes;
}

export const CartsApi = {
  index: async (): Promise<GetCarts> => {
    const { errors, response } = await Api.get<Response<Cart[]>>('/carts');
    return { errors, carts: response?.data || [] };
  },
  show: async (id: number): Promise<GetCart> => {
    const { errors, response } = await Api.get<Response<Cart>>(`/carts/${id}`);
    return { errors, cart: response?.data };
  },
  addItem: async (postId: number): Promise<GetCarts> => {
    const { errors, response } = await Api.post<Response<Cart[]>>(
      '/carts/items',
      {
        postId,
      },
    );
    return { errors, carts: response?.data || [] };
  },
  removeItem: async (postId: number): Promise<GetCarts> => {
    const { errors, response } = await Api.delete<Response<Cart[]>>(
      `/carts/items/${postId}`,
    );
    return { errors, carts: response?.data || [] };
  },
  removeSeller: async (sellerId: number): Promise<GetCarts> => {
    const { errors, response } = await Api.delete<Response<Cart[]>>(
      `/carts/sellers/${sellerId}`,
    );
    return { errors, carts: response?.data || [] };
  },
  removeAll: async (): Promise<GetCarts> => {
    const { errors, response } = await Api.delete<Response<Cart[]>>('/carts');
    return { errors, carts: response?.data || [] };
  },
  shippingQuote: async (input: ShippingQuoteRequest): Promise<GetQuotes> => {
    const qry = objToQueryStr(input);
    const { errors, response } = await Api.get<ShippingQuotes>(
      `/carts/shipping-quote?${qry}`,
    );
    return { errors, quotes: response || {} };
  },
};
