import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { QuestionsApi } from '../../../api/questions';
import { ConfirmDialog } from '../../../components/confirm-dialog';
import { Question } from '../../../interfaces/models/questions';
import { Messages } from '../../../interfaces/responses/response';

interface BargainPriceProps {
  id: number;
  open: boolean;
  onClose: () => void;
  onBargain: (question: Question) => void;
}
export const BargainPrice: React.FC<BargainPriceProps> = ({
  id,
  open,
  onClose,
  onBargain,
}) => {
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState<Messages>();

  const formik = useFormik<{ amount: string }>({
    initialValues: {
      amount: '',
    },
    validationSchema: yup.object({
      amount: yup
        .number()
        .typeError('Precio debe ser un número')
        .min(1, 'Precio debe ser mayor a $1')
        .required('Precio es requerido'),
    }),
    onSubmit: async ({ amount }) => {
      setSaving(true);
      setErrors(undefined);
      const { errors, question } = await QuestionsApi.doBargain({
        postId: id,
        amount: +amount,
      });
      setErrors(errors);
      if (!errors && question) {
        onBargain(question);
        onClose();
      }
      setSaving(false);
    },
  });

  const sendBargain = async () => {
    formik.submitForm();
  };

  return (
    <ConfirmDialog
      title="NEGOCIA EL PRECIO"
      open={open}
      onCancel={onClose}
      onConfirm={sendBargain}
      confirmText="Negociar"
      loading={saving}
      errors={errors}
    >
      <TextField
        type="tel"
        variant="outlined"
        autoFocus
        id="text-input"
        label="Precio"
        placeholder="Indica el precio que deseas pagar"
        name="amount"
        value={formik.values.amount}
        onChange={formik.handleChange}
        error={formik.touched.amount && !!formik.errors.amount}
        helperText={formik.touched.amount && formik.errors.amount}
      />
    </ConfirmDialog>
  );
};
