import React, { FunctionComponent } from 'react';
import { Messages } from '../interfaces/responses/response';
import { AlertMessage } from './alert-message';
import { Loading } from './loading';

interface LoadingWrapperProps {
  loading: boolean;
  errors?: Messages;
}
export const LoadingWrapper: FunctionComponent<LoadingWrapperProps> = ({
  loading,
  errors,
  children,
}) => {
  return loading ? (
    <Loading />
  ) : errors ? (
    <AlertMessage messages={errors} />
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};
