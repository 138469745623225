import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { PostItem } from '../../../interfaces/models/post-item';
import { RoundButton } from '../../../components/round-button';
import { AuthService } from '../../../services/auth-service';
import { formatCurrency } from '../../../utils/helpers';
import { useDispatch } from 'react-redux';
import { cartActions } from '../../../actions/cart.actions';
import { UserAvatar } from '../../../components/user-avatar';

const useClasses = makeStyles((theme) => ({
  media: {
    padding: '50% 0',
  },
  avatar: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
}));

interface PostListItemProps {
  post: PostItem;
}
export const PostListItem: React.FC<PostListItemProps> = ({ post }) => {
  const classes = useClasses();
  const currentUser = AuthService.getUser();
  const dispatch = useDispatch();

  const onPostToCart = (itemId: number) =>
    dispatch(cartActions.addItem(itemId));

  return (
    <Box mx={2} mb={4}>
      <Card>
        <CardActionArea component={Link} to={`/posts/${post.id}`}>
          <CardMedia
            image={post.pictureUrl}
            title={post.name}
            className={classes.media}
          />
          <CardContent>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <FavoriteIcon fontSize="small" color="disabled" />
              <Typography variant="caption">
                ({post.favoritesCount || 0})
              </Typography>
            </Box>
            <Typography variant="h5" component="h2" noWrap>
              {post.name}
            </Typography>
            <Box display="flex" alignItems="center" mb={1}>
              <Box flexGrow={1} pr={1}>
                <Typography variant="h5" color="secondary">
                  {formatCurrency(post.price)}
                </Typography>
              </Box>
              <Box textAlign="right" flexShrink="0">
                <Typography variant="caption" color="textSecondary">
                  Precio original <br />
                  {formatCurrency(post.originalPrice, false)}
                </Typography>
              </Box>
            </Box>
            <Box overflow="hidden">
              <Typography variant="caption" color="textSecondary" noWrap>
                {post.attributes.map((attr) => attr.value).join(' | ')}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
        <React.Fragment>
          <CardActions>
            <RoundButton
              fullWidth
              disabled={post.user?.id === currentUser?.id}
              onClick={() => onPostToCart(post.id)}
            >
              Agregar a carrito
            </RoundButton>
          </CardActions>
          <Divider />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="start"
            px={2}
            py={1}
          >
            <UserAvatar user={post.user} />
          </Box>
        </React.Fragment>
      </Card>
    </Box>
  );
};
