import React, { FunctionComponent } from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const CustomButton = withStyles((theme) => ({
  root: {
    borderRadius: '9999px',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))(Button);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface RoundButtonProps extends ButtonProps<any> {
  onClick?: React.MouseEventHandler;
  loading?: boolean;
}

export const RoundButton: FunctionComponent<RoundButtonProps> = ({
  onClick,
  children,
  size = 'large',
  variant = 'contained',
  loading = false,
  disabled,
  ...extra
}) => {
  return (
    <CustomButton
      variant={variant}
      size={size}
      color="secondary"
      disableElevation
      onClick={onClick}
      disabled={disabled || loading}
      {...extra}
    >
      {loading ? <CircularProgress size={24} /> : children}
    </CustomButton>
  );
};
