import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import { ListItemProps } from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';

import { BorderedList } from '../../../../components/bordered-list';
import Container from '@material-ui/core/Container';
import { AuthService } from '../../../../services/auth-service';

interface ListItemLinkProps
  extends ListItemProps<RouterLink, { button?: true }> {
  text: string;
}

const ListItemLink = ({ text, ...props }: ListItemLinkProps) => {
  return (
    <BorderedList button component={RouterLink} {...props}>
      <ListItemText primary={text} />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          color="secondary"
          component={RouterLink}
          to={props.to}
        >
          <NavigateNext />
        </IconButton>
      </ListItemSecondaryAction>
    </BorderedList>
  );
};

interface UserMenuProps {
  id: number;
}

export const UserMenu: FunctionComponent<UserMenuProps> = ({ id }) => {
  const logout = () => {
    AuthService.logout();
  };
  return (
    <Container maxWidth="sm">
      <Box maxWidth={480} mx="auto">
        <List>
          <ListItemLink to="/me/edit" text="Mis datos" />
          <ListItemLink to="/me/addresses" text="Mis direcciones" />
          <ListItemLink to="/me/payment-methods" text="Mis métodos de pago" />
          <ListItemLink
            to={`/me/bank-accounts/${id}`}
            text="Mis datos bancarios"
          />
          <ListItemLink to="/me/faqs" text="Preguntas frecuentes" />
          <ListItemLink to="/terms" text="Términos y condiciones" />
          <ListItemLink to="/me/support" text="Ayuda" />
          <BorderedList button>
            <ListItemText primary="Cerrar sesión" onClick={() => logout()} />
            <ListItemSecondaryAction>
              <IconButton edge="end" color="secondary">
                <NavigateNext />
              </IconButton>
            </ListItemSecondaryAction>
          </BorderedList>
        </List>
      </Box>
    </Container>
  );
};
