import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { useFormik, FormikErrors } from 'formik';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';

import { authActions } from '../../actions';
import { RootState } from '../../reducers';
import { RoundButton } from '../../components/round-button';
import { TogglePasswordInput } from '../../components/toggle-password-input';
import { SocialSignin } from './social-signin';
import { LoginRequest } from '../../interfaces/requests/auth/login-request';
import texts from '../../lang/es.json';
import { AuthProvider } from '../../services';

const validate = (values: LoginRequest) => {
  const errors: FormikErrors<LoginRequest> = {};
  if (!values.user) {
    errors.user = 'Correo electrónico es requerido.';
  }
  if (!values.secret) {
    errors.secret = 'Contraseña es requerida.';
  }
  return errors;
};

export const Login: FunctionComponent = () => {
  const loggingIn = useSelector<RootState, boolean>(
    (state) => state.authentication.loggingIn || false,
  );
  const provider = useSelector<RootState, AuthProvider | undefined>(
    (state) => state.authentication.provider,
  );
  const errorMessage = useSelector<RootState, string>(
    (state) => state.authentication.errorMessage || '',
  );
  const next = useSelector<RootState, AnyAction | undefined>(
    (state) => state.authentication.next,
  );

  const dispatch = useDispatch();

  const formik = useFormik<LoginRequest>({
    initialValues: {
      provider: 'email',
      user: '',
      secret: '',
    },
    validate: validate,
    onSubmit: (input) => {
      dispatch(authActions.login(input, next));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        variant="outlined"
        type="email"
        label={texts.email}
        name="user"
        value={formik.values.user}
        onChange={formik.handleChange}
        error={formik.touched.user && !!formik.errors.user}
        helperText={formik.touched.user && formik.errors.user}
      />
      <TogglePasswordInput
        label="Contraseña"
        name="secret"
        value={formik.values.secret}
        onChange={formik.handleChange}
        error={formik.touched.secret && !!formik.errors.secret}
        helperText={formik.touched.secret && formik.errors.secret}
      />
      <Typography align="right">
        <Link variant="caption" color="textSecondary">
          Olvide mi contraseña
        </Link>
      </Typography>
      <Box my={3}>
        <RoundButton
          type="submit"
          loading={provider === AuthProvider.EMAIL && loggingIn}
          disabled={loggingIn}
          fullWidth
        >
          Iniciar sesión
        </RoundButton>
      </Box>
      {!loggingIn && errorMessage ? (
        <Alert severity="error">{errorMessage}</Alert>
      ) : null}
      <SocialSignin />
    </form>
  );
};
