import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { CartsApi } from '../../../api/carts-api';
import { BorderedList } from '../../../components/bordered-list';
import { LoadingWrapper } from '../../../components/loading-wrapper';
import { RoundButton } from '../../../components/round-button';
import { ShippingQuote } from '../../../interfaces/models/shipping-quote';
import { Messages } from '../../../interfaces/responses/response';
import { equalsObj, formatCurrency } from '../../../utils/helpers';

interface StepShippingProps {
  cartId: number;
  addressId: string;
  value?: ShippingQuote;
  onChange: (s?: ShippingQuote) => void;
  onPrev: () => void;
  onNext: () => void;
}
export const StepShipping: React.FC<StepShippingProps> = ({
  cartId,
  addressId,
  value,
  onChange,
  onPrev,
  onNext,
}) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<ShippingQuote[]>([]);
  const [errors, setErrors] = useState<Messages>();
  const [selected, setSelected] = useState<string>('');

  useEffect(() => {
    setLoading(true);
  }, [cartId, addressId]);

  useEffect(() => {
    const fetch = async () => {
      setErrors(undefined);
      setItems([]);
      const input = {
        addressId,
        cartIds: [cartId],
      };
      const { errors, quotes } = await CartsApi.shippingQuote(input);
      setErrors(errors);
      if (!errors && quotes[cartId]) {
        setItems(quotes[cartId]);
      }
      setLoading(false);
    };
    loading && fetch();
  }, [loading, cartId, addressId]);

  useEffect(() => {
    if (loading) {
      return;
    }
    const quote = items.find((q) => alias(q) === selected);
    if (!equalsObj(quote, value)) {
      onChange(quote);
    }
  }, [loading, selected, items, value, onChange]);

  useEffect(() => {
    const str = `${value?.serviceId}-${value?.carrierId}`;
    const quote = items.find((q) => alias(q) === str);
    setSelected(quote ? str : '');
  }, [value, items]);

  const alias = (quote: ShippingQuote) => {
    return `${quote.serviceId}-${quote.carrierId?.toString() || ''}`;
  };

  return (
    <React.Fragment>
      <Box position="relative" minHeight={48}>
        <LoadingWrapper loading={loading} errors={errors}>
          <List
            disablePadding
            component={RadioGroup}
            value={selected}
            onChange={(e: unknown, value: string) => setSelected(value)}
          >
            {items.map((quote) => (
              <BorderedList key={alias(quote)}>
                <ListItemIcon>
                  <Radio value={alias(quote)} />
                </ListItemIcon>
                <ListItemText
                  primary={quote.carrierName}
                  secondary={formatCurrency(quote.price)}
                />
                <ListItemSecondaryAction />
              </BorderedList>
            ))}
          </List>
          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <RoundButton
                  fullWidth
                  variant="outlined"
                  onClick={() => onPrev()}
                >
                  Anterior
                </RoundButton>
              </Grid>
              <Grid item xs={6}>
                <RoundButton
                  fullWidth
                  disabled={!selected}
                  onClick={() => onNext()}
                >
                  Siguiente
                </RoundButton>
              </Grid>
            </Grid>
          </Box>
        </LoadingWrapper>
      </Box>
    </React.Fragment>
  );
};
