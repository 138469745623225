import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { AuthApi } from '../../api/auth-api';
import { AlertMessage } from '../../components/alert-message';
import { AppToolbar } from '../../components/app-toolbar';
import { LoadingWrapper } from '../../components/loading-wrapper';
import { Messages } from '../../interfaces/responses/response';

export const EmailVerification = () => {
  const [errors, setErrors] = useState<Messages>();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const match = useRouteMatch<{ token: string }>();
  const token = match.params.token;

  useEffect(() => {
    setLoading(true);
  }, [token]);

  useEffect(() => {
    const verificate = async () => {
      setErrors(undefined);
      setSuccess(false);
      const { errors } = await AuthApi.verificateEmail(token);
      setErrors(errors);
      if (!errors) {
        setSuccess(true);
      }
      setLoading(false);
    };
    loading && verificate();
  }, [loading, token]);

  return (
    <React.Fragment>
      <AppToolbar title="Verificación de correo" disableBack />
      <Box minHeight={24}>
        <LoadingWrapper loading={loading} errors={errors}>
          {success && (
            <AlertMessage
              severity="success"
              messages={{ m: 'Correo verificado exitosamente.' }}
              action={
                <Button color="inherit" component={Link} to="/">
                  Inicio
                </Button>
              }
            />
          )}
        </LoadingWrapper>
      </Box>
    </React.Fragment>
  );
};
