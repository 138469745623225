import React, { FunctionComponent, useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import { makeStyles } from '@material-ui/core/styles';

import { User } from '../../../../interfaces/models/user';
import { RoundButton } from '../../../../components/round-button';
import { AuthService } from '../../../../services/auth-service';
import { UsersApi } from '../../../../api/users-api';
import { UserFollows } from './user-follows';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '96px',
    height: '96px',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
  },
}));

interface UserHeaderProps {
  user: User;
}

const formatDate = (strDate?: string) => {
  if (!strDate) {
    return 'no disponible';
  }
  const date = new Date(strDate);
  return date.toLocaleDateString();
};

export const UserHeader: FunctionComponent<UserHeaderProps> = ({ user }) => {
  const classes = useStyles();
  const isMe = user.id === AuthService.getUser()?.id;
  const [follows, setFollow] = useState(user.follows || false);
  const [followersCount, setFollowersCount] = useState(user.followersCount);
  const [showFolowings, setShowFolowings] = useState(false);

  useEffect(() => {
    setFollow(user.follows || false);
  }, [user]);

  const toggleFollow = async () => {
    const flag = !follows;
    setFollow(flag);
    setFollowersCount(followersCount + (flag ? 1 : -1));
    const { errors } = await UsersApi.follow(user.id);
    if (errors) {
      setFollow(!flag);
      setFollowersCount(followersCount + (flag ? -1 : 1));
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Box
          display="flex"
          alignItems="center"
          mb={{ xs: 2, md: 0 }}
          justifyContent="center"
        >
          <Box width={240} clone>
            <Avatar
              src={user.pictureUrl || '/img/placeholder-profile.svg'}
              className={classes.avatar}
            />
          </Box>
          <Box flexGrow={{ md: 1 }} px={2}>
            <Typography variant="h5" gutterBottom={false}>
              {user.firstName} {user.lastName}
            </Typography>
            <Typography variant="caption" gutterBottom component="div">
              Ultima conexión{' '}
              {formatDate(user.lastConnection || new Date().toISOString())}
            </Typography>
            <Box>
              <Rating value={user.rate} readOnly precision={0.5} />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} container alignItems="center">
        <Box
          display="flex"
          textAlign="center"
          alignItems="center"
          width={1}
          justifyContent={{ xs: 'center', md: 'flex-start' }}
        >
          <Box mx={2} width={120}>
            {user.soldCount} Productos vendidos
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box mx={2} width={120}>
            {user.purchasedCount} Productos Comprados
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box mx={2} width={120}>
            <Link color="inherit" onClick={() => setShowFolowings(true)}>
              {followersCount} Seguidores
            </Link>
          </Box>
          {!isMe && (
            <Box mx={2} width={120}>
              <RoundButton
                color={follows ? 'primary' : 'secondary'}
                onClick={toggleFollow}
              >
                {follows ? 'Siguiendo' : 'Seguir'}
              </RoundButton>
            </Box>
          )}
        </Box>
      </Grid>
      {showFolowings && (
        <UserFollows
          userId={user.id}
          open={showFolowings}
          onClose={() => setShowFolowings(false)}
        />
      )}
    </Grid>
  );
};
