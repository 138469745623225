import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { FormikErrors, useFormik } from 'formik';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';

import { SocialSignin } from './social-signin';
import { RoundButton } from '../../components/round-button';
import { TogglePasswordInput } from '../../components/toggle-password-input';
import { SignupRequest } from '../../interfaces/requests/auth/signup-request';
import { countries } from '../../utils/data';
import { RootState } from '../../reducers';
import { authActions } from '../../actions';

interface SignupInput extends SignupRequest {
  agreeTerms: boolean;
}

const validate = (values: SignupInput) => {
  const errors: FormikErrors<SignupInput> = {};
  if (!values.firstName) {
    errors.firstName = 'Nombre es requerido.';
  }
  if (!values.lastName) {
    errors.lastName = 'Apellido es requerido.';
  }
  if (!values.email) {
    errors.email = 'Correo electrónico es requerido.';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Correo electrónico no válido.';
  }
  if (!values.phoneCountry) {
    errors.phoneCountry = 'País es requerido.';
  }
  if (!values.phone) {
    errors.phone = 'Teléfono es requerido.';
  } else if (!/^[0-9]{10}$/i.test(values.phone)) {
    errors.phone = 'Teléfono no válido.';
  }
  if (!values.password) {
    errors.password = 'Contraseña es requerida.';
  }
  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = 'Contraseñas no coinciden.';
  }
  if (!values.agreeTerms) {
    errors.agreeTerms = 'Debes aceptar los terminos y condiciones.';
  }
  return errors;
};

export const Signup: FunctionComponent = () => {
  const loggingIn = useSelector<RootState, boolean>(
    (state) => state.authentication.loggingIn || false,
  );
  const errorMessage = useSelector<RootState, string>(
    (state) => state.authentication.errorMessage || '',
  );
  const next = useSelector<RootState, AnyAction | undefined>(
    (state) => state.authentication.next,
  );
  const dispatch = useDispatch();

  const formik = useFormik<SignupInput>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneCountry: 'MX',
      phone: '',
      password: '',
      passwordConfirmation: '',
      agreeTerms: false,
    },
    validate,
    onSubmit: (values) => {
      const { agreeTerms, ...input } = values;
      dispatch(authActions.signup(input, next));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        variant="outlined"
        type="text"
        label="Nombre(s)"
        name="firstName"
        value={formik.values.firstName}
        onChange={formik.handleChange}
        error={formik.touched.firstName && !!formik.errors.firstName}
        helperText={formik.touched.firstName && formik.errors.firstName}
        inputProps={{ autoComplete: 'given-name' }}
      />
      <TextField
        variant="outlined"
        type="text"
        label="Apellido(s)"
        name="lastName"
        value={formik.values.lastName}
        onChange={formik.handleChange}
        error={formik.touched.lastName && !!formik.errors.lastName}
        helperText={formik.touched.lastName && formik.errors.lastName}
        inputProps={{ autoComplete: 'family-name' }}
      />
      <TextField
        variant="outlined"
        type="email"
        label="Correo electrónico"
        name="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && !!formik.errors.email}
        helperText={formik.touched.email && formik.errors.email}
      />
      <Grid container spacing={2}>
        <Grid item xs={5} sm={4}>
          <TextField
            variant="outlined"
            select
            label="País"
            name="phoneCountry"
            value={formik.values.phoneCountry}
            onChange={formik.handleChange}
            error={formik.touched.phoneCountry && !!formik.errors.phoneCountry}
            helperText={
              formik.touched.phoneCountry && formik.errors.phoneCountry
            }
          >
            {countries.map((option) => (
              <MenuItem key={option.code} value={option.code}>
                {option.flag} {option.dial_code}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={7} sm={8}>
          <TextField
            variant="outlined"
            type="tel"
            label="Teléfono celular"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={!!formik.errors.phone && formik.touched.phone}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </Grid>
      </Grid>
      <TogglePasswordInput
        label="Contraseña"
        name="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && !!formik.errors.password}
        helperText={formik.touched.password && formik.errors.password}
      />
      <TogglePasswordInput
        label="Confirmar contraseña"
        name="passwordConfirmation"
        value={formik.values.passwordConfirmation}
        onChange={formik.handleChange}
        error={
          formik.touched.passwordConfirmation &&
          !!formik.errors.passwordConfirmation
        }
        helperText={
          formik.touched.passwordConfirmation &&
          formik.errors.passwordConfirmation
        }
      />
      <FormControl
        error={formik.touched.agreeTerms && !!formik.errors.agreeTerms}
      >
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="agreeTerms"
              value={formik.values.agreeTerms}
              onChange={formik.handleChange}
            />
          }
          label={
            <React.Fragment>
              Acepto&nbsp;
              <Link href="#" color="secondary" noWrap>
                &nbsp;términos y condiciones
              </Link>
            </React.Fragment>
          }
        ></FormControlLabel>
        <FormHelperText>
          {formik.touched.agreeTerms && formik.errors.agreeTerms}
        </FormHelperText>
      </FormControl>
      <Box mt={3} mb={1}>
        <RoundButton type="submit" loading={loggingIn} fullWidth>
          Regístrate
        </RoundButton>
      </Box>

      {!loggingIn && errorMessage ? (
        <Alert severity="error">{errorMessage}</Alert>
      ) : null}
      <SocialSignin />
    </form>
  );
};
