import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Drawer from '@material-ui/core/Drawer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';

import { CartsApi } from '../../api/carts-api';
import { LoadingWrapper } from '../../components/loading-wrapper';
import { Placeholder } from '../../components/placeholder';
import { UserAvatar } from '../../components/user-avatar';
import { Cart } from '../../interfaces/models/cart';
import { Messages } from '../../interfaces/responses/response';
import { CartPostItem } from './cart-post-item';
import { formatCurrency } from '../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { cartActions } from '../../actions/cart.actions';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: '100%',
    maxWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.default,
  },
}));

export const CartsIndex: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [carts, setCarts] = useState<Cart[]>([]);
  const [errors, setErrors] = useState<Messages>();

  const showCart = useSelector<RootState, boolean>(
    (state) => state.cart.showCart || false,
  );
  const itemId = useSelector<RootState, number | undefined>(
    (state) => state.cart.itemId,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      const res = await CartsApi.index();
      if (res.errors) {
        setErrors(res.errors);
        return setLoading(false);
      }
      setCarts(res.carts);
      setLoading(false);
    };
    loading && fetch();
  }, [loading]);

  useEffect(() => {
    const add = async () => {
      const added = carts.some((c) =>
        c.details.some((d) => d.postId === itemId),
      );
      if (!added) {
        const res = await CartsApi.addItem(Number(itemId));
        if (res.errors) {
          setErrors(res.errors);
          return setLoading(false);
        }
        setCarts(res.carts);
      }
      dispatch(cartActions.toggleCart(true));
    };
    if (!loading && itemId) {
      add();
    }
  }, [loading, itemId, carts]);

  const removeItem = async (postId: number) => {
    const res = await CartsApi.removeItem(postId);
    if (res.errors) {
      setErrors(res.errors);
      return setLoading(false);
    }
    setCarts(res.carts);
  };

  const handleClose = () => {
    dispatch(cartActions.toggleCart(false));
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={showCart}
      onClose={handleClose}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <LoadingWrapper loading={loading} errors={errors}>
        <Box
          flexShrink="0"
          padding="0 12px"
          height={{ xs: 56, md: 72 }}
          display="flex"
          alignItems="center"
        >
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box overflow="auto">
          {carts?.length ? (
            carts.map((cart) => (
              <Box key={cart.id} marginBottom="8px">
                <Card elevation={0}>
                  <CardContent>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="start"
                    >
                      {cart?.seller && <UserAvatar user={cart?.seller} />}
                      <Box textAlign="right" flexGrow={1}>
                        <Button
                          variant="contained"
                          color="secondary"
                          component={Link}
                          to={`/carts/${cart.id}/checkout`}
                          onClick={handleClose}
                        >
                          Ir a pagar
                        </Button>
                      </Box>
                    </Box>
                    <Box my={2}>
                      {cart.details.map((item, i) => (
                        <CartPostItem
                          key={item.postId}
                          item={item}
                          guttterTop={i > 0}
                          onRemove={removeItem}
                        />
                      ))}
                    </Box>
                    <Box pt={1}>
                      {cart.fees?.map((fee) => (
                        <Box
                          display="flex"
                          alignItems="space-between"
                          mb={1}
                          key={fee.name}
                        >
                          <Box flexGrow={1}>{fee.name}</Box>
                          <Box>{formatCurrency(fee.amount)}</Box>
                        </Box>
                      ))}
                      <Box display="flex" alignItems="space-between">
                        <Box flexGrow={1}>Total</Box>
                        <Box style={{ fontWeight: 'bold' }}>
                          {formatCurrency(cart.total)}
                        </Box>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            ))
          ) : (
            <Placeholder
              picture="carts"
              title="Tu carrito esta vacío"
              body="Todavía no tienes prendas en tu carrito."
            />
          )}
        </Box>
      </LoadingWrapper>
    </Drawer>
  );
};
