import { OrderDetail } from '../../../../interfaces/models/oders/order-detail';

export const getActiveModal = (modalState: any): any => {
  const el: any = Object.keys(modalState).find(
    (modal: string) => modalState[modal] === true,
  );
  if (el !== undefined) {
    return el;
  }
  return null;
};

export const checkOpenModal = (modalState: any): boolean => {
  return Boolean(
    Object.keys(modalState).some((modal: string) => modalState[modal] === true),
  );
};

export const getModalTitle = (activeModal: string): string => {
  switch (activeModal) {
    case 'rate':
      return 'Calificar producto';
    case 'refund':
      return 'Devolver producto';
    case 'confirm':
      return 'Confirmar orden';
    case 'problem':
      return 'Reportar un problema de este producto';
    case 'help':
      return 'Solicitar ayuda';
    default:
      return '';
  }
};

export const getSaleFinalPrices = (
  order: OrderDetail | undefined,
  isSale: any,
  rejects: any[],
) => {
  if (isSale && order && order.details && order.details.length > 0) {
    return {
      price: order.details.reduce((ac, cu) => {
        if (!rejects.some((x) => x.id === cu.id) && cu.status !== 'rejected') {
          return ac + cu.price;
        } else {
          return ac;
        }
      }, 0),
      sellerFee: order.details.reduce((ac, cu) => {
        if (!rejects.some((x) => x.id === cu.id) && cu.status !== 'rejected') {
          return ac + cu.sellerFee;
        } else {
          return ac;
        }
      }, 0),
      payable: order.details.reduce((ac, cu) => {
        if (!rejects.some((x) => x.id === cu.id) && cu.status !== 'rejected') {
          return ac + cu.payable;
        } else {
          return ac;
        }
      }, 0),
    };
  }
  return { price: 0, sellerFee: 0, payable: 0 };
};
