import { createTheme } from '@material-ui/core/styles';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

const defaultTheme = createTheme();

const fontFamily = 'Open Sans, Segoe UI, Tahoma, sans-serif';
const accentFontFamily = 'Nunito, sans-serif';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#5C8CED',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ED78B8',
      dark: '#FB469E',
      contrastText: '#ffffff',
    },
    info: {
      main: '#4E4E7D',
      light: '#727C8E',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: fontFamily,
    h1: {
      fontFamily: accentFontFamily,
    },
    h2: {
      fontFamily: accentFontFamily,
    },
    h3: {
      fontFamily: accentFontFamily,
    },
    h4: {
      fontFamily: accentFontFamily,
    },
    h5: {
      fontFamily: accentFontFamily,
    },
    h6: {
      fontFamily: accentFontFamily,
    },
  },
  props: {
    MuiSelect: {
      IconComponent: KeyboardArrowDown,
    },
    MuiButton: {
      disableElevation: true,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: accentFontFamily,
        textTransform: 'none',
      },
    },
    MuiTab: {
      textColorPrimary: {
        textTransform: 'none',
      },
    },
    MuiTextField: {
      root: {
        width: '100%',
        margin: defaultTheme.spacing(1, 0),
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiSelect: {
      icon: {
        color: defaultTheme.palette.secondary.main,
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: 'transparent',
      },
    },
  },
});
