import { PropTypes } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { Link } from 'react-router-dom';

interface Pictures {
  order: string;
}

interface DialogLink {
  href: string;
  text: string;
  color?: PropTypes.Color;
}

const pictures: Pictures = {
  order: '/img/modals/order-ontheway.svg',
};

interface DialogPictureProps {
  picture: keyof Pictures;
  title: string;
  text: string;
  buttons?: DialogLink[];
}
export const DialogPicture: React.FC<DialogPictureProps> = ({
  picture,
  title,
  text,
  buttons = [],
}) => {
  return (
    <Dialog fullWidth={true} maxWidth="xs" open={true}>
      <Box textAlign="center">
        <DialogTitle>{title.toUpperCase()}</DialogTitle>
        <DialogContent>
          <Box marginBottom={1}>
            <img
              src={pictures[picture]}
              alt={title}
              style={{ maxWidth: 'min(calc(100% - 40px, 160px))' }}
            />
          </Box>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
      </Box>
      <DialogActions>
        {buttons.map((b) => (
          <Button
            key={b.href}
            color={b.color || 'secondary'}
            component={Link}
            to={b.href}
          >
            {b.text}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};
