import React, { FunctionComponent, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';

import { AppToolbar } from '../../../components/app-toolbar';
import { RoundButton } from '../../../components/round-button';
import { LoadingWrapper } from '../../../components/loading-wrapper';
import { BankAccountsApi } from '../../../api/bank-accounts-api';
import { BankAccountRequest } from '../../../interfaces/requests/bank-account/bank-account-request';
import { Messages } from '../../../interfaces/responses/response';
import { BankAccount } from '../../../interfaces/models/bank-account';
import { AlertMessage } from '../../../components/alert-message';

interface BankAccountEditFormProps extends FormikProps<BankAccountRequest> {
  id: number;
}

const getValues = (accout?: BankAccount): BankAccountRequest => ({
  bank: accout?.bank || '',
  accountNumber: accout?.accountNumber || '',
  standarizedNumber: accout?.standarizedNumber || '',
  holderName: accout?.holderName || '',
});

const BankAccountEditForm: FunctionComponent<BankAccountEditFormProps> = ({
  id,
  isSubmitting,
  setValues,
  ...formik
}) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Messages>();

  useEffect(() => {
    const fetchAccount = async () => {
      const { errors: err, account } = await BankAccountsApi.show(id);
      err && setErrors(err);
      setValues(getValues(account));
      setLoading(false);
    };
    loading && fetchAccount();
  }, [loading, id, setValues]);

  const getErrorProps = (field: keyof BankAccountRequest) => ({
    error: formik.touched[field] && !!formik.errors[field],
    helperText: formik.touched[field] && formik.errors[field],
  });

  return (
    <React.Fragment>
      <AppToolbar title="Editar datos bancarios" />
      <LoadingWrapper loading={loading} errors={errors}>
        <Box maxWidth={480} py={{ sm: 4 }} mx="auto">
          <Paper>
            <Box px={{ xs: 2, sm: 4 }} py={4}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Banco"
                  name="bank"
                  value={formik.values.bank}
                  onChange={formik.handleChange}
                  {...getErrorProps('bank')}
                  required
                />
                <TextField
                  variant="outlined"
                  type="tel"
                  label="Número de cuenta"
                  name="accountNumber"
                  value={formik.values.accountNumber}
                  onChange={formik.handleChange}
                  {...getErrorProps('accountNumber')}
                  required
                />
                <TextField
                  variant="outlined"
                  type="tel"
                  label="CLABE"
                  name="standarizedNumber"
                  value={formik.values.standarizedNumber}
                  onChange={formik.handleChange}
                  {...getErrorProps('standarizedNumber')}
                  required
                />
                <TextField
                  variant="outlined"
                  type="text"
                  label="Nombre del beneficiario"
                  name="holderName"
                  value={formik.values.holderName}
                  onChange={formik.handleChange}
                  {...getErrorProps('holderName')}
                  required
                />
                {formik.status?.errors && (
                  <AlertMessage messages={formik.status.errors} />
                )}
                {formik.status?.success && (
                  <AlertMessage
                    severity="success"
                    messages={{ '': 'Datos bancarios guardados exitosamente.' }}
                  />
                )}
                <Box mt={2}>
                  <RoundButton type="submit" loading={isSubmitting} fullWidth>
                    Guardar
                  </RoundButton>
                </Box>
              </form>
            </Box>
          </Paper>
        </Box>
      </LoadingWrapper>
    </React.Fragment>
  );
};

const validationSchema = yup.object({
  bank: yup.string().required('Banco es requerido.'),
  accountNumber: yup
    .string()
    .required('Número de cuenta es requerido.')
    .matches(/^\d+$/, 'Número de cuenta debe contener solo digítos.'),
  standarizedNumber: yup
    .string()
    .required('CLABE requerida.')
    .matches(/^\d{18}$/, 'CLABE debe ser de 18 digítos.'),
  holderName: yup.string().required('Nombre del beneficiario es requerido.'),
});

export const BankAccountEdit = withFormik<{ id: number }, BankAccountRequest>({
  validationSchema,
  mapPropsToErrors: () => getValues(),
  handleSubmit: async (values, { setSubmitting, setStatus, props: { id } }) => {
    setSubmitting(true);
    setStatus({ errors: undefined });
    const { errors } = await BankAccountsApi.update(id, values);
    setStatus({ errors, success: !errors });
  },
})(BankAccountEditForm);
