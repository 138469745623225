import React from 'react';
import { Box, Typography, makeStyles, Grid } from '@material-ui/core';
import { formatDate } from '../../../utils/helpers';
import { OrderDetailDefaultProps } from '../../../interfaces/models/oders/order-detail';

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.info.main,
    alignSelf: 'center',
    textAlign: 'center',
  },
  listHeader: {
    color: theme.palette.info.main,
    fontSize: 14,
  },
  listBody: {
    color: theme.palette.info.main,
    fontSize: 13,
    fontWeight: 500,
    textTransform: 'capitalize',
  },
}));

export const OrderDetailHeader: React.FC<OrderDetailDefaultProps> = ({
  order,
  isSale,
}) => {
  const classes = useStyles();
  return (
    <Box marginBottom={2} p={4} bgcolor="background.paper">
      {order && (
        <Grid container direction="column" justify="space-between">
          <Typography
            variant="h5"
            className={classes.header}
          >{`PEDIDO ${order.folio}`}</Typography>
          {order.createdAt && (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography className={classes.listHeader}>{'Fecha:'}</Typography>
              <Typography className={classes.listBody}>
                {formatDate(order.createdAt)}
              </Typography>
            </Grid>
          )}
          {order.shipment && (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography className={classes.listHeader}>
                {order.return
                  ? 'Número de guía de devolución'
                  : 'Número de guía'}
              </Typography>
              <Typography className={classes.listBody}>
                {order.shipment.trackingNumber || '---'}
              </Typography>
            </Grid>
          )}
          {order.shipment && order.shipment.carrierName && (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography className={classes.listHeader}>
                {'Paquetería'}
              </Typography>
              <Typography className={classes.listBody}>
                {order.shipment.carrierName}
              </Typography>
            </Grid>
          )}
          {isSale && (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography className={classes.listHeader}>
                {'Comprador:'}
              </Typography>
              <Typography className={classes.listBody}>
                {`${order.user.firstName} ${order.user.lastName}`}
              </Typography>
            </Grid>
          )}
          {order.seller && (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography className={classes.listHeader}>
                {'Vendedor'}
              </Typography>
              <Typography className={classes.listBody}>
                {`${order.seller.firstName || ''} ${
                  order.seller.lastName || ''
                }`}
              </Typography>
            </Grid>
          )}
          {!isSale && order.sellerLocation && (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography className={classes.listHeader}>
                {'Localización del vendedor'}
              </Typography>
              <Typography className={classes.listBody}>
                {order.sellerLocation || ''}
              </Typography>
            </Grid>
          )}
          {isSale && order.userLocation && (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography className={classes.listHeader}>
                {'Localización del comprador'}
              </Typography>
              <Typography className={classes.listBody}>
                {order.userLocation || ''}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};
