import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import StepLabel from '@material-ui/core/StepLabel';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import { useFormik } from 'formik';

import { StepAddress } from './step-address';
import { StepShipping } from './step-shipping';
import { StepPayment, PaymentChoice } from './step-payment';
import { CartPostItem } from '../cart-post-item';
import { BorderedList } from '../../../components/bordered-list';
import { LoadingWrapper } from '../../../components/loading-wrapper';
import { UserAvatar } from '../../../components/user-avatar';
import { RoundButton } from '../../../components/round-button';
import { CartsApi } from '../../../api/carts-api';
import { OpenPayService } from '../../../services/openpay-service';
import { Address } from '../../../interfaces/models/address';
import { Cart } from '../../../interfaces/models/cart';
import { PayProvider, PayMethod } from '../../../interfaces/models/oders';
import { ShippingQuote } from '../../../interfaces/models/shipping-quote';
import { OrdersRequest } from '../../../interfaces/requests/orders';
import { Messages } from '../../../interfaces/responses/response';
import { formatCurrency } from '../../../utils/helpers';
import { AlertMessage } from '../../../components/alert-message';
import { OrdersApi } from '../../../api/orders-api';
import { DialogPicture } from '../../../components/dialog-picture';
import config from '../../../config';

interface CartsCheckoutProps {
  id: number;
}
export const CartsCheckout: React.FC<CartsCheckoutProps> = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Messages>();
  const [cart, setCart] = useState<Cart>();

  const [activeStep, setActiveStep] = useState(0);
  const [address, setAddress] = useState<Address>();
  const [quote, setQuote] = useState<ShippingQuote>();
  const [payment, setPayment] = useState<PaymentChoice>({
    paymentProvider: PayProvider.OPENPAY,
    paymentMethod: PayMethod.CARD,
  });

  const [saving, setSaving] = useState(false);
  const [savingErrors, setSavingErrors] = useState<Messages>();
  const [orderId, setOrderId] = useState<number>();

  useEffect(() => {
    setLoading(true);
  }, [id]);

  useEffect(() => {
    const fetch = async () => {
      setErrors(undefined);
      setCart(undefined);
      const { cart: item, errors } = await CartsApi.show(id);
      setErrors(errors);
      if (!errors && item) {
        setCart(item);
      }
      setLoading(false);
    };
    loading && fetch();
  }, [loading, id]);

  const initialValues: OrdersRequest = useMemo(
    () => ({
      paymentProvider: payment.paymentProvider,
      paymentMethod: payment.paymentMethod,
      paymentId:
        (payment.paymentProvider === PayProvider.OPENPAY &&
          payment.paymentMethod === PayMethod.CARD &&
          payment.card?.id) ||
        '',
      amount: (cart?.total || 0) + (quote?.price || 0),
      wallet: 0,
      key: OpenPayService.getDeviceId(),
      addressId: address?.id.toString() || '',
      shipments: [
        {
          cartId: id,
          serviceId: quote?.serviceId || '',
          carrierId: quote?.carrierId?.toString() || '',
          price: quote?.price || 0,
        },
      ],
    }),
    [id, cart, payment, address, quote],
  );

  const { setFieldValue, handleChange, ...formik } = useFormik<OrdersRequest>({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (value) => {
      setSaving(true);
      setSavingErrors(undefined);
      const { errors, order } = await OrdersApi.store(value);
      setSavingErrors(errors);
      setSaving(false);
      if (!errors && order) {
        setOrderId(order.id);
      }
    },
  });

  const toPrev = () => {
    setActiveStep(Math.max(activeStep - 1, 0));
  };

  const toNext = () => {
    setActiveStep(Math.min(activeStep + 1, 3));
  };

  const onAddressSelect = useCallback((item?: Address) => {
    setAddress(item);
  }, []);

  const onQuoteSelected = useCallback((item?: ShippingQuote) => {
    setQuote(item);
  }, []);

  const onPaymentSelected = useCallback((item: PaymentChoice) => {
    setPayment(item);
  }, []);

  const getStepContent = (step: number) => {
    if (!cart) {
      return;
    }
    switch (step) {
      case 0:
        return (
          <StepAddress
            value={address}
            onChange={onAddressSelect}
            onNext={toNext}
          />
        );
      case 1:
        return (
          <StepShipping
            cartId={id}
            addressId={formik.values.addressId}
            value={quote}
            onChange={onQuoteSelected}
            onPrev={toPrev}
            onNext={toNext}
          />
        );
      case 2:
        return (
          <StepPayment
            value={payment}
            onChange={onPaymentSelected}
            onPrev={toPrev}
            onNext={toNext}
          />
        );
      default:
        return null;
    }
  };

  const renderSeller = () => {
    if (!cart) {
      return;
    }
    return (
      <Paper variant="outlined">
        <Box p={2}>
          <Typography variant="caption">
            <Box fontWeight={600} mb={1}>
              Tu compra con
            </Box>
          </Typography>
          {cart.seller && <UserAvatar user={cart.seller} />}
        </Box>
      </Paper>
    );
  };

  const renderDetails = () => {
    if (!cart) {
      return;
    }
    return (
      <Box mt={2}>
        {cart?.details.map((item, i) => (
          <CartPostItem key={item.postId} item={item} guttterTop={i > 0} />
        ))}
      </Box>
    );
  };

  const renderAmounts = () => {
    if (!cart) {
      return;
    }
    return (
      <Box mt={2}>
        <Paper variant="outlined">
          <Box p={2}>
            {cart.fees?.map((fee) => (
              <Box
                display="flex"
                alignItems="space-between"
                mb={1}
                key={fee.name}
              >
                <Box flexGrow={1}>{fee.name}</Box>
                <Box>{formatCurrency(fee.amount)}</Box>
              </Box>
            ))}
            <Box display="flex" alignItems="space-between" mb={1}>
              <Box flexGrow={1}>Envío</Box>
              <Box>
                {formik.values.shipments?.[0].price
                  ? formatCurrency(formik.values.shipments[0].price)
                  : '-'}
              </Box>
            </Box>
            <Box display="flex" alignItems="space-between">
              <Box flexGrow={1}>Total</Box>
              <Box style={{ fontWeight: 'bold' }}>
                {formatCurrency(formik.values.amount)}
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  };

  const renderSumary = (step: 0 | 1 | 2, text?: string) => {
    const titles = {
      0: 'Dirección de entrega',
      1: 'Detalle del envío',
      2: 'Detalle del pago',
    };
    return (
      <BorderedList>
        <ListItemText primary={titles[step]} secondary={text} />
        <ListItemSecondaryAction>
          <Button
            color="primary"
            disabled={saving}
            onClick={() => setActiveStep(step)}
          >
            Editar
          </Button>
        </ListItemSecondaryAction>
      </BorderedList>
    );
  };

  const shippingSummary = (): string => {
    if (!quote) {
      return '';
    }
    return [quote.carrierName, formatCurrency(quote.price)].join(' | ');
  };

  const paymentSummary = (): string => {
    let label = '';
    if (payment.paymentProvider === PayProvider.OPENPAY) {
      label = payment.paymentMethod === PayMethod.CARD ? 'Tarjeta' : 'Efectivo';
    }
    if (payment.paymentProvider === PayProvider.PAYPAL) {
      label = 'Paypal';
    }
    if (
      payment.paymentProvider === PayProvider.OPENPAY &&
      payment.paymentMethod === PayMethod.CARD &&
      payment.card
    ) {
      label += ` ***${payment.card.lastFour}`;
    }
    return label;
  };

  return (
    <LoadingWrapper loading={loading} errors={errors}>
      {cart && (
        <Container maxWidth="md">
          <Box py={4}>
            <Stepper activeStep={activeStep} alternativeLabel>
              <Step>
                <StepLabel>Domicilio</StepLabel>
              </Step>
              <Step>
                <StepLabel>Envío</StepLabel>
              </Step>
              <Step>
                <StepLabel>Pago</StepLabel>
              </Step>
              <Step>
                <StepLabel>Confirmar</StepLabel>
              </Step>
            </Stepper>
            <Box py={2}>
              {activeStep !== 3 ? (
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6} style={{ position: 'relative' }}>
                    {getStepContent(activeStep)}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {renderSeller()}
                    {renderDetails()}
                    {renderAmounts()}
                  </Grid>
                </Grid>
              ) : (
                <form onSubmit={formik.handleSubmit}>
                  <Container maxWidth="sm" disableGutters>
                    {renderSeller()}
                    {renderDetails()}
                    <Box mt={2}>
                      <List>
                        {address && renderSumary(0, address.formatted)}
                        {quote && renderSumary(1, shippingSummary())}
                        {renderSumary(2, paymentSummary())}
                      </List>
                    </Box>
                    {renderAmounts()}
                    <Box mt={3}>
                      {savingErrors && <AlertMessage messages={savingErrors} />}
                      {payment.paymentProvider === PayProvider.PAYPAL &&
                      !saving ? (
                        <PaypalExpressBtn
                          env={config.paypal.environment}
                          client={{
                            [config.paypal.environment]: config.paypal.clientId,
                          }}
                          currency={'MXN'}
                          total={formik.values.amount}
                          style={{ size: 'responsive', tagline: false }}
                          paymentOptions={{ intent: 'authorize' }}
                          shipping={1}
                          onSuccess={({ paymentID }: { paymentID: string }) => {
                            setFieldValue('paymentId', paymentID);
                            formik.submitForm();
                          }}
                        />
                      ) : (
                        <RoundButton fullWidth loading={saving} type="submit">
                          Pagar
                        </RoundButton>
                      )}
                    </Box>
                  </Container>
                </form>
              )}
            </Box>
          </Box>
          {orderId && (
            <DialogPicture
              picture="order"
              title="Orden completada"
              text="¡Gracias por tu compra! Tu orden se ha realizado con éxito"
              buttons={[
                {
                  href: `/purchases/${orderId}`,
                  text: 'Ir a mis compras',
                  color: 'primary',
                },
                {
                  href: '/',
                  text: 'Seguir comprando',
                },
              ]}
            />
          )}
        </Container>
      )}
    </LoadingWrapper>
  );
};
