import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import { PostItem } from '../../../interfaces/models/post-item';

interface RelatedItemsProps {
  items: PostItem[];
}

export const RelatedItems: React.FC<RelatedItemsProps> = ({ items }) => {
  return (
    <React.Fragment>
      <Box mb={1}>
        <Typography variant="caption">
          <Box fontWeight={600} component="span">
            Te puede interesar
          </Box>
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {items.slice(0, 3).map((rel) => (
          <Grid
            item
            xs={4}
            key={rel.id}
            component={Link}
            to={`/posts/${rel.id}`}
          >
            <Box
              border={1}
              borderRadius="borderRadius"
              borderColor="rgba(0, 0, 0, 0.26)"
              style={{
                padding: '50% 0',
                backgroundImage: `url(${rel.pictureUrl})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};
