import React, { FunctionComponent, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

interface TogglePasswordProps {
  label: string;
  name: string;
  value: string;
  error: boolean | undefined;
  helperText: React.ReactNode;
  onChange: React.ChangeEventHandler;
}

export const TogglePasswordInput: FunctionComponent<TogglePasswordProps> = ({
  label,
  name,
  value,
  error,
  helperText,
  onChange,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const endAdornment = (
    <InputAdornment position="end">
      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
  return (
    <TextField
      variant="outlined"
      type={showPassword ? 'text' : 'password'}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      InputProps={{ endAdornment }}
    />
  );
};
