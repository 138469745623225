import { Dispatch } from 'redux';
import { AuthService } from '../services';
import { authActions } from './auth.actions';
import { CartConstants } from './_constants';

export interface CartAction {
  type: CartConstants;
  visible?: boolean;
  itemId?: number;
}

function toggleCart(visible?: boolean): CartAction {
  return {
    type: CartConstants.CART_VISIBILITY_TOGGLE,
    visible,
  };
}

function addItem(itemId: number) {
  const action = {
    type: CartConstants.CART_ADD_ITEM,
    itemId,
  };
  if (AuthService.isAuth()) {
    return action;
  }
  return (dispatch: Dispatch) => {
    dispatch(authActions.requestAuth(action));
  };
}

export const cartActions = {
  toggleCart,
  addItem,
};
