import React, { FC } from 'react';
import clsx from 'clsx';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  apple: {
    backgroundColor: '#111',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#000',
      color: '#fff',
    },
  },
  facebook: {
    backgroundColor: '#39579a',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#39579a',
      color: '#fff',
    },
  },
  google: {
    border: '1px solid #8f8f8f',
    backgroundColor: '#fff',
    color: '#8f8f8f',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#8f8f8f',
    },
  },
});

interface SocialButton extends ButtonProps {
  loading?: boolean;
  disabled?: boolean;
}
export const AppleButton: FC<SocialButton> = ({
  loading = false,
  disabled = false,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      disableElevation
      className={clsx(classes.root, classes.apple)}
      disabled={disabled}
      {...props}
    >
      {loading ? <CircularProgress size={24} /> : ' Iniciar sesión con Apple'}
    </Button>
  );
};

export const FacebookButton: FC<SocialButton> = ({
  loading = false,
  disabled = false,
}) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      disableElevation
      className={clsx(classes.root, classes.facebook)}
      disabled={disabled}
    >
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <img src="/img/social/facebook.svg" alt="" height="16" /> &nbsp;&nbsp;
          Facebook
        </>
      )}
    </Button>
  );
};

export const GoogleButton: FC<SocialButton> = ({
  loading = false,
  disabled = false,
}) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      disableElevation
      className={clsx(classes.root, classes.google)}
      disabled={disabled}
    >
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <img src="/img/social/google.svg" alt="" height="16" /> &nbsp;&nbsp;
          Google
        </>
      )}
    </Button>
  );
};
