import { Api } from './api-base';
import { Attribute } from '../interfaces/models/attribute';
import { Brand } from '../interfaces/models/brand';
import { Category } from '../interfaces/models/category';
import { Post } from '../interfaces/models/post';
import { PostItem } from '../interfaces/models/post-item';
import { PostFilters } from '../interfaces/requests/posts/posts-filters';
import { PostsReportRequest } from '../interfaces/requests/posts/posts-report-request';
import { PostsRequest } from '../interfaces/requests/posts/posts-request';
import { Pagination } from '../interfaces/responses/pagination';
import { PostsIndexResponse } from '../interfaces/responses/posts/posts-index-response';
import { HasErrors, Response } from '../interfaces/responses/response';
import { objToQueryStr } from '../utils/query-params';

interface IndexAttributes extends HasErrors {
  attributes: Attribute[];
}

interface IndexCatagories extends HasErrors {
  categories: Category[];
}

interface IndexBrands extends HasErrors {
  brands: Brand[];
}

export interface ShowPost extends HasErrors {
  post?: Post;
}

export interface IndexPosts extends HasErrors {
  posts: PostItem[];
  pagination?: Pagination;
}

export interface ToggleLike extends HasErrors {
  count: number;
}

export const PostsApi = {
  attributes: async (): Promise<IndexAttributes> => {
    const { errors, response } = await Api.get<Response<Attribute[]>>(
      '/attributes',
    );
    return { errors, attributes: response?.data || [] };
  },
  categories: async (): Promise<IndexCatagories> => {
    const { errors, response } = await Api.get<Response<Category[]>>(
      '/categories',
    );
    return { errors, categories: response?.data || [] };
  },
  brands: async (): Promise<IndexBrands> => {
    const { errors, response } = await Api.get<Response<Brand[]>>('/brands', {
      params: { perPage: 999 },
    });
    return { errors, brands: response?.data || [] };
  },
  index: async (params: PostFilters = {}): Promise<IndexPosts> => {
    const queryStr = objToQueryStr(params);
    const { errors, response } = await Api.get<PostsIndexResponse>(
      `/posts?${queryStr}`,
    );
    return { errors, posts: response?.data || [], pagination: response?.meta };
  },
  show: async (id: number): Promise<ShowPost> => {
    const { errors, response } = await Api.get<Response<Post>>(`/posts/${id}`);
    return { errors, post: response?.data };
  },
  store: async (input: PostsRequest): Promise<ShowPost> => {
    const { errors, response } = await Api.post<Response<Post>>('posts', input);
    return { errors, post: response?.data };
  },
  update: async (id: number, input: PostsRequest): Promise<ShowPost> => {
    const { errors, response } = await Api.put<Response<Post>>(
      `/posts/${id}`,
      input,
    );
    return { errors, post: response?.data };
  },
  related: async (): Promise<IndexPosts> => {
    const { errors, response } = await Api.get<PostsIndexResponse>('/posts', {
      params: { perPage: 3 },
    });
    return { errors, posts: response?.data || [], pagination: response?.meta };
  },
  report: async (input: PostsReportRequest): Promise<HasErrors> => {
    const { errors } = await Api.post<void>('/post-reports', input);
    return { errors };
  },
  toggleLike: async (id: number): Promise<ToggleLike> => {
    const { errors, response } = await Api.put<Response<{ favorites: number }>>(
      `/posts/${id}/toggle-favorite`,
      {},
    );
    return { errors, count: response?.data?.favorites || 0 };
  },
  delete: async (id: number): Promise<HasErrors> => {
    const { errors } = await Api.delete<Response<void>>(`/posts/${id}`);
    return { errors };
  },
};
