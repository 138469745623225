import { Brand } from './brand';
import { PostAttribute } from './post-attribute';
import { UserItem } from './user-item';

export interface IdName {
  id?: number;
  name: string;
}

export interface PostPictures {
  front: string;
  back: string;
  tag: string;
  extra1: string;
  extra2: string;
  extra3: string;
}

export enum PostStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  ADJUSTMENT = 'adjustment',
}

export interface Post {
  id: number;
  name: string;
  description: string;
  user: UserItem;
  address: IdName;
  category: IdName;
  brand: Brand;
  attributes: PostAttribute[];
  location: string;
  originalPrice: number;
  price: number;
  pictures: PostPictures;
  quantityAvailable: number;
  isActive: boolean;
  isVisible: boolean;
  isCensored: boolean;
  isSalable: boolean;
  isBargainable: boolean;
  likeToUser: boolean;
  commentsCount: number;
  favoritesCount: number;
  status: PostStatus;
  createdAt: string;
}

export const PostStatusLabel = {
  [PostStatus.PENDING]: 'Publicación en revisión.',
  [PostStatus.ADJUSTMENT]: 'Publicación necesita correción',
  [PostStatus.APPROVED]: 'Publicación aceptada',
  [PostStatus.REJECTED]: 'Publicación rechazada',
};

export const PostStatusColor = (status: PostStatus) => {
  if (status === PostStatus.REJECTED) {
    return 'error';
  }
  if (status === PostStatus.APPROVED) {
    return 'success';
  }
  if (status === PostStatus.ADJUSTMENT) {
    return 'warning';
  }
  return 'info';
};
