import { Api } from './api-base';
import { Response, Messages } from '../interfaces/responses/response';
import { SaleItem } from '../interfaces/models/oders/sale-item';
import { PurchaseItem } from '../interfaces/models/oders/purchase-item';
import {
  OrderDetail,
  ReportValue,
  ReturnType,
  RatePostType,
} from '../interfaces/models/oders/order-detail';
import { OrdersRequest } from '../interfaces/requests/orders';

interface HasErrors {
  errors?: Messages;
}
export interface GetPurchases extends HasErrors {
  purchases: PurchaseItem[];
}
export interface GetSales extends HasErrors {
  sales: SaleItem[];
}
export interface GetOrderDetail extends HasErrors {
  order?: OrderDetail;
}

export const OrdersApi = {
  getPurchases: async (): Promise<GetPurchases> => {
    const { errors, response } = await Api.get<Response<PurchaseItem[]>>(
      '/orders',
      { params: { role: 'buyer', perPage: 24 } },
    );
    return { errors, purchases: response?.data || [] };
  },
  getSales: async (): Promise<GetSales> => {
    const { errors, response } = await Api.get<Response<SaleItem[]>>(
      '/orders',
      { params: { role: 'seller', perPage: 24 } },
    );
    return { errors, sales: response?.data || [] };
  },
  getOrderDetail: async (id: number, role: string): Promise<GetOrderDetail> => {
    const { errors, response } = await Api.get<Response<OrderDetail>>(
      `/orders/${id}/`,
      { params: { role } },
    );
    return { errors, order: response?.data };
  },
  store: async (input: OrdersRequest) => {
    const { errors, response } = await Api.post<Response<PurchaseItem[]>>(
      '/orders',
      input,
    );
    return { errors, order: response?.data?.[0] };
  },
  setConfirm: async (orderId: number, rejects: ReportValue[]) => {
    const { errors, response } = await Api.post<Response<GetOrderDetail>>(
      `/orders/${orderId}/confirm`,
      { rejects },
    );
    return { errors, order: response?.data };
  },
  setRefund: async (orderId: number, orderDetails: Array<ReturnType>) => {
    const { errors, response } = await Api.post<Response<GetOrderDetail>>(
      '/returns',
      { orderId, returnDetails: orderDetails },
    );
    return { errors, order: response?.data };
  },
  setRate: async (
    info: RatePostType,
    orderId: number,
    orderDetailId: number,
  ) => {
    const { errors, response } = await Api.post<Response<GetOrderDetail>>(
      `/orders/${orderId}/details/${orderDetailId}/rate`,
      { ...info },
    );
    return { errors, order: response?.data };
  },
};
