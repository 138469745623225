import React, { FunctionComponent } from 'react';
import { Box, Container } from '@material-ui/core';
import { __html } from './html';
const template = { __html: __html };

export const Terms: FunctionComponent = () => {
  return (
    <Box bgcolor="background.paper">
      <Container maxWidth="sm">
        <div dangerouslySetInnerHTML={template} />
      </Container>
    </Box>
  );
};
