import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { AppToolbar } from '../../../components/app-toolbar';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { LoadingWrapper } from '../../../components/loading-wrapper';
import { FaqsResponse, FaqsApi } from '../../../api/faqs-api';
import { Faqs } from '../../../interfaces/models/faqs';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    header: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      border: '1px solid',
      borderColor: theme.palette.divider,
      boxShadow: 'none',
    },
  }),
);

export const FaqsList = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<FaqsResponse>();
  useEffect(() => {
    const initData = async () => {
      setResponse(await FaqsApi.getFaqs());
      setLoading(false);
    };
    loading && initData();
  }, [loading]);

  return (
    <React.Fragment>
      <AppToolbar title="Preguntas frecuentes" />
      <LoadingWrapper loading={loading} errors={response?.errors}>
        <Box width={480} py={4} alignSelf="center">
          <div className={classes.root}>
            {!loading &&
              response &&
              response.faqs &&
              response.faqs.map((faq: Faqs) => (
                <Accordion key={`${faq.id}`} className={classes.header}>
                  <AccordionSummary
                    expandIcon={<ExpandMore color={'secondary'} />}
                    id={`${faq.id}`}
                  >
                    <Typography className={classes.heading}>
                      {faq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{faq.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        </Box>
      </LoadingWrapper>
    </React.Fragment>
  );
};
