import React, { useCallback, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import CreditCard from '@material-ui/icons/CreditCard';
import Money from '@material-ui/icons/Money';

import { ReactComponent as PaypalLogo } from '../../../assets/paypal.svg';

import { OutlinedTab } from '../../../components/outlined-tab';
import { RoundButton } from '../../../components/round-button';
import { PaymentMethodsList } from '../../user/payment-methods/payment-methods-list';
import { PaymentMethodsEdit } from '../../user/payment-methods/payment-methods-edit';
import { PayMethod, PayProvider } from '../../../interfaces/models/oders';
import { Card } from '../../../interfaces/models/card';
import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';

export enum PaymentTabs {
  CARD,
  CASH,
  PAYPAL,
}

export enum PaymentView {
  LIST,
  EDIT,
}

export interface PaymentChoice {
  paymentProvider: PayProvider;
  paymentMethod: PayMethod;
  card?: Card;
}

interface StepPaymentProps {
  value: PaymentChoice;
  onChange: (payment: PaymentChoice) => void;
  onPrev: () => void;
  onNext: () => void;
}

export const StepPayment: React.FC<StepPaymentProps> = ({
  value,
  onChange,
  onPrev,
  onNext,
}) => {
  const [tab, setTab] = useState<number>(-1);
  const [view, setView] = useState(PaymentView.LIST);

  const emitChange = useCallback(
    (item: PaymentChoice) => {
      onChange(item);
    },
    [onChange],
  );

  useEffect(() => {
    if (
      value.paymentProvider === PayProvider.OPENPAY &&
      value.paymentMethod === PayMethod.CARD
    ) {
      setTab(PaymentTabs.CARD);
    }
    if (
      value.paymentProvider === PayProvider.OPENPAY &&
      value.paymentMethod === PayMethod.CASH
    ) {
      setTab(PaymentTabs.CASH);
    }
    if (value.paymentProvider === PayProvider.PAYPAL) {
      setTab(PaymentTabs.PAYPAL);
    }
  }, [value]);

  useEffect(() => {
    const data = { ...value };
    switch (tab) {
      case PaymentTabs.CARD:
        data.paymentProvider = PayProvider.OPENPAY;
        data.paymentMethod = PayMethod.CARD;
        break;
      case PaymentTabs.CASH:
        data.paymentProvider = PayProvider.OPENPAY;
        data.paymentMethod = PayMethod.CASH;
        break;
      case PaymentTabs.PAYPAL:
        data.paymentProvider = PayProvider.PAYPAL;
        data.paymentMethod = PayMethod.CARD;
        break;
      default:
        return;
    }
    if (JSON.stringify(value) !== JSON.stringify(data)) {
      emitChange(data);
    }
  }, [tab, emitChange, value]);

  const onTabChange = (index: number) => {
    setTab(index);
  };

  const onCardSelect = (card?: Card) => {
    const data = { ...value };
    data.card = card;
    emitChange(data);
  };

  const onShowCreate = () => {
    setView(PaymentView.EDIT);
  };

  const onShowList = () => {
    setView(PaymentView.LIST);
  };

  const onCardCreated = (card: Card) => {
    onCardSelect(card);
    onShowList();
  };

  const filled = (): boolean => {
    if (value.paymentProvider === PayProvider.PAYPAL) {
      return true;
    }
    if (
      value.paymentProvider === PayProvider.OPENPAY &&
      value.paymentMethod === PayMethod.CASH
    ) {
      return true;
    }
    if (
      value.paymentProvider === PayProvider.OPENPAY &&
      value.paymentMethod === PayMethod.CARD
    ) {
      return !!value.card;
    }
    return false;
  };

  const isCard =
    value.paymentProvider === PayProvider.OPENPAY &&
    value.paymentMethod === PayMethod.CARD;

  const isCash =
    value.paymentProvider === PayProvider.OPENPAY &&
    value.paymentMethod === PayMethod.CASH;

  const isPaypal = value.paymentProvider === PayProvider.PAYPAL;

  const renderContent = () => {
    if (isCard) {
      return view === PaymentView.EDIT ? (
        <PaymentMethodsEdit onCancel={onShowList} onSuccess={onCardCreated} />
      ) : (
        <Box position="relative" minHeight={48}>
          <PaymentMethodsList
            onShowCreate={onShowCreate}
            select={{
              value: value.card,
              onChange: onCardSelect,
            }}
          />
        </Box>
      );
    }
    if (isCash) {
      return (
        <Box textAlign="center">
          <Typography variant="body2" paragraph>
            Paga en efectivo en una de nuestras tiendas afiliadas, una vez hecho
            el pago este se verá reflejado de forma inmediata.
          </Typography>
          <Box marginBottom={1}>
            <img
              src="/img/misc/openpay-stores.png"
              alt="Tiendas de conveniencia"
              style={{ maxWidth: 'min(calc(100% - 40px), 360px)' }}
            />
          </Box>
          <Link href="https://www.openpay.mx/tiendas" target="_blank">
            Ver todas las tiendas afiliadas
          </Link>
        </Box>
      );
    }
    if (isPaypal) {
      return (
        <Box textAlign="center">
          <img
            src="/img/misc/paypal.png"
            alt="Tiendas de conveniencia"
            style={{ maxWidth: 'min(calc(100% - 40px), 240px)' }}
          />
        </Box>
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      {tab >= PaymentTabs.CARD && (
        <Box mb={2}>
          <Tabs
            variant="fullWidth"
            centered
            TabIndicatorProps={{ style: { display: 'none' } }}
            value={tab}
            onChange={(e, t) => onTabChange(t)}
          >
            <OutlinedTab
              icon={<CreditCard />}
              label="Tarjeta"
              value={PaymentTabs.CARD}
            />
            <OutlinedTab
              icon={<Money />}
              label="Efectivo"
              value={PaymentTabs.CASH}
            />
            <OutlinedTab
              icon={<PaypalLogo height={24} fill="currentColor" />}
              label="Paypal"
              value={PaymentTabs.PAYPAL}
            />
          </Tabs>
        </Box>
      )}
      {renderContent()}
      {!(isCard && view === PaymentView.EDIT) && (
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <RoundButton
                fullWidth
                variant="outlined"
                onClick={() => onPrev()}
              >
                Anterior
              </RoundButton>
            </Grid>
            <Grid item xs={6}>
              <RoundButton
                fullWidth
                disabled={!filled()}
                onClick={() => onNext()}
              >
                Siguiente
              </RoundButton>
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
};
