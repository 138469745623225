import React from 'react';
import {
  makeStyles,
  Box,
  Typography,
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { paymentStatusLabel } from '../../../utils/helpers';
import { OrderDetailDefaultProps } from '../../../interfaces/models/oders/order-detail';
import { CloudDownloadOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.info.main,
  },
  body: {
    color: theme.palette.info.light,
    fontSize: 14,
    fontWeight: 500,
  },
  button: {
    color: 'white',
  },
}));

export const OrderDetailsPayment: React.FC<OrderDetailDefaultProps> = ({
  isSale,
  order,
}) => {
  const classes = useStyles();

  if (!isSale && order.payment) {
    return (
      <Grid item xs={12} sm={6}>
        <Box marginBottom={2} p={4} bgcolor="background.paper">
          <Grid container direction="row">
            <Grid container item xs={10} sm={10}>
              <Typography variant="h5" className={classes.header}>
                {'Método de pago'}
              </Typography>
              {order.payment.status && (
                <Typography className={classes.body}>
                  {paymentStatusLabel(order.payment.status)}
                </Typography>
              )}
            </Grid>
            {order.payment.referenceUrl && order.payment.referenceUrl !== '' && (
              <Grid container item xs={2} sm={2} alignItems="center">
                <Tooltip title="Abrir referencia de pago">
                  <IconButton
                    color={'secondary'}
                    size="medium"
                    href={order.payment.referenceUrl}
                    target="_blank"
                  >
                    <CloudDownloadOutlined />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    );
  }
  return null;
};
