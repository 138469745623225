import React from 'react';
import Typography from '@material-ui/core/Typography';

import { DetailItem } from '../../interfaces/models/oders/detail-item';
import {
  formatCurrency,
  orderStatusLabel,
  shipmentStatusLabel,
} from '../../utils/helpers';
import {
  Card,
  makeStyles,
  Theme,
  CardActionArea,
  Grid,
  Box,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    marginBottom: 10,
    alignItems: 'center',
  },
  action: {
    minHeight: '12vh',
    padding: '0.5vh 2vw',
    flexGrow: 1,
    height: '100%',
    alignItems: 'center',
    display: 'flex',
  },
  header: {
    fontSize: 16,
    marginBottom: 0,
    font: 'normal normal bold 16px/20px Nunito',
    color: theme.palette.info.main,
  },
  subheader: {
    fontSize: 14,
    marginBottom: 0,
    marginRight: 10,
    color: theme.palette.info.light,
  },
}));

interface OrderItemProps {
  id: number;
  isSale?: boolean;
  folio: string;
  name: string;
  amount: number;
  status: string;
  shipment?: any;
  details: DetailItem[];
}
export const OrderItem: React.FC<OrderItemProps> = ({
  id,
  isSale,
  folio,
  name,
  amount,
  shipment,
  details,
  status,
}) => {
  const classes = useStyles();

  const renderStatus = (): string => {
    if (status === 'accepted' || status === 'partial') {
      return shipmentStatusLabel(shipment);
    }
    return orderStatusLabel(status);
  };

  return (
    <Card className={classes.root}>
      <CardActionArea
        className={classes.action}
        component={Link}
        to={`/${isSale ? 'sales' : 'purchases'}/${id}`}
      >
        <Grid container>
          <Grid item container xs justify="center" direction="column">
            <Typography gutterBottom className={classes.header}>
              {folio}
            </Typography>
            <Typography gutterBottom className={classes.subheader}>
              {name}
            </Typography>
          </Grid>
          <Grid
            item
            container
            wrap="wrap"
            xs={4}
            direction="row"
            alignItems="center"
          >
            <div className={classes.subheader}>
              ({details.length}) Producto{details.length > 1 ? 's' : ''}
            </div>
            {details.slice(0, 3).map((d) => (
              <Box
                key={d.postId}
                width={40}
                height={40}
                m={0.5}
                style={{
                  backgroundImage: `url(${d.pictureUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            ))}
          </Grid>
          <Grid
            item
            xs={4}
            container
            wrap="wrap"
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Typography variant="h6" color="secondary">
              {formatCurrency(amount)}
            </Typography>
            <Box
              p={1}
              m={1}
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              minWidth={'6vw'}
              bgcolor="primary.main"
              color="primary.contrastText"
            >
              {renderStatus()}
            </Box>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};
