import { Api } from './api-base';
import { Response } from '../interfaces/responses/response';

export const MediaApi = {
  uploadPicture: async (picture: Blob) => {
    const formData = new FormData();
    formData.append('picture', picture);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const { errors, response } = await Api.post<Response<string>>(
      '/media',
      formData,
      config,
    );
    return { errors, url: response?.data || '' };
  },
};
