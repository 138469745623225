import React, { useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import images from 'react-payment-inputs/images';
import { usePaymentInputs } from 'react-payment-inputs';
import { FormikErrors, useFormik } from 'formik';

import { PaymentMethodsApi } from '../../../api/payment-methods-api';
import { OpenPayService } from '../../../services/openpay-service';
import { RoundButton } from '../../../components/round-button';
import { AlertMessage } from '../../../components/alert-message';
import { Messages } from '../../../interfaces/responses/response';
import { Provider } from '../../../interfaces/requests/payment-methods';
import { Card } from '../../../interfaces/models/card';

const ERROR_MESSAGES = {
  emptyCardNumber: 'El número de tarjeta es requerido.',
  invalidCardNumber: 'El número de la tarjeta es inválido.',
  emptyExpiryDate: 'La fecha de expiración es requerida.',
  monthOutOfRange: 'El mes debe estar entre 01 y 12',
  yearOutOfRange: 'El año no puede estar en el pasado',
  dateOutOfRange: 'La fecha de expiración no puede estar en el pasado',
  invalidExpiryDate: 'La fecha de expiración es inválida',
  emptyCVC: 'El código de seguridad es requerido.',
  invalidCVC: 'El código de seguridad es inválido.',
};

interface PaymentMethodInputs {
  holderName: string;
  cardNumber: string;
  expiryDate: string;
  cvc: string;
}

const getTokenInput = (values: PaymentMethodInputs) => {
  const [expirationMonth, expirationYear] = values.expiryDate.split(' / ');
  return {
    holderName: values.holderName,
    cardNumber: values.cardNumber.replaceAll(' ', ''),
    expirationMonth,
    expirationYear,
    cvv2: values.cvc,
  };
};

interface PaymentMethodsEditProps {
  onSuccess: (card: Card) => void;
  onCancel: () => void;
}

export const PaymentMethodsEdit: React.FC<PaymentMethodsEditProps> = ({
  onSuccess,
  onCancel,
}) => {
  const deviceSessionId = useMemo(() => OpenPayService.getDeviceId(), []);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState<Messages>();

  const {
    meta,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getCardImageProps,
  } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES,
  });

  const updateStatus = (isSaving: boolean, errorMessages?: Messages) => {
    setSaving(isSaving);
    setErrors(errorMessages);
  };

  const formik = useFormik<PaymentMethodInputs>({
    initialValues: {
      holderName: '',
      cardNumber: '',
      expiryDate: '',
      cvc: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validate: (values) => {
      const errors: FormikErrors<PaymentMethodInputs> = {};
      if (!values.holderName) {
        errors.holderName = 'Nombre del titular es requerido.';
      }
      if (meta.erroredInputs.cardNumber) {
        errors.cardNumber = meta.erroredInputs.cardNumber;
      }
      if (meta.erroredInputs.expiryDate) {
        errors.expiryDate = meta.erroredInputs.expiryDate;
      }
      if (meta.erroredInputs.cvc) {
        errors.cvc = meta.erroredInputs.cvc;
      }
      return errors;
    },
    onSubmit: async (values) => {
      updateStatus(true);
      const { tokenId, error } = await OpenPayService.generateToken(
        getTokenInput(values),
      );
      if (error) {
        return updateStatus(false, { error });
      }
      const { errors, card } = await PaymentMethodsApi.store({
        provider: Provider.OPENPAY,
        deviceSessionId,
        tokenId: tokenId || '',
      });
      updateStatus(false, errors);
      if (!errors && card) {
        onSuccess(card);
      }
    },
  });

  const inputErrorProps = (field: keyof PaymentMethodInputs) => ({
    error: formik.touched[field] && !!formik.errors[field],
    helperText: formik.touched[field] && formik.errors[field],
  });

  const cardProps = (fn: Function, field: keyof PaymentMethodInputs) =>
    fn({
      name: field,
      value: formik.values[field],
      placeholder: '',
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      onError: (error: string) => {
        formik.setFieldError(field, error);
      },
    });

  return (
    <Paper>
      <Box px={{ xs: 2, sm: 4 }} py={4}>
        <form onSubmit={formik.handleSubmit} noValidate>
          <TextField
            variant="outlined"
            type="text"
            autoComplete="cc-name"
            label="Nombre del titular"
            name="holderName"
            value={formik.values.holderName}
            onChange={(e) => {
              formik.handleChange(e);
              formik.validateForm({
                holderName: e.target.value,
              } as PaymentMethodInputs);
            }}
            onBlur={() => {
              formik.setFieldTouched('holderName');
            }}
            {...inputErrorProps('holderName')}
          />
          <TextField
            variant="outlined"
            type="text"
            label="Número de tarjeta"
            InputProps={{
              inputProps: cardProps(getCardNumberProps, 'cardNumber'),
              endAdornment: (
                <InputAdornment position="end">
                  <svg {...getCardImageProps({ images })} />
                </InputAdornment>
              ),
            }}
            {...inputErrorProps('cardNumber')}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                type="text"
                label="Fecha de exp."
                InputProps={{
                  inputProps: cardProps(getExpiryDateProps, 'expiryDate'),
                }}
                {...inputErrorProps('expiryDate')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                type="password"
                label="CVV"
                InputProps={{
                  inputProps: cardProps(getCVCProps, 'cvc'),
                }}
                {...inputErrorProps('cvc')}
              />
            </Grid>
          </Grid>
          {errors && <AlertMessage messages={errors} />}
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <RoundButton
                  fullWidth
                  variant="outlined"
                  disabled={saving}
                  onClick={() => onCancel()}
                >
                  Cancelar
                </RoundButton>
              </Grid>
              <Grid item xs={6}>
                <RoundButton fullWidth type="submit" loading={saving}>
                  Guardar
                </RoundButton>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </Paper>
  );
};
