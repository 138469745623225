import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { PostsApi, IndexPosts } from '../../api/posts-api';
import { LoadingWrapper } from '../../components/loading-wrapper';
import { Placeholder } from '../../components/placeholder';
import { PostListItem } from './partials/post-list-item';

interface PostsListProps {
  userId?: number;
  likesTo?: number;
}
export const PostsList: React.FC<PostsListProps> = ({ userId, likesTo }) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<IndexPosts>({ posts: [] });

  useEffect(() => {
    setLoading(true);
  }, [userId, likesTo]);

  useEffect(() => {
    const fetch = async () => {
      setResponse(await PostsApi.index({ userId, likesTo }));
      setLoading(false);
    };
    loading && fetch();
  }, [loading, userId, likesTo]);

  return (
    <LoadingWrapper loading={loading} errors={response.errors}>
      {response.posts.length === 0 ? (
        likesTo ? (
          <Placeholder
            picture="favorites"
            title="Sin favoritos"
            body="Este usuario todavía no ha añadido favoritos."
          />
        ) : (
          <Placeholder
            picture="posts"
            title="Sin productos"
            body="Este usuario todavía no ha añadido productos."
          />
        )
      ) : (
        <Container maxWidth="lg">
          <Grid container>
            {response.posts.map((post) => (
              <Grid key={post.id} item xs={12} sm={6} md={4} lg={3}>
                <PostListItem post={post} />
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </LoadingWrapper>
  );
};
