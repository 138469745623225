import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import { LoadingWrapper } from '../../components/loading-wrapper';

import { GetOrderDetail, OrdersApi } from '../../api/orders-api';
import {
  OrderDetailsProps,
  ModalsState,
  ReportValue,
  ReturnType,
  ReturnState,
} from '../../interfaces/models/oders/order-detail';
import OrderDetailSteps from './partials/order-detail-steps';
import { OrderDetailHeader } from './partials/order-detail-header';
import { OrderDetailsList } from './partials/order-detail-list';
import { OrderDetailAddress } from './partials/order-detail-address';
import { OrderDetailsPayment } from './partials/order-detail-payment';
import { OrderDetailTotals } from './partials/order-detail-totals';
import { OrderDetailFooter } from './partials/order-detail-footer';
import { OrderDetailModal } from './partials/modal/order-detail-modals';
import { SnackAlert } from '../../components/snack-alert';
import { getSaleFinalPrices } from './partials/modal/controller';

interface snackState {
  open: boolean;
  message: string;
  severity: string;
}

export const OrderDetails: React.FC<OrderDetailsProps> = ({
  orderId,
  isSale,
}) => {
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState<ModalsState>({
    help: false,
    confirm: false,
    problem: false,
    id: null,
  });
  const [snack, setSnack] = useState<snackState>({
    open: false,
    message: '',
    severity: 'success',
  });
  const [rejects, setRejects] = useState<ReportValue[]>([]);
  const [returns, setReturns] = useState<ReturnState>({
    orderDetails: [],
  });
  const [response, setResponse] = useState<GetOrderDetail>();

  const finalSalePrice = getSaleFinalPrices(response?.order, isSale, rejects);

  // Modal
  const onCloseModal = (key = 'help') => {
    setModal({ ...modal, [key]: false, id: null });
  };
  const openModal = (key = 'help') => {
    setModal({ ...modal, [key]: true });
  };
  const openModalWithItem = (key = 'problem', id: number) => {
    setModal({ ...modal, [key]: true, id });
  };
  // Snack
  const setOpenSnack = (value: boolean) => {
    setSnack({ ...snack, open: value });
  };
  const setSnackState = (state: object) => {
    setSnack({ ...snack, ...state });
  };
  // Report
  const setRejectElements = (el: ReportValue) => {
    setRejects([...rejects, el]);
  };
  // Returns
  const setReturnElement = (el: ReturnType) => {
    const find = returns.orderDetails.find(
      (item) => item.orderDetailId === el.orderDetailId,
    );
    if (find === undefined) {
      setReturns({
        ...returns,
        orderDetails: [...returns.orderDetails, el],
      });
    }
  };
  // Order
  const getData = async () => {
    await setLoading(true);
    await setResponse(
      await OrdersApi.getOrderDetail(orderId, isSale ? 'seller' : 'buyer'),
    );
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
  }, [orderId]);

  useEffect(() => {
    const initData = async () => {
      setResponse(
        await OrdersApi.getOrderDetail(orderId, isSale ? 'seller' : 'buyer'),
      );
      setLoading(false);
    };
    loading && initData();
  }, [loading, orderId, isSale]);

  return (
    <LoadingWrapper loading={loading} errors={response?.errors}>
      {!loading && response && response.order && (
        <Box maxWidth={'md'} py={{ sm: 4 }} mx="auto">
          <OrderDetailSteps order={response.order} />
          <OrderDetailHeader isSale={isSale} order={response.order} />
          <OrderDetailsList
            isSale={isSale}
            order={response.order}
            rejects={rejects}
            orderDetails={returns.orderDetails}
            openModalWithItem={openModalWithItem}
          />
          <Grid container direction="row" spacing={2}>
            <OrderDetailAddress isSale={isSale} order={response.order} />
            <OrderDetailsPayment isSale={isSale} order={response.order} />
          </Grid>
          <OrderDetailTotals
            isSale={isSale}
            order={response.order}
            finalSalePrice={finalSalePrice}
          />
          <OrderDetailFooter
            isSale={isSale}
            returns={returns}
            getData={getData}
            openModal={openModal}
            order={response.order}
            setSnackState={setSnackState}
            setLoading={(value: boolean) => setLoading(value)}
          />
          <OrderDetailModal
            rejects={rejects}
            modalState={modal}
            order={response.order}
            getData={getData}
            onCloseModal={onCloseModal}
            setSnackState={setSnackState}
            setReturnElement={setReturnElement}
            setRejectElements={setRejectElements}
          />
          <SnackAlert {...snack} setOpen={setOpenSnack} />
        </Box>
      )}
    </LoadingWrapper>
  );
};
