import { Api } from './api-base';
import { Response, Messages } from '../interfaces/responses/response';
import { BankAccount } from '../interfaces/models/bank-account';
import { BankAccountRequest } from '../interfaces/requests/bank-account/bank-account-request';

export interface ShowAccount {
  errors?: Messages;
  account?: BankAccount;
}

export const BankAccountsApi = {
  show: async (id: number): Promise<ShowAccount> => {
    const { errors, response } = await Api.get<Response<BankAccount>>(
      `/bank-accounts/${id}`,
    );
    return { errors, account: response?.data };
  },
  update: async (
    id: number,
    input: BankAccountRequest,
  ): Promise<ShowAccount> => {
    const { errors, response } = await Api.put<Response<BankAccount>>(
      `/bank-accounts/${id}`,
      input,
    );
    return { errors, account: response?.data };
  },
};
