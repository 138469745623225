import React, { useEffect, useState } from 'react';

import { OrdersApi } from '../../api/orders-api';
import { LoadingWrapper } from '../../components/loading-wrapper';
import { OrderItem } from './order-item';
import { Messages } from '../../interfaces/responses/response';
import { PurchaseItem } from '../../interfaces/models/oders/purchase-item';
import { Grid, Typography, makeStyles, Theme } from '@material-ui/core';
import { setFormattedOrderList, OrderFormattedList } from '../../utils/helpers';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginTop: '2.4vh',
    marginBottom: 10,
    fontSize: 15,
    color: theme.palette.info.dark,
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
}));

export const PurchasesIndex = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Messages>();
  const [items, setItems] = useState<OrderFormattedList<PurchaseItem>[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const { errors, purchases } = await OrdersApi.getPurchases();
      if (errors) {
        setErrors(errors);
        return setLoading(false);
      }
      setItems(setFormattedOrderList(purchases));
      setLoading(false);
    };
    loading && fetch();
  }, [loading]);

  return (
    <LoadingWrapper loading={loading} errors={errors}>
      {items?.map((item) => (
        <Grid key={`key-${item.date}`} container direction="column">
          <Grid item container direction="row">
            <Typography className={classes.header}>{item.date}</Typography>
          </Grid>
          <Grid item container direction="column">
            {item.items.map((order: PurchaseItem) => (
              <OrderItem
                id={order.id}
                key={order.id}
                folio={order.folio}
                name={`${order.seller?.firstName} ${order.seller?.lastName}`}
                amount={order.total}
                status={order.status}
                shipment={order.shipment}
                details={order.details}
              />
            ))}
          </Grid>
        </Grid>
      ))}
    </LoadingWrapper>
  );
};
