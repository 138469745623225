import { Api } from './api-base';
import { Response, HasErrors } from '../interfaces/responses/response';
import { User } from '../interfaces/models/user';
import { UpdateMeRequest } from '../interfaces/requests/me/update-profile';
import { ChangePasswordRequest } from '../interfaces/requests/me/change-password';

export interface GetProfile extends HasErrors {
  user?: User;
}

export interface GetUrl extends HasErrors {
  url: string;
}

export const MeApi = {
  getProfile: async (): Promise<GetProfile> => {
    const { errors, response } = await Api.get<Response<User>>('/me');
    return { errors, user: response?.data };
  },
  editProfile: async (input: UpdateMeRequest): Promise<GetProfile> => {
    const { errors, response } = await Api.put<Response<User>>('/me', input);
    return { errors, user: response?.data };
  },
  updatePicture: async (picture: Blob): Promise<GetUrl> => {
    const formData = new FormData();
    formData.append('picture', picture);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const { errors, response } = await Api.put<Response<string>>(
      '/me/picture',
      formData,
      config,
    );
    return { errors, url: response?.data || '' };
  },
  changePassword: async (input: ChangePasswordRequest) => {
    const { errors } = await Api.post<Response<void>>('/auth/password', input);
    return { errors };
  },
};
