import React, { FunctionComponent } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';

import { getBackPath } from '../utils/helpers';

interface AppToolbarProps {
  title: string;
  disableBack?: boolean;
}

export const AppToolbar: FunctionComponent<AppToolbarProps> = ({
  title,
  disableBack = false,
  children,
}) => {
  const match = useRouteMatch();
  const backPath = getBackPath(match?.url);
  return (
    <AppBar position="static" elevation={0}>
      <Container>
        <Toolbar disableGutters>
          {!disableBack && (
            <IconButton
              edge="start"
              color="inherit"
              component={RouterLink}
              to={backPath}
            >
              <ArrowBack />
            </IconButton>
          )}
          <Typography variant="h6">{title}</Typography>
          <Box flexGrow={1}></Box>
          {children}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
